import { DIALOG_MESSAGE_TYPE } from '@shared/common/constants';
import { Component, OnInit } from '@angular/core';
import { faCheckCircle, faTimes, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
})
export class MessageComponent implements OnInit {
  type: string = DIALOG_MESSAGE_TYPE.Success;
  message: string;
  modalRef?: BsModalRef;
  dialogType: any;
  faTimes: any = faTimes;
  faCheckCircle: any = faCheckCircle;
  faExclamationCircle: any = faExclamationCircle;
  constructor(private bsModalRef: BsModalRef) {}

  ngOnInit(): void {
    this.dialogType = DIALOG_MESSAGE_TYPE;
  }

  /**
   * Close dialog
   */
  close(): void {
    this.bsModalRef.hide();
  }
}
