<div class="container-fluid">
  <div *ngIf="isConfirmCode === false;else confirm_code_content" id="login-page" class="row shadow-lg">
    <div class="col-md-4 text-center company-info d-flex flex-column justify-content-center">
      <img class="company-info__logo" src="assets/img/WM_logo_2.png" alt="WM LOGO">
    </div>
    <div class="col-md-8 pt-4">
      <h1 class="text-center mb-4 mt-2">ログイン</h1>
      <div class="row">
        <form [formGroup]="loginForm" (ngSubmit)="onSubmitLogin()" class="form-group">
          <div class="form-group row">
            <div class="col-sm-12">
              <input
                formControlName="username"
                type="text" class="form-control"
                id="username"
                placeholder="ユーザ名"
                maxlength="64"
                required
                [ngClass]="getLoginFormControls('username')"
              >
              <div class="invalid-feedback">
                ユーザー名を入力してください
              </div>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-sm-12 my-2">
              <input
                type="password"
                class="form-control"
                formControlName="password"
                id="password"
                placeholder="パスワード"
                maxlength="64"
                required
                [ngClass]="getLoginFormControls('password')"
              >
              <div class="invalid-feedback">
                パスワードを入力してください
              </div>
            </div>
          </div>
          <div class="form-group row mt-5">
            <div class="col-sm-12">
              <button type="submit" class="btn text-white btn-block btn-lg" [disabled]="loginForm.invalid">ログイン</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <ng-template #confirm_code_content>
    <div id="confirm-code-panel" class="row shadow-lg">
      <div class="col-md-4 text-center company-info d-flex flex-column justify-content-center">
        <img class="company-info__logo" src="assets/img/WM_logo_2.png" alt="WM LOGO">
      </div>
      <div class="col-md-8 pt-4 confirm-code-inner">
        <h1 class="text-center mb-4 mt-2">認証コード</h1>
        <div class="row">
          <form class="form-group">
            <div class="form-group row">
              <code-input
                [codeLength]="4"
                (codeCompleted)="onCodeCompleted($event)"
              >
              </code-input>
            </div>
          </form>
        </div>
      </div>
    </div>
  </ng-template>
</div>
<app-loading *ngIf="isLoading"></app-loading>
