<!-- Start home page -->
<div class="home-page" id="homePage">
  <div class="header text-white home-fs-16" [innerHTML]="headerContent">
  </div>
  <div class="body">
    <div class="steps justify-content-center">
      <div class="text-center page-title">新規お申し込み</div>
      <ul class="progressbar d-flex position-relative align-items-center">
        <li class="p-1 d-flex position-relative align-items-center" [ngClass]="{'active': stepNumber === 1}">
          <span class="step-number font-weight-bold d-flex justify-content-center align-items-center">1</span>
          <span class="step-title d-flex justify-content-center align-items-center text-center">エリア<br class="sp-break">選択</span>
        </li>
        <li class="p-1 d-flex position-relative align-items-center" [ngClass]="{'active': stepNumber === 2}">
          <span class="step-number font-weight-bold d-flex justify-content-center align-items-center">2</span>
          <span class="step-title d-flex justify-content-center align-items-center text-center">コース<br class="sp-break">選択</span>
        </li>
        <li class="p-1 d-flex position-relative align-items-center" [ngClass]="{'active': stepNumber === 3}">
          <span class="step-number font-weight-bold d-flex justify-content-center align-items-center">3</span>
          <span class="step-title d-flex justify-content-center align-items-center text-center">利用<br class="sp-break">規約</span>
        </li>
        <li class="p-1 d-flex position-relative align-items-center" [ngClass]="{'active': stepNumber === 4}">
          <span class="step-number font-weight-bold d-flex justify-content-center align-items-center">4</span>
          <span class="step-title d-flex justify-content-center align-items-center text-center">入力</span>
        </li>
        <li class="p-1 d-flex position-relative align-items-center" [ngClass]="{'active': stepNumber === 5}">
          <span class="step-number font-weight-bold d-flex justify-content-center align-items-center">5</span>
          <span class="step-title d-flex justify-content-center align-items-center text-center">日程<br class="sp-break">調整</span>
        </li>
      </ul>
    </div>
    <div class="content body__content">
      <router-outlet></router-outlet>
    </div>
  </div>

  <div class="footer page__footer text-white home-fs-16"
      [innerHTML]="footerContent"
  ></div>
</div>
<!-- End home page -->


