import { HelperService } from '@core/services/helper.service';
import { DataService } from '@core/services/data.service';
import { Component, OnInit } from '@angular/core';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { PaymentService } from '@core/services/home/payment.service';
import {
  PROGRESS_STEPS,
  ROUTES_PATH,
  OBJECT_GENDER,
  RESULT_CODES,
  DIALOG_MESSAGE_TYPE,
  MESSAGES,
  STORAGE_KEYS,
  ADDRESS_NUMBER_CHARACTER,
  ADDRESS_CHARACTER,
  CHOME_CHARACTER,
  HOUSE_TYPE,
  BOOLEAN_MAPPING_VALUE,
  PaymentMethod,
  COURSE_CHOOSE,
  creditNamesBasedOnPaymentMethodValue,
  PAYMENT_TYPES,
  DECRYPT_DATA_FAIL_COOKIE_NAME,
  PAYMENT_METHOD_VALUE,
} from '@shared/common/constants';
import { ApplicationsService } from '@core/services/home/applications.service';
import { DialogServiceService } from '@core/services/dialog-service.service';
import { HttpStatusCode } from '@angular/common/http';
import * as cryptoJs from 'crypto-js';
import { Company } from '@core/models/company.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-app-info-sony-3d',
  templateUrl: './app-info-sony-3d.component.html',
  styleUrls: ['./app-info-sony-3d.component.scss'],
})
export class AppInfoSony3DComponent implements OnInit {
  isLoading: boolean = false;
  requestId: string = null;
  buttonName: string = '';
  homeUrl: string = '';
  orderId: any;
  responseText: string = '';
  paymentStatus: string = 'PENDING';
  buttonRedirect: boolean = false;
  applicationID: any = null;
  faChevronLeft: any = faChevronLeft;
  faChevronRight: any = faChevronRight;
  dataOfAppInput: any = {};
  confirmDetailData: any = {};
  constGenderString: any = OBJECT_GENDER;
  courseInfo: any = {};
  cardNumber: string = '';
  paymentMethodValue: PaymentMethod = PaymentMethod.EMPTY;
  paymentMethodOpt = '';
  listOptionChoice: any = [];
  public routes = ROUTES_PATH;
  selectedPaymentMethodExplanatory = {
    explanatory: '',
    explanatory_url: '',
  };
  texthead = ``;
  textAfter = ``;
  textmiddle = ``;
  text1_pending = '申込はまだ完了していません。';
  text2_pending = '画面を閉じずにお待ちください。';
  isPending = false;
  remark_title: string;
  company: Company;
  constructor(
    private readonly dataService: DataService,
    private readonly helperService: HelperService,
    private dialogService: DialogServiceService,
    private readonly paymentService: PaymentService,
  ) {}

  ngOnInit(): void {
    this.orderId = sessionStorage.getItem('order_id');
    if (this.orderId) {
      this.isLoading = true;
      this.pollPaymentStatus();
      return;
    }
    this.getDataStored();
    this.clearData();
    this.responseText = `メールを送信致しましたので記載のURLより日程調整の申込をお願いします。<br />申込後、２～３日経過してもメールが届かない場合は、お問い合わせください。`;
  }
  backPreviousStep(): void {
    this.helperService.removeSessionStorageValue(STORAGE_KEYS.IsConfirm);
    this.helperService.redirectByOptions(ROUTES_PATH.AppInfoInput, { skipLocationChange: true });
    this.helperService.removeSessionStorageValue(STORAGE_KEYS.MaxTimeApi);
  }
  async pollPaymentStatus() {
    try {
      this.isPending = true;
      $('.heading_title').html('処理中');
      $('.card-header-title').html('');
      $('.notice_title').html('');
      const data = await this.paymentService
        .postDataOfPaymentStatus({ orderId: this.orderId, type: 'SONY' })
        .toPromise();
      if (!sessionStorage.getItem(STORAGE_KEYS.MaxTimeApi)) {
        let max_time_api: number = Math.floor(Number(data.body['max_time_api']) / 10);
        this.helperService.setSessionStorage(STORAGE_KEYS.MaxTimeApi, String(max_time_api));
      }
      if (
        data.body &&
        data.body.status &&
        data.body.status.payment_status &&
        data.body.status.payment_status !== this.paymentStatus
      ) {
        this.requestId = sessionStorage.getItem('request_id');
        this.isPending = false;
        $('.heading_title').html('お申し込みが完了しました');
        $('.card-header-title').html('お申し込み完了');
        $('.notice_title').html('申請ID');
        if (data.body.status.payment_status == 'success') {
          this.helperService.removeSessionStorageValue(STORAGE_KEYS.InfoInputValues);
          this.helperService.removeSessionStorageValue(STORAGE_KEYS.applicationID);
          this.helperService.removeSessionStorageValue(STORAGE_KEYS.MaxTimeApi);
          this.texthead = `メールを送信致しましたので記載のURLより日程調整の申込をお願いします。`;
          this.textAfter = `申込後、２～３日経過してもメールが届かない場合は、お問い合わせください`;
        } else {
          this.applicationID = sessionStorage.getItem('application_id');
          this.dialogService.open({
            type: DIALOG_MESSAGE_TYPE.Error,
            message: '3Dセキュア認証が失敗しました',
          });
          this.dataService.setStepDataStored(PROGRESS_STEPS.StepFour.Index);
          this.backPreviousStep();
        }
        this.isLoading = false;
        this.buttonRedirect = true;
      } else {
        //start setup max_time_api
        let max_time_api = Number(sessionStorage.getItem(STORAGE_KEYS.MaxTimeApi));
        if (max_time_api > 0) {
          setTimeout(() => this.pollPaymentStatus(), 10000);
          let cur_max_time_api = Number(max_time_api) - 1;
          this.helperService.setSessionStorage(STORAGE_KEYS.MaxTimeApi, String(cur_max_time_api));
        } else {
          this.isPending = false;
          this.texthead = `決済処理中にシステムエラーが発生しました。`;
          this.textAfter = `お手数ですが、時間をおいて再度お申込みください。`;
          this.textmiddle = `お申込みは完了しておりません。`;
          this.isLoading = false;
          this.buttonRedirect = true;
          this.helperService.removeSessionStorageValue(STORAGE_KEYS.InfoInputValues);
          $('.alert-check').css('background-color', '#FDDCDF');
          $('.heading_title').html('お申し込みが失敗しました');
          $('.notice_title').remove();
          $('.card-header-title').html('');
        }
      }
    } catch (error) {
      this.buttonRedirect = true;
      console.error(error);
      this.isLoading = false;
    }
  }
  getDataStored(): void {
    const {
      data: {
        infoInput: {
          value: {
            application: { request_id },
            company: { button_name, home_url },
          },
        },
      },
    } = this.dataService.getDataStored();
    !request_id && this.helperService.redirect(ROUTES_PATH.Error);
    this.requestId = request_id;
    if (button_name && home_url) {
      this.buttonName = button_name;
      this.homeUrl = home_url;
    }
  }

  clearData(): void {
    this.dataService.clearData();
    this.helperService.removeSessionStorageValue(STORAGE_KEYS.DataStoredSteps);
  }
  goToHomeUrl(): void {
    this.helperService.removeSessionStorageValue(STORAGE_KEYS.DataStoredSteps);
    this.helperService.removeSessionStorageValue(STORAGE_KEYS.orderID);
    this.helperService.removeSessionStorageValue(STORAGE_KEYS.requestID);
    this.helperService.removeSessionStorageValue(STORAGE_KEYS.MaxTimeApi);
    window.location.href = this.homeUrl;
  }
}
