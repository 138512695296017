import {
  CANDY_W_SUCCESS_RESULT,
  CARD_INFO_COOKIE_NAME,
  CHECK_CARD_INFO_COOKIE_EMPTY_REGEX,
  DIALOG_MESSAGE_TYPE,
  ExplanatoryKeyName,
  GET_CARD_INFO_COOKIE_VALUE_REGEX,
  NOT_FOUND_MEMBER_ID_COOKIE_NAME,
  creditNamesBasedOnPaymentMethodValue,
} from './../../shared/common/constants';
import { ApiResponse, ProgressStored } from '@shared/common/types';
import { HelperService } from '@core/services/helper.service';
import { ApplicationBody } from '@core/models/app-input.model';
import { DataService } from '@core/services/data.service';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AfterViewInit, Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';
import * as _ from 'lodash';
declare let $: any;
import { map } from 'rxjs/operators';

import {
  PROGRESS_STEPS,
  STORAGE_KEYS,
  LIMIT_YEARS,
  RESULT_CODES,
  ROUTES_PATH,
  MONTHS,
  HOUSING_CATEGORY,
  HOUSE_TYPE,
  CHOME_CHARACTER,
  MESSAGES,
  PAYMENT_TYPES,
  COURSE_CHOOSE,
  PAYMENT_METHOD_VALUE,
  PaymentMethod,
} from '@shared/common/constants';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { finalize } from 'rxjs/operators';
import { ComplementaryService } from '@core/services/home/complementary.service';
import { Company } from '@core/models/company.model';
import { isEmpty } from 'lodash';
import { CandyWPaymentComponent } from '../candy_w-payment/candy_w-payment.component';
import { DialogServiceService } from '@core/services/dialog-service.service';
import { decodeBase64, decodePercentEncodedString } from '@shared/utils/common-function-util';

@Component({
  selector: 'app-app-info-input',
  templateUrl: './app-info-input.component.html',
  styleUrls: ['./app-info-input.component.scss'],
})
export class AppInfoInputComponent implements OnInit, AfterViewInit {
  @ViewChild('hiddenToken') cardTokenElement: ElementRef;
  @ViewChild('hiddenCardNumber') cardNumberElement: ElementRef;
  @ViewChild(CandyWPaymentComponent) candyWPaymentForm: CandyWPaymentComponent;
  countryCode: string = 'jp';
  appInfoInputForm: FormGroup;
  faChevronLeft: any = faChevronLeft;
  faChevronRight: any = faChevronRight;
  isDisplay: boolean = true;
  postalCodeInput: string = '';
  regexCheckKatakana: any = /^([ァ-ン]|ー)+$/;
  checkRegexSingleByteCharacters: any = /^[^ -~｡-ﾟ\x00-\x1f\t]+$/;
  yearsList: string[] = [];
  monthsList: string[] = MONTHS;
  daysList: string[] = [];
  birthday: string = '';
  selectedYear: string = null;
  selectedMonth: string = null;
  selectedDay: string = null;
  isDisabledMonth: boolean = true;
  isDisabledDay: boolean = true;
  stateOrCity: string = '';
  establishChome: string = '';
  establishPostalCode: string = '';
  typeHouse: string = '';
  companyCode: string;
  courseCodeId: string = '';
  isCheckedContract: boolean = false;
  public routes = ROUTES_PATH;
  isLoading: boolean = false;
  isReadonlyAndRequired: boolean;
  cardToken: string = '';
  hiddenCardNumberProp: string = '';
  paymentUrl: string = '';
  objectDetail: ApplicationBody = {
    last_name: '',
    first_name: '',
    last_name_furigana: '',
    first_name_furigana: '',
    establish_postal_code: '',
    establish_prefectures: '',
    establish_municipalities: '',
    establish_town_area: '',
    establish_chome: '',
    establish_address: '',
    establish_address_number: '',
    establish_apartment_name: '',
    establish_room_number: '',
    contract_postal_code: '',
    contract_prefectures: '',
    contract_municipalities: '',
    contract_town_area: '',
    contract_chome: '',
    contract_address: '',
    contract_address_number: '',
    contract_apartment_name: '',
    contract_room_number: '',
    birthday: '',
    gender: 0,
    telephone_number: '',
    mobile_telephone_number: '',
    email_address: '',
    email_address_confirmation: '',
    remarks: '',
  };
  objectDataAppInput: any = {
    service_course: {},
    detail: {},
  };
  birthdayErrorMsg: string = '';
  private lastOpened: BsDropdownDirective = null;
  isCheckCardError: boolean = false;
  isOpenSmbcPayment: boolean = false;
  company: Company;
  PAYMENT_TYPES = PAYMENT_TYPES;
  isSelectedOtherBuilding: boolean = false;
  paymentMethod: Array<PaymentMethod>;
  shouldPaymentShow: boolean = false;
  paymentMethodValue: PaymentMethod;
  public courseChoose = COURSE_CHOOSE;
  public paymentMethodConst = PAYMENT_METHOD_VALUE;
  selectedPaymentMethodExplanatory = {
    explanatory: '',
    explanatory_url: '',
  };
  remark_title: string;
  remark_annotation: string;
  creditNames: { [key: number]: { fieldName: string; value: string } } = {};
  isEnableCandyWCardButton = false;
  candyWCardAdded = false;
  candyWCardInfo = {};
  member_id = '';
  isCourseSupportCreditCard = true;

  constructor(
    private formBuilder: FormBuilder,
    private readonly dataService: DataService,
    private readonly helperService: HelperService,
    private complementaryService: ComplementaryService,
    private readonly dialogService: DialogServiceService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.initForm();
  }

  async ngOnInit(): Promise<void> {
    this.getCandyWCardInfoFromCookie();
    this.getYears();
    this.getEstablishDataPostalCode();
    this.resetFormValue();
    this.getDataFromSessionOfCourseChoose();
    if (!this.paymentUrl) {
      this.helperService.redirect(ROUTES_PATH.Error);
      return;
    }
    this.loadPaymentScript();
    this.checkTypeHouseAndRemoveValidation();
    this.isDisplay = this.isCheckedContract;
    this.removeValidate(this.isDisplay);
    this.subscribePostalCodeValueChange();
    this.shouldPaymentShow = this.paymentMethod === undefined || !this.paymentMethod.includes(PaymentMethod.NONE);
    this.enableCandyWPaymentCardButton({ value: this.appInfoInputForm.controls.last_name.value });
  }

  async ngAfterViewInit() {
    if (this.shouldPaymentShow) {
      map(
        $(this.cardTokenElement.nativeElement).on('change', (e) => {
          this.subscribeTokenValueChange(e);
        }),

        $(this.cardNumberElement.nativeElement).on('change', (e) => {
          this.subscribeCardValueChange(e);
        }),
      );
    }

    $('input').on('keypress', (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
      }
    });

    await this.reloadPaymentMethod();
  }

  initForm(): void {
    this.appInfoInputForm = this.formBuilder.group({
      last_name: [
        '',
        [Validators.maxLength(10), Validators.pattern(this.checkRegexSingleByteCharacters), Validators.required],
      ],
      first_name: [
        '',
        [Validators.maxLength(10), Validators.pattern(this.checkRegexSingleByteCharacters), Validators.required],
      ],
      last_name_furigana: [
        '',
        [Validators.maxLength(10), Validators.pattern(this.regexCheckKatakana), Validators.required],
      ],
      first_name_furigana: [
        '',
        [Validators.maxLength(10), Validators.pattern(this.regexCheckKatakana), Validators.required],
      ],
      establish_address: ['', [Validators.maxLength(5)]],
      establish_address_number: [''],
      establish_apartment_name: ['', [Validators.required]],
      establish_room_number: ['', [Validators.required, Validators.maxLength(10)]],
      contract_postal_code: [
        '',
        [Validators.required, Validators.maxLength(7), Validators.minLength(7), Validators.pattern('^[0-9]*$')],
      ],
      contract_prefectures: ['', [Validators.required]],
      contract_municipalities: ['', [Validators.required]],
      contract_town_area: [''],
      contract_chome: ['', [Validators.maxLength(64)]],
      contract_address: ['', [Validators.maxLength(5)]],
      contract_address_number: [''],
      contract_apartment_name: [''],
      contract_room_number: ['', [Validators.maxLength(10)]],
      gender: ['0'],
      telephone_number: [
        '',
        [Validators.required, Validators.minLength(12), Validators.maxLength(14), Validators.pattern('^[-0-9]*$')],
      ],
      mobile_telephone_number: [
        '',
        [Validators.minLength(12), Validators.maxLength(14), Validators.pattern('^[-0-9]*$')],
      ],
      email_address: ['', [Validators.email, Validators.required]],
      email_address_confirmation: ['', [Validators.email, Validators.required]],
      remarks: [''],
    });
  }
  checkTypeHouseAndRemoveValidation(): void {
    this.isReadonlyAndRequired = this.typeHouse === HOUSE_TYPE.Collective;
    if (this.typeHouse === HOUSE_TYPE.Detached) {
      this.appInfoInputForm.get('establish_apartment_name').clearValidators();
      this.appInfoInputForm.get('establish_apartment_name').updateValueAndValidity();
      this.appInfoInputForm.get('establish_room_number').setValidators([Validators.maxLength(10)]);
      this.appInfoInputForm.get('establish_room_number').updateValueAndValidity();
    }
  }

  onCheckBoxChange(e): void {
    this.isCheckedContract = e.target.checked;
    this.isDisplay = e.target.checked;
    this.removeValidate(this.isDisplay);
  }
  removeValidate(isDisplay: boolean) {
    if (isDisplay === false) {
      this.appInfoInputForm.get('contract_postal_code').clearValidators();
      this.appInfoInputForm.get('contract_postal_code').updateValueAndValidity();
      this.appInfoInputForm.get('contract_room_number').clearValidators();
      this.appInfoInputForm.get('contract_room_number').updateValueAndValidity();
      this.appInfoInputForm.get('contract_address').clearValidators();
      this.appInfoInputForm.get('contract_address').updateValueAndValidity();
      this.appInfoInputForm.get('contract_prefectures').clearValidators();
      this.appInfoInputForm.get('contract_prefectures').updateValueAndValidity();
      this.appInfoInputForm.get('contract_municipalities').clearValidators();
      this.appInfoInputForm.get('contract_municipalities').updateValueAndValidity();
    } else {
      this.appInfoInputForm
        .get('contract_postal_code')
        .setValidators([
          Validators.required,
          Validators.maxLength(7),
          Validators.minLength(7),
          Validators.pattern('^[0-9]*$'),
        ]);
      this.appInfoInputForm.get('contract_postal_code').updateValueAndValidity();
      this.appInfoInputForm.get('contract_room_number').setValidators([Validators.maxLength(10)]);
      this.appInfoInputForm.get('contract_room_number').updateValueAndValidity();
      this.appInfoInputForm.get('contract_address').setValidators([Validators.maxLength(5)]);
      this.appInfoInputForm.get('contract_address').updateValueAndValidity();
      this.appInfoInputForm.get('contract_prefectures').setValidators([Validators.required]);
      this.appInfoInputForm.get('contract_prefectures').updateValueAndValidity();
      this.appInfoInputForm.get('contract_municipalities').setValidators([Validators.required]);
      this.appInfoInputForm.get('contract_municipalities').updateValueAndValidity();
    }
  }

  getYears(): void {
    const mostRecentYearOfEligibility = moment().subtract(LIMIT_YEARS.conditionYears, 'y');
    const startYear = moment().subtract(LIMIT_YEARS.LimitYears, 'y');
    while (mostRecentYearOfEligibility.diff(startYear, 'years') >= 0) {
      this.yearsList.push(startYear.format('YYYY'));
      startYear.add(1, 'year');
    }
    this.yearsList.sort((a: any, b: any) => b - a);
  }

  getSelectedYear(year: string): void {
    this.isDisabledMonth = false;
    this.selectedDay = '';
    this.isDisabledDay = true;
    this.selectedYear = year;
    this.selectedMonth = '';
    this.resetBirthdayValue();
  }

  getDaysOfMonth(year: string, month: string): void {
    this.isDisabledDay = false;
    this.selectedMonth = month;
    this.daysList = [];
    this.selectedDay = '';
    const stringMonthYear = `${year}-${month}`;
    const numberDaysOfMonths = moment(stringMonthYear, 'YYYY-MM').daysInMonth();
    for (let i = 1; i <= numberDaysOfMonths; i++) {
      this.daysList.push(i.toString());
    }

    this.resetBirthdayValue();
  }

  getSelectedDay(day: string): void {
    this.selectedDay = day;
    const birthDayString = `${this.selectedYear}-${this.selectedMonth}-${this.selectedDay}`;

    const birthday = moment(birthDayString, 'YYYY-M-D');
    if (AppInfoInputComponent.isValidBirthday(_.cloneDeep(birthday))) {
      this.birthday = birthday.format('YYYY-MM-DD');
      this.birthdayErrorMsg = '';
    } else {
      this.birthdayErrorMsg = MESSAGES.AppInput.InvalidBirthday;
      this.birthday = '';
    }
  }

  private resetBirthdayValue(): void {
    this.birthday = '';
    this.birthdayErrorMsg = '';
  }

  private static isValidBirthday(birthday): boolean {
    return moment().isAfter(birthday.add(LIMIT_YEARS.conditionYears, 'years'));
  }

  isKatakana(formControl: string): void {
    const formControlName = this.appInfoInputForm.controls[formControl];
    const newKatakanaInput: string = formControlName.value;
    const resultCheckKatakana: boolean = this.checkKatakana(newKatakanaInput);
    !resultCheckKatakana
      ? formControlName.setErrors({ mustKatakanaCharacters: true })
      : formControlName.setErrors(null);
  }

  /**
   * check character Katakana
   * @param text
   * @returns boolean
   */
  checkKatakana(text: string): boolean {
    return this.regexCheckKatakana.test(text);
  }

  isFullWidth(formControl: string): void {
    const formControlName = this.appInfoInputForm.controls[formControl];
    const controlAbstract = formControlName.validator({} as AbstractControl);
    const newInput = formControlName.value;
    const isMustMultiSize = this.checkFullWidth(newInput);
    if (controlAbstract && controlAbstract.required) {
      !isMustMultiSize ? formControlName.setErrors({ mustMultiSize: true }) : formControlName.setErrors(null);
    } else {
      newInput && !isMustMultiSize
        ? formControlName.setErrors({ mustMultiSize: true })
        : formControlName.setErrors(null);
    }
  }

  checkFullWidth(text: string): boolean {
    return this.checkRegexSingleByteCharacters.test(text);
  }
  /**
   * Get the postal value form raw format: xxx-xxxx
   * @param {String} rawPostalCode raw postalCode value
   */
  getValue(rawPostalCode = ''): string {
    return rawPostalCode.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
  }

  /**
   * Listen the keypress event of input tag to validate number value
   * @param {Event} event The event
   * @returns {Boolean}
   */
  validateEnterNumberValue(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    // Only 0-9
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  /**
   * Format postal code value
   * @param {String} postalCodeInputValue Postal code value
   * @returns {String} postal code formatted
   */
  // public formatPostalCode(postalCodeInputValue: string = ''): string {
  //   if (postalCodeInputValue.length >= 4 && !postalCodeInputValue.includes('-')) {
  //     postalCodeInputValue =
  //       postalCodeInputValue.substr(0, 3) + '-' + postalCodeInputValue.substr(3, postalCodeInputValue.length);
  //   }

  //   return postalCodeInputValue;
  // }
  public get appInfoFormControls(): any {
    return this.appInfoInputForm.controls;
  }

  mustMatch(): any {
    const { email_address_confirmation, email_address } = this.appInfoInputForm.controls;
    if (email_address.errors && !email_address_confirmation.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }
    // set error on matchingControl if validation fails
    email_address.value !== email_address_confirmation.value
      ? email_address_confirmation.setErrors({ mustMatch: true })
      : email_address_confirmation.setErrors(null);
  }

  /**
   * Click to redirect previous page
   */
  public backPreviousStep(): void {
    this.dataService.setStepDataStored(PROGRESS_STEPS.StepThree.Index);
    this.helperService.removeSessionStorageValue(STORAGE_KEYS.InfoInputValues);
    this.helperService.redirectByOptions(ROUTES_PATH.HomeTermsOfService, { skipLocationChange: true });
  }

  public get allowNextStep(): boolean {
    const isOtherPaymentMethod = [
      PaymentMethod.ACCOUNT_TRANSFER,
      PaymentMethod.CONVENIENCE_STORE,
      PaymentMethod.OTHERS,
    ].includes(this.paymentMethodValue);

    const isNothingPaymentMethod = this.paymentMethodValue === PAYMENT_METHOD_VALUE.NOTHING;

    const isCreditCardPaymentMethod = this.paymentMethodValue === PAYMENT_METHOD_VALUE.CREDIT_CARD;

    const isCandyWPaymentMethod = this.company.credit_code === PAYMENT_TYPES.CANDY_W;

    const isSMBCOrSonyCreditPayment = this.company.credit_code.match(
      new RegExp(`${PAYMENT_TYPES.SMBC}|${PAYMENT_TYPES.SONY}`),
    );

    const shouldSubmit =
      this.appInfoInputForm.valid &&
      // other payment method
      (isOtherPaymentMethod ||
        // User input their card
        (((isCreditCardPaymentMethod && isSMBCOrSonyCreditPayment) || isNothingPaymentMethod) &&
          !isEmpty(this.cardToken) &&
          !isEmpty(this.birthday)) ||
        (isCreditCardPaymentMethod && isCandyWPaymentMethod && this.candyWCardAdded && !isEmpty(this.birthday)) ||
        // Payment method ['0']
        !this.shouldPaymentShow);

    return shouldSubmit;
  }

  /**
   * Click to redirect confirm page
   */
  public async clickToConfirmPage(): Promise<void> {
    if (
      this.appInfoInputForm.invalid ||
      (this.paymentMethodValue === PAYMENT_METHOD_VALUE.CREDIT_CARD &&
        this.company.credit_code.match(new RegExp(`${PAYMENT_TYPES.SMBC}|${PAYMENT_TYPES.SONY}`)) &&
        !this.cardToken) ||
      (this.paymentMethodValue === PAYMENT_METHOD_VALUE.CREDIT_CARD &&
        this.company.credit_code === PAYMENT_TYPES.CANDY_W &&
        !this.candyWCardAdded)
    ) {
      return;
    }

    this.redirectToConfirmPage();
  }

  /**
   * Save the input data to session and redirect to confirm page
   */
  private redirectToConfirmPage(): void {
    this.objectDataAppInput.payment = { ...this.objectDataAppInput.payment, card_token: window.btoa(this.cardToken) };

    if (
      this.paymentMethodValue === PAYMENT_METHOD_VALUE.CREDIT_CARD ||
      this.paymentMethodValue === PAYMENT_METHOD_VALUE.NOTHING ||
      this.paymentMethodValue === PAYMENT_METHOD_VALUE.NONE
    ) {
      if (this.company.credit_code.match(new RegExp(`${PAYMENT_TYPES.SMBC}|${PAYMENT_TYPES.SONY}`))) {
        this.objectDataAppInput.cardNumber = this.hiddenCardNumberProp;
        this.objectDataAppInput.payment.card_token = window.btoa(this.cardToken);
      }
    }

    this.saveInputValuesToSessionStorage();

    this.helperService.setSessionStorage(STORAGE_KEYS.IsConfirm, 'true');
    this.helperService.redirectByOptions(ROUTES_PATH.AppInfoConfirm, { skipLocationChange: true });
  }

  public saveInputValuesToSessionStorage() {
    Object.keys(this.objectDetail).map((item) => {
      if (this.appInfoInputForm.controls[item]) {
        this.objectDetail[item] = this.appInfoInputForm.controls[item].value;
      }
    });

    this.objectDetail.birthday = this.birthday;
    if (this.isDisplay === false) {
      this.setEstablishDataIntoContractData(this.objectDetail);
    }

    this.objectDataAppInput.payment = { ...this.objectDataAppInput.payment, method_value: this.paymentMethodValue };
    this.objectDataAppInput.detail = this.objectDetail;

    const contractAddress = this.objectDataAppInput.detail.contract_address;
    const establishAddress = this.objectDataAppInput.detail.establish_address;

    this.objectDataAppInput.detail.contract_address = contractAddress ? contractAddress : '';
    this.objectDataAppInput.detail.establish_address = establishAddress ? establishAddress : '';
    this.objectDataAppInput.detail.gender = parseInt(this.objectDataAppInput.detail.gender);
    this.objectDataAppInput.detail.checkedContract = this.isCheckedContract;
    const capitalizeTypeHouse = AppInfoInputComponent.capitalizeTypeHouse(this.typeHouse);
    this.objectDataAppInput.detail.application_housing_category = HOUSING_CATEGORY[capitalizeTypeHouse];
    this.objectDataAppInput.explanatory = this.selectedPaymentMethodExplanatory;

    // Course service information
    this.objectDataAppInput.service_course = {
      company_code: this.companyCode,
      course_code_id: this.courseCodeId,
    };
    this.helperService.setSessionStorage(
      STORAGE_KEYS.InfoInputValues,
      JSON.stringify({ application: this.objectDataAppInput }),
    );
  }

  /**
   * Handle click to show dropdown list
   * @param {Event} $event
   * @param {BsDropdownDirective} clickedDropdown
   */
  onShown($event, clickedDropdown: BsDropdownDirective): void {
    if (this.lastOpened && this.lastOpened !== clickedDropdown) {
      this.lastOpened.hide();
    }

    this.lastOpened = clickedDropdown;
    $event.stopPropagation();
  }

  private getDataFromSessionOfCourseChoose(): void {
    const dataParsed = this.dataService.getDataStored(PROGRESS_STEPS.StepTwo.Index);
    const { selectedCourse, company } = dataParsed;
    const { course_code_id, company_code, payment_url, payment_Method } = selectedCourse;

    this.paymentMethodValue = this.paymentMethodConst.EMPTY;
    this.paymentMethod = payment_Method;
    this.isCourseSupportCreditCard = payment_Method?.includes(PAYMENT_METHOD_VALUE.CREDIT_CARD);

    if (this.paymentMethod === undefined || this.paymentMethod.length === 0) {
      this.paymentMethodValue = this.paymentMethodConst.NOTHING;
    } else if (this.paymentMethod.includes(this.paymentMethodConst.NONE)) {
      this.paymentMethodValue = this.paymentMethodConst.NONE;
    }

    this.courseCodeId = course_code_id;
    this.companyCode = company_code;
    this.paymentUrl = payment_url;
    this.company = company;
    this.remark_title = company?.remark_title || '';
    this.remark_annotation = company?.remark_annotation || '';

    selectedCourse?.payment_Method.forEach((value: string) => {
      const creditNameItem = creditNamesBasedOnPaymentMethodValue[value];
      if (creditNameItem) {
        Object.assign(this.creditNames, {
          [value]: {
            ...creditNameItem,
            value: company[creditNameItem.fieldName] || '',
          },
        });
      }
    });

    const dataStepOneParsed = this.dataService.getDataStored(PROGRESS_STEPS.StepOne.Index);
    this.isSelectedOtherBuilding = dataStepOneParsed?.addressDetail?.other_building_display_flag || false;

    if (company.credit_code === PAYMENT_TYPES.CANDY_W && this.isCourseSupportCreditCard) {
      const { member_id } = this.dataService.getDataStored(PROGRESS_STEPS.StepThree.Index);
      if (!member_id) {
        document.cookie = `${NOT_FOUND_MEMBER_ID_COOKIE_NAME}=${MESSAGES.CourseChoose.notFoundMemberId}; Path=/;`;
        return this.backToFirstStep();
      }
      this.member_id = member_id;
    }
  }

  /**
   * Function getEstablishDataPostalCode get data of course-choose screen and area-search
   * Currently using mock data
   */
  getEstablishDataPostalCode(): void {
    const dataStored: ProgressStored = this.helperService.parseJson(
      this.helperService.getSessionStorageValue(STORAGE_KEYS.DataStoredSteps),
    );
    const establishData = dataStored.data.areaSearch.value;

    const { addressDetail, postalCode, houseType } = establishData;

    this.establishPostalCode = postalCode;
    this.typeHouse = houseType;
    const { prefectures, municipalities, town_area, chome, apartment_name, address, address_number } = addressDetail;
    this.establishChome = chome;

    this.stateOrCity = prefectures + municipalities + town_area + chome + CHOME_CHARACTER;
    this.appInfoInputForm.controls['establish_address'].setValue(address);
    this.appInfoInputForm.controls['establish_address_number'].setValue(address_number);
    this.appInfoInputForm.controls['establish_apartment_name'].setValue(apartment_name || '');
    this.objectDetail.establish_municipalities = municipalities;
    this.objectDetail.establish_postal_code = this.establishPostalCode;
    this.objectDetail.establish_prefectures = prefectures;
    this.objectDetail.establish_town_area = town_area;
    this.objectDetail.establish_chome = chome;
  }

  /**
   * Function getContractDataByPostalCode get data of detached or collective service
   * @param postalCode
   */
  getContractDataByPostalCode(postalCode: string): void {
    const postalCodeInput = postalCode.replace('-', '');
    this.isLoading = true;
    this.complementaryService
      .getContractInfoTownArea(postalCodeInput)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe((res: ApiResponse) => {
        const dataContents = res.contents;
        if (dataContents && res.result_code === RESULT_CODES.Success) {
          const { municipalities, prefectures, town_area } = dataContents;
          this.appInfoInputForm.controls['contract_municipalities'].setValue(municipalities || '');
          this.appInfoInputForm.controls['contract_prefectures'].setValue(prefectures || '');
          this.appInfoInputForm.controls['contract_town_area'].setValue(town_area || '');
        } else {
          this.appInfoInputForm.controls['contract_municipalities'].setValue('');
          this.appInfoInputForm.controls['contract_prefectures'].setValue('');
          this.appInfoInputForm.controls['contract_town_area'].setValue('');
        }
      });
  }
  setEstablishDataIntoContractData(dataEstablishSetIntoContract): void {
    const {
      establish_prefectures,
      establish_town_area,
      establish_chome,
      establish_municipalities,
      establish_apartment_name,
      establish_address,
      establish_address_number,
      establish_postal_code,
      establish_room_number,
    } = dataEstablishSetIntoContract;
    this.objectDetail.contract_municipalities = establish_municipalities;
    this.objectDetail.contract_prefectures = establish_prefectures;
    this.objectDetail.contract_town_area = establish_town_area;
    this.objectDetail.contract_chome = establish_chome;
    this.objectDetail.contract_address = establish_address;
    this.objectDetail.contract_address_number = establish_address_number;
    this.objectDetail.contract_postal_code = establish_postal_code;
    this.objectDetail.contract_apartment_name = establish_apartment_name || '';
    this.objectDetail.contract_room_number = establish_room_number;
  }

  /**
   * reset form data
   */
  resetFormValue(): void {
    const infoInputStored: string = this.helperService.getSessionStorageValue(STORAGE_KEYS.InfoInputValues);
    const dataInput = this.helperService.parseJson(infoInputStored);

    if (dataInput) {
      const oldData = dataInput.application.detail;
      this.isCheckedContract = oldData.checkedContract;
      this.isDisplay = oldData.checkedContract;
      this.appInfoInputForm.reset(oldData);
      this.appInfoInputForm.controls['gender'].reset(oldData.gender.toString());
      this.postalCodeInput = oldData.contract_postal_code;
      this.reInitBirthdayForm(oldData.birthday);
      this.isCheckCardError = dataInput.isCheckCardError || false;
      this.selectedPaymentMethodExplanatory = dataInput.application.explanatory;
    }
  }

  reInitBirthdayForm(birthday: string): void {
    const birthdayStr = birthday.replace(/-/g, '') + '00000000';
    const year = birthdayStr.slice(0, 4);
    const month = birthdayStr.slice(4, 6);
    const day = birthdayStr.slice(6, 8);
    Number(year) > 0 && this.getSelectedYear(year);
    Number(month) > 0 && this.getDaysOfMonth(year, month);
    Number(day) > 0 && this.getSelectedDay(day);
  }

  /**
   * Subscribe postal code value change
   */
  private subscribePostalCodeValueChange(): void {
    this.appInfoInputForm.controls['contract_postal_code'].valueChanges.subscribe((postalCodeValue) => {
      if (postalCodeValue.length >= 7) {
        this.getContractDataByPostalCode(postalCodeValue);
      }
    });
  }

  private loadPaymentScript() {
    if (this.company.credit_code === PAYMENT_TYPES.SONY) {
      this.loadSonyPaymentScripts();
    } else if (this.company.credit_code === PAYMENT_TYPES.SMBC) {
      this.loadSMBCPaymentScripts();
    }
  }

  private subscribeTokenValueChange(e): void {
    const cardTokenUpdated = e?.target?.value;
    cardTokenUpdated && (this.cardToken = cardTokenUpdated);
    this.changeDetectorRef.detectChanges();
  }

  private subscribeCardValueChange(e): void {
    const cardNumber = e?.target?.value;
    cardNumber && (this.hiddenCardNumberProp = cardNumber);
  }

  private async reloadPaymentMethod() {
    const inputData = this.helperService.parseJson(
      this.helperService.getSessionStorageValue(STORAGE_KEYS.InfoInputValues),
    );
    const paymentMethodValue = inputData?.application?.payment?.method_value;
    const cardToken = inputData?.application?.payment?.card_token || '';
    const cardNumber = inputData?.application?.cardNumber || '';

    if (paymentMethodValue) {
      this.paymentMethodValue = paymentMethodValue;
      return;
    } else if (!isEmpty(cardNumber) && !isEmpty(cardToken)) {
      this.paymentMethodValue = PaymentMethod.CREDIT_CARD;
      this.cardNumberElement.nativeElement.value = cardNumber;
      this.cardTokenElement.nativeElement.value = cardToken;
      await Promise.all([
        this.subscribeTokenValueChange({ target: { value: cardToken } }),
        this.subscribeCardValueChange({ target: { value: cardNumber } }),
      ]);
      this.selectPaymentMethod(PaymentMethod.CREDIT_CARD, COURSE_CHOOSE[PaymentMethod.CREDIT_CARD]);
      return;
    } else {
      this.cardToken = '';
      this.hiddenCardNumberProp = '';
    }
  }

  private static capitalizeTypeHouse(typeHouse: string): string {
    return typeHouse.charAt(0).toUpperCase() + typeHouse.slice(1);
  }

  public setSMBCPaymentButton(isOpen: boolean = false) {
    // Bypass in case: close the payment popup with javascript and the isOpenSmbcPayment flag is not updated
    if (isOpen && this.isOpenSmbcPayment) {
      this.isOpenSmbcPayment = false;
      this.changeDetectorRef.detectChanges();
    }
    document.body.style.overflow = isOpen ? 'hidden' : '';

    this.isOpenSmbcPayment = isOpen;
  }

  private loadSonyPaymentScripts() {
    if (this.helperService.isFoundScript('e-scott.jp')) {
      return;
    }

    // Load 3rd script
    const paymentScript = document.createElement('script');
    paymentScript.type = 'text/javascript';
    paymentScript.src = this.paymentUrl;
    paymentScript.className = 'spsvToken';
    paymentScript.setAttribute('callBackFunc', 'setToken');
    document.head.appendChild(paymentScript);

    // Load the callback function script
    this.helperService.loadScript('../../../assets/js/payment.js');
  }

  private loadSMBCPaymentScripts() {
    const apiKey: string = this.helperService.getParamValueByLink(this.paymentUrl, 'apiKey');
    if (!apiKey) {
      this.helperService.redirect(ROUTES_PATH.Error);
      return;
    }

    this.helperService.setSessionStorage(STORAGE_KEYS.StoreId, window.btoa(apiKey));

    if (this.helperService.isFoundScript('smbc-gp.co.jp')) {
      return;
    }

    // Load 3rd script
    const paymentScript = document.createElement('script');
    paymentScript.type = 'text/javascript';
    paymentScript.src = this.paymentUrl;
    document.head.appendChild(paymentScript);
    // Load the callback function script
    this.helperService.loadScript('../../../assets/js/payment.js');
  }

  public async selectPaymentMethod(method: PaymentMethod | string, _text: string) {
    if (method !== PAYMENT_METHOD_VALUE.CREDIT_CARD) {
      await this.reloadPaymentMethod();
    }

    if (method !== '') {
      const explanatoryKeyNames = ExplanatoryKeyName[method] as {
        explanatory: string;
        explanatory_url: string;
      };

      Object.assign(this.selectedPaymentMethodExplanatory, {
        explanatory: this.company[explanatoryKeyNames.explanatory] || '',
        explanatory_url: this.company[explanatoryKeyNames.explanatory_url] || '',
      });
    }

    this.paymentMethodValue = method as PaymentMethod;
  }

  public enableCandyWPaymentCardButton({ value }: any) {
    this.isEnableCandyWCardButton = value?.trim() !== '' && !isEmpty(this.company.settlementAgency);
  }

  private getCandyWCardInfoFromCookie() {
    const currentCookie = document.cookie;
    const isNoData =
      isEmpty(currentCookie.match(CARD_INFO_COOKIE_NAME)) ||
      !isEmpty(CHECK_CARD_INFO_COOKIE_EMPTY_REGEX.exec(currentCookie));
    if (isNoData) return;
    document.cookie = `${CARD_INFO_COOKIE_NAME}=; Path=/;`;
    const keyNameIndex = 0;
    const keyvalueIndex = 1;
    const result = {};
    const cardInfoCookie = GET_CARD_INFO_COOKIE_VALUE_REGEX.exec(currentCookie)[0];
    const decodedCookie = decodeBase64(cardInfoCookie.replace(`${CARD_INFO_COOKIE_NAME}=`, ''));
    const cardInfoArray = decodedCookie.split('&');
    cardInfoArray.forEach((item) => {
      const itemArr = item.split('=');
      switch (itemArr[keyNameIndex]) {
        case 'last_name':
        case 'message':
          Object.assign(result, {
            [itemArr[keyNameIndex]]: decodePercentEncodedString(itemArr[keyvalueIndex]),
          });
          break;

        default:
          Object.assign(result, {
            [itemArr[keyNameIndex]]: itemArr[keyvalueIndex],
          });
          break;
      }
    });

    this.handleCandyWResult(result);
  }

  private async handleCandyWResult(cardResult: any) {
    const { result, message } = cardResult;
    switch (result) {
      case CANDY_W_SUCCESS_RESULT:
        this.candyWCardAdded = true;
        break;

      default:
        this.dialogService.open({ type: DIALOG_MESSAGE_TYPE.Error, message });
        break;
    }
  }

  private backToFirstStep() {
    this.dataService.setStepDataStored(PROGRESS_STEPS.StepOne.Index);
    this.helperService.redirect(ROUTES_PATH.Index);
  }
}
