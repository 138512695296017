import { HelperService } from '@core/services/helper.service';
import { ROUTES_PATH, STORAGE_KEYS, DIALOG_MESSAGE_TYPE, PROGRESS_STEPS } from '@shared/common/constants';
import { DataService } from '@core/services/data.service';
import { Component, OnInit } from '@angular/core';
import { PaymentService } from '@core/services/home/payment.service';
import { DialogServiceService } from '@core/services/dialog-service.service';

@Component({
  selector: 'app-reserve',
  templateUrl: './reserve.component.html',
  styleUrls: ['./reserve.component.scss'],
})
export class ReserveComponent implements OnInit {
  isLoading: boolean = false;
  requestId: string = null;
  buttonName: string = '';
  homeUrl: string = '';
  orderId: any;
  responseText: string = '';
  paymentStatus: string = 'PENDING';
  buttonRedirect: boolean = false;
  applicationID: any = null;
  texthead = ``;
  textAfter = ``;
  textmiddle = ``;
  text1_pending = '申込はまだ完了していません。';
  text2_pending = '画面を閉じずにお待ちください。';
  isPending = false;
  constructor(
    private readonly dataService: DataService,
    private readonly helperService: HelperService,
    private readonly paymentService: PaymentService,
    private dialogService: DialogServiceService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.orderId = sessionStorage.getItem('order_id');
    if (this.orderId) {
      this.isLoading = true;
      this.pollPaymentStatus();
      return;
    }
    this.getDataStored();
    this.clearData();
    this.texthead = `メールを送信致しましたので記載のURLより日程調整の申込をお願いします。`;
    this.textAfter = `申込後、２～３日経過してもメールが届かない場合は、お問い合わせください`;
  }

  getDataStored(): void {
    const {
      data: {
        infoInput: {
          value: {
            application: { request_id },
            company: { button_name, home_url },
          },
        },
      },
    } = this.dataService.getDataStored();
    !request_id && this.helperService.redirect(ROUTES_PATH.Error);
    this.requestId = request_id;
    if (button_name && home_url) {
      this.buttonName = button_name;
      this.homeUrl = home_url;
    }
  }

  clearData(): void {
    this.dataService.clearData();
    this.helperService.removeSessionStorageValue(STORAGE_KEYS.DataStoredSteps);
  }

  goToHomeUrl(): void {
    this.helperService.removeSessionStorageValue(STORAGE_KEYS.DataStoredSteps);
    this.helperService.removeSessionStorageValue(STORAGE_KEYS.orderID);
    this.helperService.removeSessionStorageValue(STORAGE_KEYS.requestID);
    this.helperService.removeSessionStorageValue(STORAGE_KEYS.MaxTimeApi);
    window.location.href = this.homeUrl;
  }
  async pollPaymentStatus() {
    try {
      $('.card-header-title').html('');
      $('.heading_title').html('処理中');
      $('.notice_title').html('');
      this.isPending = true;
      const data = await this.paymentService
        .postDataOfPaymentStatus({ orderId: this.orderId, type: 'SMBC' })
        .toPromise();
      if (!sessionStorage.getItem(STORAGE_KEYS.MaxTimeApi)) {
        let max_time_api: number = Math.floor(Number(data.body['max_time_api']) / 10);
        this.helperService.setSessionStorage(STORAGE_KEYS.MaxTimeApi, String(max_time_api));
      }
      if (
        data.body &&
        data.body.status &&
        data.body.status.payment_status &&
        data.body.status.payment_status !== this.paymentStatus
      ) {
        this.isPending = false;
        this.requestId = sessionStorage.getItem('request_id');
        $('.heading_title').html('お申し込みが完了しました');
        $('.card-header-title').html('お申し込み完了');
        $('.notice_title').html('申請ID');
        if (data.body.status.payment_status == 'success') {
          this.helperService.removeSessionStorageValue(STORAGE_KEYS.InfoInputValues);
          this.helperService.removeSessionStorageValue(STORAGE_KEYS.applicationID);
          this.helperService.removeSessionStorageValue(STORAGE_KEYS.MaxTimeApi);
          this.texthead = `メールを送信致しましたので記載のURLより日程調整の申込をお願いします。`;
          this.textAfter = `申込後、２～３日経過してもメールが届かない場合は、お問い合わせください`;
        } else {
          this.applicationID = sessionStorage.getItem('application_id');
          this.dialogService.open({
            type: DIALOG_MESSAGE_TYPE.Error,
            message: '3Dセキュア認証が失敗しました',
          });
          this.dataService.setStepDataStored(PROGRESS_STEPS.StepFour.Index);
          this.backPreviousStep();
        }
        this.isLoading = false;
        this.buttonRedirect = true;
      } else {
        //start setup max_time_api
        let max_time_api = Number(sessionStorage.getItem(STORAGE_KEYS.MaxTimeApi));
        if (max_time_api > 0) {
          setTimeout(() => this.pollPaymentStatus(), 10000);
          let cur_max_time_api = Number(max_time_api) - 1;
          this.helperService.setSessionStorage(STORAGE_KEYS.MaxTimeApi, String(cur_max_time_api));
        } else {
          this.isPending = false;
          this.texthead = `決済処理中にシステムエラーが発生しました。`;
          this.textAfter = `お手数ですが、時間をおいて再度お申込みください。`;
          this.textmiddle = `お申込みは完了しておりません。`;
          this.isLoading = false;
          this.buttonRedirect = true;
          this.helperService.removeSessionStorageValue(STORAGE_KEYS.InfoInputValues);
          $('.alert-check').css('background-color', '#FDDCDF');
          $('.heading_title').html('お申し込みが失敗しました');
          $('.notice_title').remove();
          $('.card-header-title').html('');
        }
      }
    } catch (error) {
      this.buttonRedirect = true;
      this.applicationID = sessionStorage.getItem('application_id');
      console.error(error);
      this.isLoading = false;
    }
  }
  backPreviousStep(): void {
    this.helperService.removeSessionStorageValue(STORAGE_KEYS.IsConfirm);
    this.helperService.redirectByOptions(ROUTES_PATH.AppInfoInput, { skipLocationChange: true });
    this.helperService.removeSessionStorageValue(STORAGE_KEYS.MaxTimeApi);
  }
}
//#dc3545
