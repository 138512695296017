import { HeaderFooterContents, ProgressData } from '@shared/common/types';
import { StepValue } from '@shared/common/types';
import { HelperService } from '@core/services/helper.service';
import { PROGRESS_STEPS, STORAGE_KEYS } from '@shared/common/constants';
import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { ProgressStored } from '@shared/common/types';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private subject = new Subject<number>();
  private headerFooterContentSubject = new Subject<HeaderFooterContents>();
  constructor(private readonly helperService: HelperService) {}

  /**
   * Clear step
   */
  public clearData(): void {
    this.subject.next();
  }

  /**
   * Get subject data
   * @returns
   */
  public getStepNumber(): Observable<number> {
    return this.subject.asObservable();
  }

  /**
   * Update step data and active step number
   * @param {Number} newStepNumber The step number
   * @param {Any} value Step value
   */
  public setStepDataStored(newStepNumber: number, value: any = {}): void {
    if (!this.isValidProgressDataStored()) {
      this.removeProgressData();
      window.location.reload();
      return;
    }

    const dataStoredStr: string = this.helperService.getSessionStorageValue(STORAGE_KEYS.DataStoredSteps);
    const dataParsed: ProgressStored = this.helperService.parseJson(dataStoredStr) || {
      activeStepNumber: PROGRESS_STEPS.StepOne.Index,
      data: {},
    };

    const currentStepNumber: number = dataParsed.activeStepNumber;
    if (currentStepNumber < newStepNumber) {
      dataParsed.data[this.getStepNameByIndex(currentStepNumber)] = {
        stepNumber: currentStepNumber,
        value,
      };
    }

    if (currentStepNumber > newStepNumber) {
      this.removeOldStepValue(dataParsed, currentStepNumber);
    }

    dataParsed.activeStepNumber = newStepNumber;
    this.helperService.setSessionStorage(STORAGE_KEYS.DataStoredSteps, JSON.stringify(dataParsed));
    this.subject.next(newStepNumber);
  }

  /**
   * get step name by step number
   * @param {Number} stepNumber Step number
   * @returns {String} The step name
   */
  private getStepNameByIndex(stepNumber: number): string {
    const existingStepKey: string = Object.keys(PROGRESS_STEPS).find(
      (key: string) => PROGRESS_STEPS[key].Index === stepNumber,
    );
    if (!existingStepKey) {
      return '';
    }

    return PROGRESS_STEPS[existingStepKey].Name;
  }

  /**
   * Get step value
   * @param {Number} stepNumber Step number
   * @returns {object} Step value
   */
  public getDataStored(stepNumber: number = null): any {
    const dataStoredStr: string = this.helperService.getSessionStorageValue(STORAGE_KEYS.DataStoredSteps);
    const dataParsed: ProgressStored = this.helperService.parseJson(dataStoredStr);
    if (!stepNumber) {
      return dataParsed;
    }
    const stepObj: StepValue = dataParsed.data[this.getStepNameByIndex(stepNumber)];
    return stepObj ? stepObj.value : null;
  }

  /**
   * Remove old step value in case back page
   * @param {ProgressStored} dataParsed Data parsed
   * @param {Number} removeFromStep Remove data from steps
   *
   */
  private removeOldStepValue(dataParsed: ProgressStored, removeFromStep: number): void {
    if (!dataParsed.data || !removeFromStep) {
      return;
    }

    const progressDataClone: ProgressStored = _.cloneDeep(dataParsed);
    Object.keys(progressDataClone.data).forEach((key: string): void => {
      const stepValue: StepValue = dataParsed.data[key] || {};
      if (stepValue && stepValue.stepNumber >= removeFromStep) {
        delete dataParsed.data[key];
      }
    });
  }

  /**
   * Check the progress data is valid or not
   * @returns {Boolean} Check result
   */
  public isValidProgressDataStored(): boolean {
    const dataStoredStr: string = this.helperService.getSessionStorageValue(STORAGE_KEYS.DataStoredSteps);
    const dataStored: ProgressStored = this.helperService.parseJson(dataStoredStr);
    if (!dataStored) {
      return true;
    }

    const activeStepStored: number = Number(dataStored?.activeStepNumber);
    const stepsData: ProgressData = dataStored?.data;
    if (!this.isValidStepNumber(activeStepStored) || !stepsData) {
      return false;
    }

    const requiredStepsKey: Array<string> = Object.keys(PROGRESS_STEPS)
      .filter((key: string) => PROGRESS_STEPS[key].Index < activeStepStored)
      .map((stepName: string) => PROGRESS_STEPS[stepName].Name);
    const existedStepsKey: Array<string> = _.cloneDeep(requiredStepsKey).filter(
      (stepName: string) => stepsData[stepName],
    );

    if (!_.isEqual(requiredStepsKey, existedStepsKey)) {
      return false;
    }

    return true;
  }

  /**
   * Remove progress data on session storage
   */
  public removeProgressData(): void {
    this.helperService.removeSessionStorageValue(STORAGE_KEYS.DataStoredSteps);
    this.helperService.removeSessionStorageValue(STORAGE_KEYS.InfoInputValues);
  }

  /**
   * Check the active step number stored is valid or not
   * @param {Number} activeStepStored Active step number
   * @returns {Boolean} valid/invalid step number
   */
  private isValidStepNumber(activeStepStored: number = 0): boolean {
    return activeStepStored >= PROGRESS_STEPS.StepOne.Index && activeStepStored <= PROGRESS_STEPS.StepFive.Index;
  }

  /**
   * Get header footer data
   * @returns {Observable<HeaderFooterContents>}
   */
  public getHeaderFooterContent(): Observable<HeaderFooterContents> {
    return this.headerFooterContentSubject.asObservable();
  }

  /**
   * Set header footer content
   * @param {String} headerContent Header content
   * @param {String} footerContent Footer content
   */
  public setHeaderFooterContent(headerContent: string = '', footerContent: string = ''): void {
    this.helperService.setSessionStorage(STORAGE_KEYS.HeaderContent, headerContent);
    this.helperService.setSessionStorage(STORAGE_KEYS.FooterContent, footerContent);
    this.headerFooterContentSubject.next({ headerContent, footerContent });
  }
}
