<div class="container-fluid shadow">
  <section class="card">
    <div class="card-header">
      提供状況の確認
    </div>
    <div class="card-body section c-mt-5">
      <h6 class="text-center title">お住まいの建物の種類を選択してください</h6>
        <div class="row justify-content-center c-mt-3">
          <button (click)="clickSelectHouseType(HOUSE_TYPE.Collective)" type="button" class="btn btn-shadow {{
            selectedHouseType === HOUSE_TYPE.Collective
              ? 'btn-black'
              : selectedHouseType ? 'btn-unselected' : 'btn-choose-white'
            }} btn-cw-250 btn-ch-70 btn-search-type">
            <span class="d-flex">
              <img class="btn-icon mr-2 {{ selectedHouseType ? 'img-brightness' : '' }}" src="{{
                selectedHouseType === HOUSE_TYPE.Collective
                  ? './assets/img/icon-btn-building.png'
                  : selectedHouseType ? './assets/img/icon-btn-building.png' : './assets/img/icon-btn-building-selected@2x.png'
                }}">
              <span>集合住宅</span>
            </span>
          </button>
          <button (click)="clickSelectHouseType(HOUSE_TYPE.Detached)"
            type="button"
            class="btn btn-shadow {{
              selectedHouseType === HOUSE_TYPE.Detached
                ? 'btn-black'
                : selectedHouseType ? 'btn-unselected' : 'btn-choose-white'
              }} btn-cw-250 btn-ch-70 btn-search-type">
            <span class="d-flex">
              <img class="btn-icon mr-2 {{ selectedHouseType ? 'img-brightness' : '' }}" src="{{
                selectedHouseType === HOUSE_TYPE.Detached
                  ? './assets/img/icon-btn-home.png'
                  : selectedHouseType ? './assets/img/icon-btn-home.png' : './assets/img/icon-btn-home-selected.png'
                }}">
              <span>戸建住宅</span>
            </span>
          </button>
        </div>
    </div>

    <div class="card-body section c-mt-3">
      <h6 class="text-center title"> 郵便番号（半角・ハイフンなし）を入力してください。</h6>
      <div class="row justify-content-center c-mt-3">
        <div class="postal-search">
          <span class="align-middle postal-icon" [ngClass]="postalCodeInput ? 'text-shadow': 'text-disable'">〒</span>
          <img *ngIf="postalCodeInput" src="assets/img/btn-zipcode-delete@2x.png" class="position-absolute btn-remove-postal-code" (click)="clearPostalCodeInput()"/>
          <input type="tel" #inputPostalCode
            class="form-control c-fs-30 text-shadow"
            [(ngModel)] = "postalCodeInput" [disabled]="!selectedHouseType"
            (keyup)="onChangePostalCodeInput()"
            placeholder="1230001"
            maxlength="7"
            pattern="\d*">
        </div>
        <button type="button" class="btn btn-cw-170 btn-ch-50 btn-shadow btn-black" [disabled]="postalCodeInput?.length < 8" (click)="onSearchAddresses()">
          <span><fa-icon [icon]="faAngleRight"></fa-icon>住所を検索する</span>
        </button>
      </div>
    </div>
    <div class="card-body section c-mt-3">
      <h6 class="heading">住所を選択してください</h6>
      <div class="select-section c-mt-3">
        <div dropdown [isDisabled]='!groupAddressesList.length' [autoClose]="true" #dropdownAddress="bs-dropdown">
          <button id="button-autoclose1" dropdownToggle type="button" class="btn btn-block dropdown-toggle d-flex text-selected c-ff-h3 c-fs-19 my-0"
            [ngClass]="!groupAddressesList.length ? 'text-shadow': 'text-gray'"
                  aria-controls="dropdown-autoclose1" (click)="onShown($event, dropdownAddress);">
            <span class="d-flex justify-content-between w-100 ">
              <span class="d-inline-block text-truncate {{!groupAddressesList.length ? 'c-ff-h6': ''}}" >
                {{ (groupAddressDisplayInfo(selectedGroupAddress) | addressDetailFormatPipe) || '選択してください' }}
              </span>
              <img class="dropdown-arrow" src="assets/img/icon-pulldown@2x.png">
            </span>
          </button>
          <ul id="dropdown-autoclose1" *dropdownMenu class="dropdown-menu mb-3 py-0"
              role="menu" aria-labelledby="button-autoclose1">
            <li *ngFor ="let groupAddress of groupAddressesList" role="menuitem">
              <div class="dropdown-item  py-2" (click)="clickSelectGroupAddress(groupAddress)">
                <div class="c-ff-h3 c-fs-19 address-item">{{ groupAddressDisplayInfo(groupAddress) | addressDetailFormatPipe }}</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div [hidden]="!selectedGroupAddress" class="card-body section c-mt-3">
      <h6 class="heading">番地以降を選択してください。</h6>
      <form [style.display]="isShowForm ? 'block' : 'none'" [formGroup]="addressInputForm">
        <div *ngIf="selectedGroupAddress" class="form-inline address-search c-mt-3 c-fs-19 text-shadow c-ff-h3">
          <div class="mr-2 selected-address-title">{{ addressInfoShowOnInput() | addressDetailFormatPipe}} </div>
          <div class="form-inline align-self-center input-address-container">
            <div class="form-inline align-self-center">
              <input class="form-control c-input text-center address"
                type="text" placeholder="１２"
                maxlength="5"
                formControlName="address">
                <span class="mx-2">番地</span>
            </div>
            <div class="form-inline align-self-center">
              <input class="form-control c-input text-center addressNumber"
                type="text" placeholder="３"
                formControlName="addressNumber">
                <span class="mx-2">号</span>
            </div>
          </div>
        </div>
      </form>
      <span [style.display]="!isShowForm ? 'block' : 'none'">該当の番地・号がリストにない場合は、番地・号無しを選択して下さい。</span>
      <div *ngIf="houseTypeStored === HOUSE_TYPE.Collective" class="select-section c-mt-3">
        <div dropdown [isDisabled]='!selectedGroupAddress?.details?.length' [autoClose]="false" #dropdownBuilding="bs-dropdown">
          <button id="button-basic" dropdownToggle type="button" class="btn btn-block dropdown-toggle text-selected c-ff-h3 c-fs-19 my-0"
                  aria-controls="dropdown-basic"
                  [ngClass]="!selectedGroupAddress?.details?.length ? 'text-shadow': 'text-gray'" (click)="onShown($event, dropdownBuilding);">
            <span class="d-flex justify-content-between w-100">
              <span class="d-inline-block text-truncate {{ !selectedGroupAddress?.details?.length ? 'c-ff-h6': ''}}" >
                {{ selectedAddress ? selectedAddress.apartment_name : '選択してください' }}
              </span>
              <img class="dropdown-arrow" src="assets/img/icon-pulldown@2x.png">
            </span>
          </button>
          <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu mb-3 py-0"
              role="menu" aria-labelledby="button-basic">
            <li *ngFor ="let addressDetail of addressesListFilter" role="menuitem">
              <div class="dropdown-item" (click)="clickSelectAddressDetail(addressDetail)">
                <div class="c-fs-16 c-ff-h3">{{ getBuildingAddress(addressDetail) }}</div>
                <span *ngIf="addressDetail.apartment_name" class="text-green c-fs-19 c-ff-h6">{{ addressDetail.apartment_name }}</span>
              </div>
            </li>
            <li role="menuitem" *ngIf="otherBuildingDisplayFlag">
              <div class="dropdown-item py-3" (click)="clickSelectAddressDetail(null, true);">
                <span class="text-green c-fs-19 c-ff-h6">{{otherBuildingName}}</span>
              </div>
            </li>
            <!-- No address -->
            <li role="menuitem" *ngIf="!addressesListFilter?.length && !otherBuildingDisplayFlag">
              <div class="dropdown-item">
                <span class="text-green c-fs-19 c-ff-h6">見つかりません</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div *ngIf="houseTypeStored === HOUSE_TYPE.Detached" class="select-section c-mt-3">
        <div dropdown [isDisabled]='!selectedGroupAddress?.details?.length' [autoClose]="false" #dropdownDetachedAddress="bs-dropdown">
          <button id="button-basic" dropdownToggle type="button" class="btn btn-block dropdown-toggle text-selected c-ff-h3 c-fs-19 my-0"
                  aria-controls="dropdown-basic"
                  [ngClass]="!selectedGroupAddress?.details?.length ? 'text-shadow': 'text-gray'" (click)="onShown($event, dropdownDetachedAddress);">
            <span class="d-flex justify-content-between w-100">
              <span class="d-inline-block text-truncate {{ !selectedGroupAddress?.details?.length ? 'c-ff-h6': ''}}" >
                {{selectedAddress ? (getDetachAddress(selectedGroupAddress, selectedAddress) | addressDetailFormatPipe) : '選択してください'}}
              </span>
              <img class="dropdown-arrow" src="assets/img/icon-pulldown@2x.png">
            </span>
          </button>
          <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu mb-3 py-0"
              role="menu" aria-labelledby="button-basic">
            <li *ngFor ="let addressDetail of addressesListFilter" role="menuitem">
              <div class="dropdown-item py-2" (click)="clickSelectAddressDetail(addressDetail)">
                <div class="c-fs-19 c-ff-h3 address-item">{{(getDetachAddress(selectedGroupAddress, addressDetail)) | addressDetailFormatPipe}}</div>
              </div>
            </li>
            <!-- No address -->
            <li role="menuitem" *ngIf="!addressesListFilter?.length">
              <div class="dropdown-item">
                <span class="text-green c-fs-19 c-ff-h6">見つかりません</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="footer align-items-center c-mt-5 c-mb-5">
      <div class="col-12 d-flex justify-content-center">
        <button class="btn btn-lime btn-cw-260 btn-ch-70" (click)="clickNextStep();" [disabled]="!allowNextStep">
          <span class="btn-text"><fa-icon [icon]="faAngleRight"></fa-icon>提供エリアを確認する</span>
        </button>
      </div>
    </div>
  </section>
</div>

<app-loading *ngIf="isLoading"></app-loading>
