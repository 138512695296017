import { ADDRESS_CHARACTER, ADDRESS_NUMBER_CHARACTER } from '@shared/common/constants';
import { Address } from '@core/models/area.model';
import { LocalStorageItem } from '@shared/common/types';
import { CHOME_CHARACTER, ROUTES_PATH } from '@shared/common/constants';
import { Title } from '@angular/platform-browser';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  constructor(private router: Router, private titleService: Title) {}

  /**
   * Set page title
   * @param {string} title The page title to set
   */
  public setPageTitle(title: string): void {
    this.titleService.setTitle(title);
  }

  /**
   * Redirect page
   * @param {string} route The page route
   * @param {boolean} isAdmin Is redirect admin page
   */
  public redirect(route: string, isAdmin = false): void {
    this.router.navigate([HelperService.getRouteByType(route, isAdmin)]).then();
  }

  /**
   * Redirect page
   * @param {string} route The page route
   */
  public adminRedirect(route: string): void {
    this.redirect(route, true);
  }

  /**
   * Redirect page
   * @param {string} route The page route
   * @param {any} options
   * @param {boolean} isAdmin Is redirect admin page
   */
  public redirectByOptions(route: string, options: any = {}, isAdmin = false): void {
    this.router.navigate([HelperService.getRouteByType(route, isAdmin)], options).then();
  }

  /**
   * Redirect page
   * @param {string} route The page route
   * @param {any} options Is redirect admin page
   */
  public adminRedirectByOptions(route: string, options: any = {}): void {
    this.redirectByOptions(route, options, true);
  }

  /**
   * Get full route
   * @param {string} route The page route
   * @param {boolean} isAdmin Is redirect admin page
   * @returns Full route
   */
  private static getRouteByType(route: string, isAdmin = false): string {
    if (!isAdmin) {
      return `/${route}`;
    }

    return `/${ROUTES_PATH.Admin}/${route}`;
  }

  /**
   * Remove local storage by keys
   * @param {Array<String>} keys Local storage keys
   */
  public removeLocalStorageItems(keys: string[]): void {
    keys.forEach((key: string) => {
      localStorage.removeItem(key);
    });
  }

  /**
   * Get local storage value by key
   * @param {string} key The local storage key
   * @returns {string} Key value
   */
  public getLocalStorageItem(key: string): string {
    return localStorage.getItem(key) || '';
  }

  /**
   * Set local storage items
   * @param {LocalStorageItem[]} items The local storage items
   */
  public setLocalStorageItems(items: LocalStorageItem[]): void {
    items.forEach((item: LocalStorageItem) => {
      localStorage.setItem(item.key, item.value);
    });
  }

  /**
   * Load script
   * @param {string} url The script url
   * @returns {void}
   */
  loadScript(url: string): void {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    script.className = 'current-script';
    body.appendChild(script);
  }
  /**
   * Parse data stored in storage
   * @param {String} dataStoredStr data storage
   * @returns {object | boolean | Array<any>} raw data
   */
  public parseJson(dataStoredStr: string): any {
    try {
      return JSON.parse(dataStoredStr);
    } catch {
      return null;
    }
  }

  /**
   * Set session storage
   * @param {string} key session key
   * @param {string} value session value
   */
  public setSessionStorage(key: string, value: string): void {
    if (!value) {
      return;
    }

    sessionStorage.setItem(key, value);
  }

  /**
   * Get session storage value by key
   * @param {string} key session key
   * @returns {string}
   */
  public getSessionStorageValue(key: string): string {
    if (!key) {
      return '';
    }

    return sessionStorage.getItem(key) || '';
  }

  /**
   * Remove session storage value by key
   * @param {string} key session key
   * @returns {string}
   */
  public removeSessionStorageValue(key: string): void {
    if (!key) {
      return;
    }

    sessionStorage.removeItem(key);
  }

  /**
   * Format address to show on UI
   * @param {Address} address The address detail obj
   */
  public addressDisplayFormat(address: Address): string {
    let addressStr: string = (address.prefectures || '') + (address.municipalities || '') + (address.town_area || '');
    address.chome && (addressStr += `${address.chome ? address.chome + CHOME_CHARACTER : ''}`);
    address.address && (addressStr += `${address.address}${ADDRESS_CHARACTER}`);
    address.address_number && (addressStr += `${address.address_number}${ADDRESS_NUMBER_CHARACTER}`);
    address.apartment_name && (addressStr += address.apartment_name);

    return addressStr;
  }

  /**
   * Open blank page
   * @param {String} url The url
   */
  public openBlankPage(url: string): void {
    window.open(url, '_blank');
  }

  /**
   * Convert text have type fullwidth or haftwidth to latin
   * @param {String} text The text
   * @returns {String} The text converted
   */
  public convertFullOrHaftWidthToLatin(text: string = ''): string {
    return text.replace(/[\uff01-\uff5e]/g, (character) => String.fromCharCode(character.charCodeAt(0) - 0xfee0));
  }

  /**
   * Extract number from text
   * @param {String} text The text
   * @returns {String} The number extracted
   */
  public extractNumberFromString(text: string = ''): string {
    return text.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
  }

  /**
   * Extract file name from url
   * @param {String} url The url
   * @returns {String} The file name
   */
  public extractFileNameFromUrl(url: string = ''): string {
    const match: Array<string> = url.match(/([^/?#]+)[^/]*$/);
    if (match.length && match.length > 1) {
      return match[1];
    }

    return '';
  }

  /**
   * Check the text is html or not
   * @param {string} text The test to check
   * @returns {boolean} The result
   */
  public isHTML(text: string): boolean {
    return /(<([^>]+)>)/i.test(text);
  }

  /**
   * Convert text to html ( \n\t -> <br>)
   * @param {String} text
   * @returns {String}
   */
  public convertText2Html(text: string): string {
    return text.replace(/(?:\r\n|\r|\n)/g, '<br>');
  }

  public isFoundScript(includeText: string): boolean {
    const scripts: any = document.getElementsByTagName('script') || [];
    for (let i = 0; i < scripts.length; i++) {
      const script = scripts[i];
      if (script.getAttribute('src') && script.getAttribute('src').includes(includeText)) {
        return true;
      }
    }

    return false;
  }

  public getParamValueByLink(link, paramName: string): string {
    const url = new URL(link);
    return url.searchParams.get(paramName) || '';
  }

  public decodeBase64EncodeString(encodeString: string): string {
    if (!encodeString) {
      return '';
    }

    try {
      return window.atob(encodeString);
    } catch {
      return '';
    }
  }
}
