<div class="container-fluid shadow reserve-page">
  <section class="card">
    <div class="card-header c-ff-h6">
      お申し込み完了
    </div>
    <div class="card-body section card-custom">
      <h6 class="heading c-ff-h6">お申し込みが完了しました</h6>
      <div class="alert alert-common p-4 text-center rounded">
        <p class="c-fw-6 c-fs-19 mb-3 c-ff-h6">申請ID</p>
        <p class="c-fw-6 c-fs-30 request-id mb-3 c-ff-h6">{{ requestId }}</p>
        <p class="c-fs-16 mb-0 c-ff-h3">メールを送信致しましたので記載のURLより日程調整の申込をお願いします。<br />申込後、２～３日経過してもメールが届かない場合は、お問い合わせください。</p>
      </div>
      <div *ngIf="buttonName && homeUrl" class="text-center d-flex justify-content-center py-4">
        <button class="btn btn-white btn-home" (click)="goToHomeUrl();">
          {{buttonName}}
        </button>
      </div>
    </div>
  </section>
</div>
