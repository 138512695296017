<div class="container-fluid shadow reserve-page">
  <section *ngIf="isDone; else construction" class="card">
    <div class="card-header c-ff-h6">
      日程のご希望受け付け
    </div>
    <div class="card-body section card-custom">
      <h6 class="heading c-ff-h6">ご希望の日程を受け付けました</h6>
      <div class="alert alert-common p-4 text-center rounded">
        <p class="c-fw-6 c-fs-19 mb-3 c-ff-h6">申請ID</p>
        <p class="c-fw-6 c-fs-30 request-id mb-3 c-ff-h6">{{ requestId }}</p>
        <p class="c-fs-16 mb-0 c-ff-h3">メールを送信致しましたのでご確認ください。</p>
        <p class="c-fs-16 mb-0 c-ff-h3">後日、担当者よりご連絡させていただきます。</p>
      </div>
      <div *ngIf="buttonName && homeUrl" class="text-center d-flex justify-content-center py-3">
        <button class="btn btn-white btn-home" (click)="goToHomeUrl();">
          {{buttonName}}
        </button>
      </div>
    </div>
  </section>
  <ng-template #construction>
    <section *ngIf="!isDone" class="card">
      <div class="card-header c-ff-h6">
        ご希望の日程
      </div>
      <div class="card-body section text-shadow card-custom">
        <h6 class="heading c-ff-h6 mt-0">ご希望の日程をカレンダーより選択してください</h6>
        <p class="c-ff-h3 pre-white-space">{{ attentionMessage }}</p>
        <p class="text-center" *ngIf="isHiddenCalendar">既に日程希望を申込済みの為、設定できません。</p>
        <ng-container *ngIf="!isHiddenCalendar">
          <p class="text-select c-ff-h3">※最大{{ calendar.MaxChoose }}日まで希望日を選択可能です</p>
          <div class="d-flex align-items-center wrap-icon">
            <p class="o-icon mb-0 c-ff-h3"><img class="img-scale" src="/assets/img/icon-calender-circle@2x.png" alt="icon" /> <span>…選択可能</span></p>
            <p class="x-icon mb-0 c-ff-h3"><img class="img-scale" src="/assets/img/icon-calender-cross@2x.png" alt="icon" /> <span>…選択不可</span></p>
          </div>
          <div class="calendar mx-auto border-bottom border-right rounded-top">
            <div class="calendar__direction d-flex justify-content-between align-items-center border border-bottom-0 border-right-0 p-2">
              <p [ngClass]="{
                'invisible': currentMonth === monthChange && currentYear === yearChange
              }" role="button" class="prev c-fw-6 text-shadow mb-0 position-relative c-ff-h6">
                <fa-icon class="content-full" (click)="prevMonth()" [icon]="faAngleLeft"></fa-icon>
                {{ monthPrev }}月
              </p>
              <p class="font-weight-bold h5 mb-0 c-fw-6 c-ff-h6 current-month">{{ monthChange }}月</p>
              <p
                role="button"
                class="next font-weight-bold h6 c-fw-6 mb-0 position-relative c-ff-h6"
                [ngClass]="{'disabled-next': disabledNextMonth}"
              >
                {{ monthNext }}月
                <fa-icon class="content-full" (click)="nextMonth()" [icon]="faAngleRight"></fa-icon>
              </p>
            </div>
            <div class="d-flex calendar__header border border-right-0 border-bottom-0">
              <div
                class="d-flex justify-content-center align-items-center border-right c-ff-h6"
                [ngClass]="{
                  'border-right-0': last
                }"
                *ngFor="let item of calendarHeader; let last = last"
              >
                {{ item }}
              </div>
            </div>
            <div class="d-flex flex-wrap calendar__body">
              <div
                *ngFor="let item of arrCalendar"
                class="border border-right-0 border-bottom-0 p-1"
                role="button"
                [ngClass]="{
                  'no-click bg-light': !item || item?.frame?.length === 0 || item?.allFrameCountZero,
                  'disabled-date': arrDateChoose?.length === calendar.MaxChoose && !item.choose
                }"
              >
                <div
                  class="d-flex flex-column justify-content-center align-items-center w-100 h-100 rounded"
                  (click)="!item.choose ? showModal(modalCalendar, item) : removeDate(item.date)"
                  [ngClass]="{'bg-selected-date text-white': checkIncludes(arrDateChoose, item?.date)}"
                >
                  <ng-container *ngIf="item">
                    <p class="mb-0 c-ff-h3">{{ item?.day }}</p>
                    <p *ngIf="item?.frame?.length !== 0 && !item?.allFrameCountZero; else noFrame" class="icon-active mb-0">
                      <img *ngIf="!item?.choose; else choose" class="img-scale" src="/assets/img/icon-calender-circle@2x.png" alt="icon" />
                      <ng-template #choose>
                        <img class="img-scale" src="/assets/img/icon-calender-check@2x.png" alt="icon" />
                      </ng-template>
                    </p>
                    <ng-template #noFrame>
                      <p class="mb-0 h4 text-secondary">
                        <img class="img-scale" src="/assets/img/icon-calender-cross@2x.png" alt="icon" />
                      </p>
                    </ng-template>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
          <div *ngFor="let item of arrDateChoose; let i = index" class="card card-secondary mb-4">
            <div class="card-header c-fw-6 text-shadow c-ff-h6">
              ご希望の日程{{ i + 1}}
            </div>
            <div class="card-body">
              <div class="form-row">
                <div class="form-group col-md-3">
                  <input type="text" readonly class="input-date form-control bg-white text-shadow c-ff-h3" value="{{ item?.date }}">
                </div>
                <div class="form-group col-md-3">
                  <div dropdown #dropdown="bs-dropdown">
                    <button
                      dropdownToggle
                      type="button"
                      class="btn dropdown-toggle btn-block d-flex position-relative"
                      (click)="onShown($event, dropdown);"
                    >
                      <div id="{{ formatDate(item?.date) }}" [attr.data-date]="item?.date + '-' + item?.timeZone" class="text-shadow c-ff-h3 selected-date">{{ item?.timeZoneText }}</div>
                      <fa-icon class="position-absolute image-dropdown" [icon]="faChevronDown"></fa-icon>
                    </button>
                    <ul *dropdownMenu class="dropdown-menu mb-3 py-0" role="menu">
                      <li role="menuitem">
                        <div class="dropdown-item py-2" (click)="changeTimeZone(formatDate(item?.date), timeZone.NotSpecified.value, timeZone.NotSpecified.text)">
                          <div class="c-ff-h3 c-fs-16 text-shadow">{{ timeZone.NotSpecified.text }}</div>
                        </div>
                      </li>
                      <li *ngIf="!checkTimeZone(item?.frame, timeZone.Morning.value)" role="menuitem">
                        <div class="dropdown-item py-2" (click)="changeTimeZone(formatDate(item?.date), timeZone.Morning.value, timeZone.Morning.text)">
                          <div class="c-ff-h3 c-fs-16 text-shadow">{{ timeZone.Morning.text }}</div>
                        </div>
                      </li>
                      <li *ngIf="!checkTimeZone(item?.frame, timeZone.Afternoon.value)" role="menuitem">
                        <div class="dropdown-item py-2" (click)="changeTimeZone(formatDate(item?.date), timeZone.Afternoon.value, timeZone.Afternoon.text)">
                          <div class="c-ff-h3 c-fs-16 text-shadow">{{ timeZone.Afternoon.text }}</div>
                        </div>
                      </li>
                      <li *ngIf="!checkTimeZone(item?.frame, timeZone.Evening.value)" role="menuitem">
                        <div class="dropdown-item py-2" (click)="changeTimeZone(formatDate(item?.date), timeZone.Evening.value, timeZone.Evening.text)">
                          <div class="c-ff-h3 c-fs-16 text-shadow">{{ timeZone.Evening.text }}</div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-4"></div>
                <div class="col-md-2 text-center">
                  <p (click)="removeDate(item?.date)" role="button" class="button-delete py-1 mt-1 text-red bg-light rounded-lg border c-ff-h3">
                    <fa-icon [icon]="faTrashAlt"></fa-icon> 削除する
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="footer row mt-5 align-items-center">
            <div class="col-12 d-flex justify-content-center">
              <button [disabled]="disabledSubmit" class="btn btn-lime btn-cw-260 btn-ch-70 mb-4" (click)="done()">
                <span class="btn-text c-ff-h6"><fa-icon [icon]="faAngleRight"></fa-icon> ご希望の日程を申し込む</span>
              </button>
            </div>
          </div>
        </ng-container>
      </div>
    </section>
    <ng-template #modalCalendar>
      <div class="modal-header bg-light py-3">
        <span class="modal-title pull-left text-dark">時間帯を選択してください</span>
        <button type="button" class="btn-close close pull-right text-dark" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
      </div>
      <div class="modal-body p-3">
        <div class="d-flex justify-content-center">
          <p class="text-dark font-weight-bold btn-modal m-2 mb-0">{{ dataModal?.date }}</p>
          <p class="text-dark font-weight-bold btn-modal m-2 mb-0"></p>
        </div>
        <div class="d-flex justify-content-center">
          <button
            class="btn bg-white m-2 rounded border btn-modal"
            (click)="chooseTimeZone(dataModal, $event, timeZone.NotSpecified.value, timeZone.NotSpecified.text)"
          >{{ timeZone.NotSpecified.text }}</button>
          <button
            class="btn bg-white m-2 rounded border btn-modal"
            [disabled]="checkTimeZone(dataModal?.frame, timeZone.Morning.value)"
            (click)="chooseTimeZone(dataModal, $event, timeZone.Morning.value, timeZone.Morning.text)"
          >{{ timeZone.Morning.text }}</button>
        </div>
        <div class="d-flex justify-content-center">
          <button
            class="btn bg-white m-2 rounded border btn-modal"
            [disabled]="checkTimeZone(dataModal?.frame, timeZone.Afternoon.value)"
            (click)="chooseTimeZone(dataModal, $event, timeZone.Afternoon.value, timeZone.Afternoon.text)"
          >{{ timeZone.Afternoon.text }}</button>
          <button
            class="btn bg-white m-2 rounded border btn-modal"
            [disabled]="checkTimeZone(dataModal?.frame, timeZone.Evening.value)"
            (click)="chooseTimeZone(dataModal, $event, timeZone.Evening.value, timeZone.Evening.text)"
          >{{ timeZone.Evening.text }}</button>
        </div>
        <hr />
        <button class="btn m-2 p-2 bg-light border rounded float-right" (click)="closeModal()">キャンセル</button>
      </div>
    </ng-template>
  </ng-template>
</div>
<app-loading *ngIf="isLoading"></app-loading>
