import { CompanyService } from '@core/services/home/company.service';
import { ActivatedRoute } from '@angular/router';
import { ApiResponse, ProgressStored } from '@shared/common/types';
import { GroupAddress, FormActionProps } from '@core/models/area.model';
import { FormGroup, FormBuilder } from '@angular/forms';
import {
  PROGRESS_STEPS,
  HOUSE_TYPE,
  RESULT_CODES,
  DIALOG_MESSAGE_TYPE,
  MESSAGES,
  ROUTES_PATH,
  STORAGE_KEYS,
  QUERY_PARAMS_KEY,
  DEFAULT_FOOTER_ELEMENT,
  DEFAULT_HEADER_ELEMENT,
  OTHER_BUILDING_NAME,
  BOOLEAN_MAPPING_VALUE,
  DECRYPT_DATA_FAIL_COOKIE_NAME,
  NOT_FOUND_MEMBER_ID_COOKIE_NAME,
} from '@shared/common/constants';
import { HelperService } from '@core/services/helper.service';
import { DataService } from '@core/services/data.service';
import { DialogServiceService } from '@core/services/dialog-service.service';
import { AreaService } from '@core/services/home/area.service';
import { Address } from '@core/models/area.model';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { faHome, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import * as _ from 'lodash';
import { convertCookieStringToArray } from '@shared/utils/common-function-util';

@Component({
  selector: 'app-area-search',
  templateUrl: './area-search.component.html',
  styleUrls: ['./area-search.component.scss'],
})
export class AreaSearchComponent implements OnInit {
  @ViewChild('dropdownAddress') dropdownAddress: BsDropdownDirective;
  @ViewChild('dropdownBuilding') dropdownBuilding: BsDropdownDirective;
  @ViewChild('dropdownDetachedAddress') dropdownDetachedAddress: BsDropdownDirective;
  @ViewChild('inputPostalCode') inputPostalCode: ElementRef;
  // Icons
  faHome: any = faHome;
  faAngleRight: any = faAngleRight;
  isLoading: boolean = false;
  HOUSE_TYPE: any = HOUSE_TYPE;
  public routes = ROUTES_PATH;
  private lastOpened: BsDropdownDirective = null;

  addressInputForm: FormGroup;
  postalCodeInput: string = '';

  selectedHouseType: string;
  groupAddressesList: Array<GroupAddress> = [];
  // Only use to show on UI
  addressesListFilter: Array<Address> = [];
  selectedAddress: Address;
  selectedGroupAddress: GroupAddress;

  postalCodeStored: string = '';
  houseTypeStored: string = '';
  originalCompanyCode: string = '';
  otherBuildingDisplayFlag: number = BOOLEAN_MAPPING_VALUE.True;
  otherBuildingName: string = OTHER_BUILDING_NAME;

  constructor(
    private readonly dataService: DataService,
    private readonly helperService: HelperService,
    private readonly dialogService: DialogServiceService,
    private areaService: AreaService,
    private formBuilder: FormBuilder,
    private readonly activatedRoute: ActivatedRoute,
    private readonly companyService: CompanyService,
  ) {
    this.addressInputForm = this.formBuilder.group({
      address: [{ value: '', disabled: true }],
      addressNumber: [{ value: '', disabled: true }],
    });
  }

  async ngOnInit(): Promise<void> {
    this.checkCookieErrorMsg();
    const dataParsed: ProgressStored = this.dataService.getDataStored();
    const activeStepNumber = dataParsed?.activeStepNumber;
    const routeRedirect: string = this.routeRedirectBasedOnDataStored(activeStepNumber);
    if (routeRedirect) {
      this.helperService.redirectByOptions(routeRedirect, { skipLocationChange: true });
      return;
    }

    const companyCode: string = this.activatedRoute.snapshot.queryParamMap.get(QUERY_PARAMS_KEY.OriginalCompanyCode);
    companyCode && (this.originalCompanyCode = companyCode);

    if (dataParsed?.data?.areaSearch) {
      await this.reInitDataStored(dataParsed.data.areaSearch.value);
    }

    this.onChangeFormValues();

    // Set header and footer to default
    this.dataService.setHeaderFooterContent(DEFAULT_HEADER_ELEMENT, DEFAULT_FOOTER_ELEMENT);
  }

  /**
   * Get route redirect by progress data stored
   * @param {Number} activeStepNumber Active step
   * @returns {string}
   */
  private routeRedirectBasedOnDataStored(activeStepNumber: number): string {
    let routeRedirect: string = '';
    if (activeStepNumber && activeStepNumber != PROGRESS_STEPS.StepOne.Index) {
      if (
        activeStepNumber === PROGRESS_STEPS.StepTwo.Index &&
        this.dataService.getDataStored(PROGRESS_STEPS.StepOne.Index)
      ) {
        routeRedirect = ROUTES_PATH.CourseChoose;
      } else if (
        activeStepNumber === PROGRESS_STEPS.StepThree.Index &&
        this.dataService.getDataStored(PROGRESS_STEPS.StepTwo.Index)
      ) {
        routeRedirect = ROUTES_PATH.HomeTermsOfService;
      } else if (activeStepNumber === PROGRESS_STEPS.StepFour.Index) {
        routeRedirect =
          this.helperService.getSessionStorageValue(STORAGE_KEYS.InfoInputValues) &&
          this.helperService.getSessionStorageValue(STORAGE_KEYS.IsConfirm)
            ? ROUTES_PATH.AppInfoConfirm
            : ROUTES_PATH.AppInfoInput;
      } else if (
        activeStepNumber === PROGRESS_STEPS.StepFive.Index &&
        this.dataService.getDataStored(PROGRESS_STEPS.StepFour.Index)
      ) {
        routeRedirect = ROUTES_PATH.Reserve;
      }
    }

    return routeRedirect;
  }

  /**
   * Handle form onchange  values
   */
  private onChangeFormValues(): void {
    this.addressInputForm.valueChanges.subscribe((formValues) => {
      // Filter address number by address
      this.addressesListFilter =
        this.selectedGroupAddress?.details?.filter((address: Address) =>
          this.filterAddressCondition(address, formValues),
        ) || [];
    });
  }

  /**
   * Listen the value change on postal code input
   */
  public onChangePostalCodeInput(): void {
    if (this.postalCodeInput && this.postalCodeInput === this.postalCodeStored) {
      return;
    }

    const postalCodeChange: string = String(this.postalCodeInput).replace(/[^0-9]/g, '');

    this.postalCodeInput = postalCodeChange;

    this.postalCodeInput.length > 6 ? this.selectedHouseType && this.onSearchAddresses() : this.clearUserEnteredData();
  }

  /**
   * Handle action click house type
   * @param {String} houseType The house type, must be Collective or Dashed
   */
  public clickSelectHouseType(houseType: string): void {
    if (!houseType) return;
    this.selectedHouseType = houseType;

    this.postalCodeInput.length >= 8 && this.onSearchAddresses();
  }

  /**
   * Handle re-init entered data before when click back page from courses list
   * @param {any} dataParsed Data stored form local storage
   */
  private async reInitDataStored(dataParsed): Promise<void> {
    this.postalCodeInput = dataParsed.postalCode;
    this.selectedHouseType = dataParsed.houseType;
    dataParsed.originalCompanyCode && (this.originalCompanyCode = dataParsed.originalCompanyCode);

    await this.onSearchAddresses();
    if (this.groupAddressesList?.length) {
      this.clickSelectGroupAddress(dataParsed.selectedGroupAddress);
      dataParsed.selectedAddress?.other_building_display_flag
        ? this.clickSelectAddressDetail(null, true)
        : this.clickSelectAddressDetail(dataParsed.selectedAddress);
      this.addressInputForm.patchValue({
        address: dataParsed.inputBuildingAddress.address,
        addressNumber: dataParsed.inputBuildingAddress.addressNumber,
      });
    }
    // Close dropdown select address and building
    this.onToggleDropdown(this.dropdownAddress, false);
    this.selectedHouseType === HOUSE_TYPE.Collective && this.onToggleDropdown(this.dropdownBuilding, true);
    this.selectedHouseType === HOUSE_TYPE.Detached && this.onToggleDropdown(this.dropdownDetachedAddress, true);
  }

  /**
   * Handle click search and call API to fetch data
   */
  public async onSearchAddresses(): Promise<void> {
    this.clearUserEnteredData();
    this.postalCodeStored = this.postalCodeInput;
    this.houseTypeStored = this.selectedHouseType;

    this.isLoading = true;
    await this.areaService
      .searchAddressByPostalCode(this.houseTypeStored, this.postalCodeStored)
      .toPromise()
      .then((data) => this.handleSearchAddressResponse(data))
      .catch((err) =>
        this.dialogService.open({
          type: DIALOG_MESSAGE_TYPE.Error,
          message: err.message || MESSAGES.Base.UpdateFailed,
        }),
      );

    if (this.houseTypeStored === HOUSE_TYPE.Collective && this.originalCompanyCode) {
      await this.getCompanyByOriginalCode();
    }

    this.isLoading = false;
  }

  /**
   * Handle search address response data
   * @param {ApiResponse} data API search response data
   */
  private handleSearchAddressResponse(data: ApiResponse): void {
    if (data.contents && data.result_code === RESULT_CODES.Success) {
      this.groupAddressesList = this.groupAddressesByChrome(
        data.contents[this.houseTypeStored === HOUSE_TYPE.Detached ? 'areas' : 'houses'],
      );

      this.groupAddressesList?.length && this.onToggleDropdown(this.dropdownAddress, true);

      return;
    }

    this.dialogService.open({ type: DIALOG_MESSAGE_TYPE.Warning, message: MESSAGES.Base.NoData });
  }

  /**
   * Group address: prefectures + municipalities + town_area + chome
   * @param {Array<Address>} addresses Addresses
   * @returns {Array<GroupAddress>} Group address
   */
  private groupAddressesByChrome(addresses: Array<Address> = []): Array<GroupAddress> {
    const groupedAddressesData = _.groupBy(addresses, (address: Address) => {
      return [address.prefectures + address.municipalities + address.town_area + address.chome];
    });

    const groupAddressesList: Array<GroupAddress> = [];
    Object.keys(groupedAddressesData).forEach((key: string) => {
      groupAddressesList.push({
        name: key,
        details: this.handleSortDetailAddress(groupedAddressesData[key]),
      });
    });
    return groupAddressesList;
  }

  /**
   * Sort address list by address and address_number
   * @param {Array<Address>} $groupAddressesList
   * @returns {Array<Address>} Group address sort
   */
  private handleSortDetailAddress(groupAddressesList: Address[]): Address[] {
    const sortArrayAddress: Address[] = _.sortBy(groupAddressesList, [
      (address_item) => {
        return _.toNumber(address_item.address);
      },
      (address_number_item) => {
        return _.toNumber(address_number_item.address_number);
      },
    ]);
    return sortArrayAddress;
  }
  /**
   * Check entered data is valid to click next step
   * @returns {Boolean} True if allow, else false
   */
  public get allowNextStep() {
    if (!this.houseTypeStored || !this.postalCodeStored) {
      return false;
    }

    if (this.houseTypeStored === HOUSE_TYPE.Collective) {
      return this.selectedAddress && this.selectedAddress.apartment_name;
    }

    return this.selectedGroupAddress;
  }
  /**
   * Click to next step
   */
  public async clickNextStep(): Promise<void> {
    if (!this.allowNextStep) {
      this.dialogService.open({ type: DIALOG_MESSAGE_TYPE.Warning, message: MESSAGES.Area.WarningInvalidAddress });
      return;
    }
    this.dataService.setStepDataStored(PROGRESS_STEPS.StepTwo.Index, {
      houseType: this.houseTypeStored,
      postalCode: this.postalCodeStored,
      selectedAddress: this.selectedAddress,
      selectedGroupAddress: this.selectedGroupAddress,
      inputBuildingAddress: this.addressInputForm.value,
      addressDetail: this.getHouseDetailAddress(),
      originalCompanyCode: this.originalCompanyCode,
    });
    // Remove all query params
    await this.helperService.redirectByOptions(ROUTES_PATH.Index, { queryParams: {} });

    this.helperService.redirectByOptions(ROUTES_PATH.CourseChoose, { skipLocationChange: true });
  }

  /**
   * Handle click to show dropdown list
   * @param {Event} $event
   * @param {BsDropdownDirective} clickedDropdown
   */
  public onShown($event, clickedDropdown: BsDropdownDirective): void {
    if (this.lastOpened && this.lastOpened !== clickedDropdown) {
      this.lastOpened.hide();
    }

    this.lastOpened = clickedDropdown;
    $event.stopPropagation();
  }

  /**
   * Handle select address on dropdown list
   * @param groupAddressSelected
   */
  public clickSelectGroupAddress(groupAddressSelected: GroupAddress): void {
    // Reset the building address
    this.selectedAddress = null;
    this.addressInputForm.patchValue({
      address: '',
      addressNumber: '',
    });
    this.setAddressFormAction({
      enable: this.selectedHouseType === HOUSE_TYPE.Collective,
      disable: this.selectedHouseType === HOUSE_TYPE.Detached,
    });

    this.selectedGroupAddress = groupAddressSelected;
    this.addressesListFilter = (groupAddressSelected.details || []).filter((address: Address) =>
      this.filterAddressCondition(address, this.addressInputForm.value),
    );
    // Show building dropdown if the house type is collective
    this.houseTypeStored === HOUSE_TYPE.Collective &&
      this.addressesListFilter?.length &&
      this.onToggleDropdown(this.dropdownBuilding, true);
    this.houseTypeStored === HOUSE_TYPE.Detached &&
      this.addressesListFilter?.length &&
      this.onToggleDropdown(this.dropdownDetachedAddress, true);
  }

  /**
   * Select building in building dropdown list
   * @param address
   * @param selectedOtherBuilding
   */
  public clickSelectAddressDetail(address: Address, selectedOtherBuilding = false): void {
    this.selectedAddress = address;
    if (selectedOtherBuilding) {
      this.selectedAddress = this.defineOtherBuildingAddress();
    }

    // Patch value to the input form
    this.addressInputForm.patchValue({
      address: this.selectedAddress?.address,
      addressNumber: this.selectedAddress?.address_number,
    });

    this.onToggleDropdown(this.dropdownBuilding, false);
    this.onToggleDropdown(this.dropdownDetachedAddress, false);
  }

  /**
   * Clear postal code on input
   */
  public clearPostalCodeInput(): void {
    this.postalCodeInput = '';
    this.inputPostalCode.nativeElement.focus();
    this.clearUserEnteredData();
  }

  /**
   * Define the building address to show on building dropdown list
   * @param {Address} address The address
   */
  public getBuildingAddress(address: Address): string {
    let buildingAddress: string = this.helperService.addressDisplayFormat({
      municipalities: address.municipalities,
      town_area: address.town_area,
      chome: address.chome,
    });

    address.address && (buildingAddress += address.address);
    address.address_number && (buildingAddress += '-' + address.address_number);
    return buildingAddress;
  }
  /**
   * Define the detached address to show on building dropdown list
   * @param groupAddress
   * @param addressDetachedDetail
   */
  public getDetachAddress(groupAddress: GroupAddress, addressDetachedDetail: Address): any {
    const addressDetail: Address = groupAddress?.details ? groupAddress?.details[0] : {};
    return {
      prefectures: addressDetail.prefectures,
      municipalities: addressDetail.municipalities,
      town_area: addressDetail.town_area,
      chome: addressDetail.chome,
      address: addressDetachedDetail.address,
      address_number: addressDetachedDetail.address_number,
    };
  }
  /**
   * Patch value to the address form
   * @param {String} address The address
   * @param {String} addressNumber The address number
   */
  private patchValueAddressForm(address: string, addressNumber: string): void {
    this.addressInputForm.patchValue({
      address,
      addressNumber,
    });
  }

  /**
   * Enable/disable form input
   */
  private setAddressFormAction(props: FormActionProps): void {
    if (props.enable) {
      this.addressInputForm.get('address').enable();
      this.addressInputForm.get('addressNumber').enable();
      return;
    }

    this.addressInputForm.get('address').disable();
    this.addressInputForm.get('addressNumber').disable();
  }

  /**
   * Clear user entered data
   */
  private clearUserEnteredData() {
    this.groupAddressesList = this.addressesListFilter = [];
    this.selectedGroupAddress = this.selectedAddress = null;
    this.postalCodeStored = '';
    this.houseTypeStored = '';
    // Reset address form value and disable input
    // -> Need to check the value to avoid trigger the filter function
    if (this.isValuableForm()) {
      this.patchValueAddressForm('', '');
    }
  }

  private isValuableForm() {
    const formFields = Object.keys(this.addressInputForm.value);
    return formFields.filter((fieldName) => this.addressInputForm.value[fieldName]).length;
  }

  /**
   * Used to trigger dropdown toggle
   * @param {BsDropdownDirective} dropdownDirective Dropdown directive
   * @param {Boolean} isShow Show/hide
   */
  private onToggleDropdown(dropdownDirective: BsDropdownDirective, isShow: boolean = true): void {
    if (!dropdownDirective) {
      return;
    }

    setTimeout(() => {
      isShow ? dropdownDirective.show() : dropdownDirective.hide();
    }, 200);
  }

  /**
   * Filter address by condition
   * @param {Address} address Address
   * @param formValues
   * @returns {Boolean} Result of filter by condition
   */
  private filterAddressCondition(address: Address, formValues): boolean {
    if (this.selectedHouseType === HOUSE_TYPE.Detached) {
      return true;
    }

    const enteredAddress: string = formValues.address;
    const enteredAddressNumber: string = formValues.addressNumber;

    const conditionToHiddenOnList =
      address.apartment_name === '' ||
      (enteredAddress && address.address !== enteredAddress) ||
      (enteredAddressNumber && address.address_number !== enteredAddressNumber);

    return !conditionToHiddenOnList;
  }

  /**
   * (Call on html) Get address info to show on select building dropdown
   * @returns {Address} Address info
   */
  public addressInfoShowOnInput(): Address {
    const address: Address = this.selectedGroupAddress?.details ? this.selectedGroupAddress.details[0] : {};
    return {
      municipalities: address.municipalities,
      town_area: address.town_area,
      chome: address.chome,
    };
  }

  /**
   * (Call on html) Get group address info to show on UI
   * @param {GroupAddress} groupAddress Group address
   * @returns {Address} Address Infos
   */
  public groupAddressDisplayInfo(groupAddress: GroupAddress): Address {
    const addressDetail: Address = groupAddress?.details ? groupAddress?.details[0] : {};

    return {
      prefectures: addressDetail.prefectures,
      municipalities: addressDetail.municipalities,
      town_area: addressDetail.town_area,
      chome: addressDetail.chome,
    };
  }
  /**
   * Get house address detail to go to next step
   * @returns {Address} Address detail
   */
  private getHouseDetailAddress(): Address {
    const getDetachedAddress = (): Address => {
      const addressSelected: Address = _.cloneDeep(this.selectedGroupAddress.details[0]);
      return {
        prefectures: addressSelected.prefectures,
        municipalities: addressSelected.municipalities,
        town_area: addressSelected.town_area,
        chome: addressSelected.chome,
      };
    };

    const addressDetail: Address =
      this.houseTypeStored === HOUSE_TYPE.Collective ? _.cloneDeep(this.selectedAddress) : getDetachedAddress();

    const inputAddressValues = {
      address: this.addressInputForm.value.address,
      address_number: this.addressInputForm.value.addressNumber,
    };
    if (!inputAddressValues.address) {
      delete inputAddressValues.address;
    }

    return _.merge(addressDetail, inputAddressValues);
  }

  private defineOtherBuildingAddress(): Address {
    return {
      postal_code: this.selectedGroupAddress.details[0].postal_code,
      town_area: this.selectedGroupAddress.details[0].town_area,
      prefectures: this.selectedGroupAddress.details[0].prefectures,
      municipalities: this.selectedGroupAddress.details[0].municipalities,
      chome: this.selectedGroupAddress.details[0].chome,
      address: '',
      address_number: '',
      apartment_name: this.otherBuildingName,
      facility_flg: '',
      other_building_display_flag: BOOLEAN_MAPPING_VALUE.True,
    };
  }

  /**
   * Get company by original code
   * @returns {Promise}
   */
  private getCompanyByOriginalCode(): Promise<void> {
    return this.companyService
      .getCompanyInfo(this.originalCompanyCode)
      .toPromise()
      .then((data: ApiResponse) => {
        if (data.result_code === RESULT_CODES.Success && data.contents?.company) {
          this.otherBuildingDisplayFlag = data.contents.company.other_building_display_flag;
        }
      })
      .catch(() => null);
  }

  private checkCookieErrorMsg() {
    const cookieArr = convertCookieStringToArray(document.cookie);
    const errorCookieNames = [DECRYPT_DATA_FAIL_COOKIE_NAME, NOT_FOUND_MEMBER_ID_COOKIE_NAME];

    cookieArr.forEach((obj) => {
      if (errorCookieNames.includes(obj.key) && !_.isEmpty(obj.value)) {
        this.dialogService.open({ type: DIALOG_MESSAGE_TYPE.Error, message: obj.value });
        document.cookie = `${obj.key}=; Path=/;`;
      }
    });
  }
}
