import { HelperService } from '@core/services/helper.service';
import { CompanyService } from '@core/services/home/company.service';
import { DataService } from '@core/services/data.service';

import {
  CALENDAR,
  DEFAULT_FOOTER_ELEMENT,
  DEFAULT_HEADER_ELEMENT,
  RESULT_CODES,
  ROUTES_PATH,
  TIME_ZONE,
} from '@shared/common/constants';
import { Component, Inject, OnInit, Renderer2, TemplateRef } from '@angular/core';
import * as moment from 'moment';
import { faAngleLeft, faAngleRight, faTrashAlt, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ReserveService } from '@core/services/home/reserve.service';
import { ApiResponse } from '@shared/common/types';
import { finalize } from 'rxjs/operators';
import { CalendarItem, Constructions, ConstructionsBody, DesiredConstructions } from '@core/models/reserve.modal';
import { DOCUMENT } from '@angular/common';
import { ApplicationsService } from '@core/services/home/applications.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-setting-construction',
  templateUrl: './setting-construction.component.html',
  styleUrls: ['./setting-construction.component.scss'],
})
export class SettingConstructionComponent implements OnInit {
  monthChange: number = Number(moment().format('M'));
  currentMonth: number = Number(moment().format('M'));
  yearChange: number = Number(moment().format('YYYY'));
  currentYear: number = Number(moment().format('YYYY'));
  arrCalendar: Array<any> = [];
  calendarHeader: Array<string> = ['月', '火', '水', '木', '金', '土', '日'];
  faAngleLeft = faAngleLeft;
  faAngleRight = faAngleRight;
  faTrashAlt = faTrashAlt;
  faChevronDown = faChevronDown;
  isDone = false;
  disabledSubmit = true;
  private lastOpened: BsDropdownDirective = null;
  modalRef?: BsModalRef;
  dataModal: any = {};
  housingCategory: number = null;
  applicationId: number = null;
  companyCode: string = '';
  requestId: string = '';
  facilityFlg: string = '';
  isLoading = false;
  constructions: Constructions[] = [];
  arrDateChoose: any = [];
  timeZone: any = TIME_ZONE;
  calendar: any = CALENDAR;
  monthPrev: number = null;
  monthNext: number = null;
  disabledNextMonth = false;
  isHiddenCalendar = false;
  attentionMessage: string = '';
  buttonName: string = '';
  homeUrl: string = '';

  constructor(
    private readonly dataService: DataService,
    public renderer: Renderer2,
    private modalService: BsModalService,
    private reserveService: ReserveService,
    private readonly helperService: HelperService,
    private applicationService: ApplicationsService,
    private readonly companyService: CompanyService,
    @Inject(DOCUMENT) private document: Document,
    private route: ActivatedRoute,
  ) {}

  async ngOnInit(): Promise<void> {
    this.isLoading = true;
    this.document.body.classList.add('construction-body');
    this.calcPrevNext();
    this.getRequestId();
    await this.updateApplicationCompletion();
    await this.getDesireDays();
    await this.getConstructions();
    this.isLoading = false;
  }

  getRequestId(): void {
    this.requestId = this.route.snapshot.queryParamMap.get('request_id');
    !this.requestId && this.helperService.redirect(ROUTES_PATH.Error);
  }

  async updateApplicationCompletion(): Promise<void> {
    await this.applicationService
      .updateApplicationCompletion({
        request_id: this.requestId,
      })
      .toPromise()
      .then((data: ApiResponse) => {
        if (data.result_code === RESULT_CODES.NoData) {
          this.helperService.redirect(ROUTES_PATH.Error);
        }
        if (data.result_code === RESULT_CODES.Success && data.body) {
          const { application_id, housing_category, company_code, request_id, facility_flg } = data.body.application;
          [application_id, housing_category, company_code, request_id].some((el) => !el) &&
            this.helperService.redirect(ROUTES_PATH.Error);
          this.applicationId = application_id;
          this.housingCategory = housing_category;
          this.companyCode = company_code;
          this.requestId = request_id;
          this.facilityFlg = facility_flg;
        }
      })
      .catch(() => {
        this.helperService.redirect(ROUTES_PATH.Error);
      });
  }

  async getDesireDays(): Promise<void> {
    await this.reserveService
      .getDesireDays(this.applicationId, this.companyCode)
      .toPromise()
      .then((data: ApiResponse) => {
        data.result_code === RESULT_CODES.NoData && this.helperService.redirect(ROUTES_PATH.Error);
        if (data.contents && data.result_code === RESULT_CODES.Success) {
          data.contents.desired_construction?.desired_days?.length > 0 && (this.isHiddenCalendar = true);
        }
      })
      .catch(() => {
        this.helperService.redirect(ROUTES_PATH.Error);
        88;
      });
  }

  async getConstructions(): Promise<void> {
    this.yearChange === this.currentYear
      ? (this.disabledNextMonth = this.monthChange === this.currentMonth + 1)
      : (this.disabledNextMonth = this.yearChange === this.currentYear + 1);
    await this.reserveService
      .getConstructions(this.companyCode, this.yearChange, this.monthChange, this.housingCategory, this.facilityFlg)
      .toPromise()
      .then((data: ApiResponse) => {
        const companyInfo = data.contents?.company || {};
        const header = companyInfo.header_footer_inquiry_contents || DEFAULT_HEADER_ELEMENT;
        const footer = companyInfo.header_footer_inquiry_contents || DEFAULT_FOOTER_ELEMENT;
        this.dataService.setHeaderFooterContent(decodeURI(header), decodeURI(footer));
        this.attentionMessage = companyInfo.attention_message || '';
        data.result_code === RESULT_CODES.NoData && (this.constructions = []);
        if (data.contents && data.result_code === RESULT_CODES.Success) {
          this.constructions = data.contents.constructions || [];
        }
        this.renderCalendar();
      })
      .catch(() => {
        this.helperService.redirect(ROUTES_PATH.Error);
      });
  }

  renderCalendar(): void {
    const { MaxLength, MinLength, WeekLength } = this.calendar;
    this.arrCalendar = [];
    for (let i = 1; i <= MaxLength; i++) {
      this.arrCalendar.push(i);
    }
    const dayInMonth = moment(`${this.yearChange}-${this.monthChange}`, 'YYYY-MM').daysInMonth();
    const month = this.monthChange.toString().padStart(2, '0');
    let startDate = moment(`${this.yearChange}-${month}-01`).day();
    startDate === 0 && (startDate = WeekLength);
    for (let i = 0; i < this.arrCalendar.length; i++) {
      const day = (this.arrCalendar[i] - (startDate - 1)).toString().padStart(2, '0');
      const date = `${this.yearChange}/${month}/${day}`;
      i < startDate - 1
        ? (this.arrCalendar[i] = '')
        : (this.arrCalendar[i] = {
            day,
            date,
            choose: this.checkIncludes(this.arrDateChoose, date),
            frame: this.constructions[i - (startDate - 1)]?.frame || [],
            allFrameCountZero: this.constructions[i - (startDate - 1)]?.frame.every((el) => el.frame_count === 0),
          });
      this.arrCalendar[i]['day'] > dayInMonth && (this.arrCalendar[i] = '');
    }
    let isNull = 0;
    for (let i = this.arrCalendar.length; i >= MinLength; i--) {
      this.arrCalendar[i] === '' && isNull++;
    }
    isNull >= WeekLength && this.arrCalendar.splice(this.arrCalendar.length - WeekLength, WeekLength);
  }

  showModal(modalCalendar: TemplateRef<any>, item: CalendarItem): void {
    this.dataModal = item;
    this.modalRef = this.modalService.show(modalCalendar);
  }

  chooseTimeZone({ date, frame }: Constructions, { target }: MouseEvent, timeZone: string, timeZoneText: string): void {
    this.renderer.addClass(target, 'bg-primary');
    this.renderer.addClass(target, 'text-white');
    this.arrDateChoose.push({ timeZone: Number(timeZone), timeZoneText, date, frame });
    this.arrDateChoose.sort((a, b) => {
      return a.date.localeCompare(b.date);
    });
    this.disabledSubmit = false;
    this.renderCalendar();
    this.closeModal();
  }

  async prevMonth(): Promise<void> {
    const { MinMonth, MaxMonth } = this.calendar;
    this.monthChange === MinMonth ? ((this.monthChange = MaxMonth), this.yearChange--) : this.monthChange--;
    this.calcPrevNext();
    this.isLoading = true;
    await this.getConstructions();
    this.isLoading = false;
  }

  async nextMonth(): Promise<void> {
    const { MinMonth, MaxMonth } = this.calendar;
    this.monthChange === MaxMonth ? ((this.monthChange = MinMonth), this.yearChange++) : this.monthChange++;
    this.calcPrevNext();
    this.isLoading = true;
    await this.getConstructions();
    this.isLoading = false;
  }

  calcPrevNext(): void {
    const { MinMonth, MaxMonth } = this.calendar;
    this.monthPrev = this.monthChange === MinMonth ? MaxMonth : this.monthChange - 1;
    this.monthNext = this.monthChange === MaxMonth ? MinMonth : this.monthChange + 1;
  }

  removeDate(value: string): void {
    this.arrDateChoose = this.arrDateChoose.filter(({ date }) => date !== value);
    this.disabledSubmit = this.arrDateChoose.length === 0;
    this.renderCalendar();
  }

  changeTimeZone(id: string, timeZone: string, timeZoneText: string): void {
    const ele = document.getElementById(id);
    ele.innerText = timeZoneText;
    ele.setAttribute('data-date', `${id.split('-').join('/')}-${timeZone}`);
  }

  done(): void {
    this.isLoading = true;
    const desired_constructions: DesiredConstructions[] = [];
    const selectedDateList = document.getElementsByClassName('selected-date') as HTMLCollectionOf<HTMLInputElement>;
    for (let i = 0; i < selectedDateList.length; i++) {
      desired_constructions.push({
        date: this.formatDate(selectedDateList[i].attributes.getNamedItem('data-date').nodeValue.split('-')[0]),
        time_zone: Number(selectedDateList[i].attributes.getNamedItem('data-date').nodeValue.split('-')[1]),
      });
    }
    const options: ConstructionsBody = {
      application_id: this.applicationId,
      company_code: this.companyCode,
      desired_constructions,
    };
    this.reserveService
      .updateConstructions(options)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (data: ApiResponse) => {
          if (data.result_code === RESULT_CODES.NoData) {
            this.helperService.redirect(ROUTES_PATH.Error);
          }
          if (data.result_code === RESULT_CODES.Success) {
            this.isDone = true;
            this.buttonName = data.body.company.button_name;
            this.homeUrl = data.body.company.home_url;
          }
        },
        () => {
          this.helperService.redirect(ROUTES_PATH.Error);
        },
      );
  }

  /**
   * Handle click to show dropdown list
   * @param {Event} $event
   * @param {BsDropdownDirective} clickedDropdown
   */
  onShown($event: MouseEvent, clickedDropdown: BsDropdownDirective): void {
    if (this.lastOpened && this.lastOpened !== clickedDropdown) {
      this.lastOpened.hide();
    }

    this.lastOpened = clickedDropdown;
    $event.stopPropagation();
  }

  closeModal(modalId?: number): void {
    this.modalService.hide(modalId);
  }

  formatDate(date: string): string {
    return moment(date, 'YYYY/MM/DD').format('YYYY-MM-DD');
  }

  checkIncludes(obj: any[], value: string): boolean {
    return obj.some((el) => el.date.includes(value));
  }

  checkTimeZone(frame: any[], timeZone: string): boolean {
    return frame.some((el) => Number(el.time_zone) === Number(timeZone) && Number(el.frame_count) === 0);
  }

  goToHomeUrl(): void {
    window.location.href = this.homeUrl;
  }
}
