<app-loading *ngIf="isLoading"></app-loading>
<div class="container-fluid shadow reserve-page">
  <section class="card">
    <div class="card-header c-ff-h6 card-header-title">
      お申し込み完了
    </div>
    <div style="padding: 1.25rem;" class="card-body section card-custom ">
      <h6 class="heading c-ff-h6  heading_title">お申し込みが完了しました</h6>
      <div class="alert alert-common p-4 text-center rounded alert-check">
        <p class="c-fw-6 c-fs-19 mb-3 c-ff-h6 notice_title">申請ID</p>
        <p style="color: #006180;margin: 0;" class="c-fw-6 c-fs-30 request-id mb-3 c-ff-h6">{{ requestId }}</p>
        <p style="font-weight: 600;" *ngIf="isPending" class="c-fs-16 mb-0 c-ff-h3 ">{{text1_pending}}</p>
        <p style="font-weight: 600;" *ngIf="isPending" class="c-fs-16 mb-0 c-ff-h3 ">{{text2_pending}}</p>
        <p style="margin: 0;" id="responseText" class="c-fs-16 mb-0 c-ff-h3 ">{{texthead}}</p>
        <p id="responseText" class="c-fs-16 mb-0 c-ff-h3 ">{{textmiddle}}</p>
        <p style="margin: 0;" id="responseText" class="c-fs-16 mb-0 c-ff-h3 ">{{textAfter}}</p>
      </div>
      <div *ngIf="buttonName && homeUrl" class="text-center d-flex justify-content-center py-4">
        <button class="btn btn-white btn-home" (click)="goToHomeUrl();">
          {{buttonName}}
        </button>
      </div>
      <div *ngIf="buttonRedirect" class="text-center d-flex justify-content-center py-4">
        <button style="border-radius: 25px;font-weight: 600;font-size: 16px;width: 170px;height: 50px" class="btn btn-white btn-home" (click)="goToHomeUrl();">
          ホームに戻る 
        </button>
      </div>
    </div>
  </section>
</div>
