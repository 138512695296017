<form
  ngNoForm
  name="CandyWPaymentForm"
  action="{{payment_url}}"
  method="post"
  accept-charset="shift_jis"
  #candyWCardForm
  >
  <input type="hidden" name="trans_no" #trans_no />
  <input type="hidden" name="trans_date" #trans_date />
  <input type="hidden" name="term_id" #term_id />
  <input type="hidden" name="yds_id" #yds_id />
  <input type="hidden" name="div_trade" #div_trade />
  <input type="hidden" name="amount" #amount />
  <input type="hidden" name="last_name" #last_name />
  <input type="hidden" name="result_url" #result_url />
  <input type="hidden" name="biz_info" #biz_info />
  <input type="hidden" name="hash" #hash />
</form>
