import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { RequestService } from '../request.service';
import { ApiResponse } from '@shared/common/types';
import { API_ROUTES } from '@shared/common/constants';
@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor(private requestService: RequestService) {}

  public postDataOfPaymentStatus(objectData: any): Observable<ApiResponse> {
    const url = `${API_ROUTES.Home.PaymentStatus}`;
    return this.requestService.post(url, objectData);
  }
  public postDeleteApplications(objectData: any): Observable<ApiResponse> {
    const url = `${API_ROUTES.Home.ApplicationDelete}`;
    return this.requestService.post(url, objectData);
  }
}
