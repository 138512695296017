<app-loading *ngIf="isLoading"></app-loading>
<div class="container-fluid shadow">
  <section>
    <div class="card">
      <div class="card-header">
        <span>お申し込み情報の確認</span>
      </div>
      <div class="card-body section py-4">
        <h6 class="heading my-4">
          お申し込み者情報を確認してください
        </h6>
        <div class="form-group d-flex flex-column info-confirm c-mb-4 span-title">
          <span class="mb-3 info-title" [innerHTML]="courseInfo.name | nl2br"></span>
          <div class="ml-4 mb-2">
            <span class="text-shadow c-fw-6">コース内容</span>
            <p class="text-shadow mt-1 c-ff-h3" [innerHTML]="courseInfo.contents | nl2br"></p>
          </div>
          <div class="ml-4 mb-2">
            <span class="text-shadow c-fw-6" [innerHTML]="courseInfo.section_1_title | nl2br"></span>
            <p class="text-shadow c-ff-h3" [innerHTML]="courseInfo.section_1 | nl2br"></p>
          </div>
          <div class="ml-4 mb-2">
            <span class="text-shadow c-fw-6" [innerHTML]="courseInfo.section_2_title | nl2br"></span>
            <p class="text-shadow c-ff-h3" [innerHTML]="courseInfo.section_2 | nl2br"></p>
          </div>
          <div class="ml-4 mb-2">
            <span class="text-shadow c-fw-6" [innerHTML]="courseInfo.section_3_title | nl2br"></span>
            <p class="text-shadow c-ff-h3" [innerHTML]="courseInfo.section_3 | nl2br"></p>
          </div>
          <div *ngIf="listOptionChoice.length" class="ml-4">
              <span class="text-shadow c-fw-6">オプションサービス</span>
              <div class="row mt-1 c-fw-3" *ngFor="let option of listOptionChoice">
                <div class="col-6"><p class="text-shadow c-ff-h3" >{{option.title}}:</p></div>
                <div class="col-6" [innerHtml]="option.contents"></div>
              </div>
          </div>
        </div>
        <hr class="c-mt-4 c-mb-3">
        <div class="info-confirm-details ml-1">
          <div class="form-group d-flex flex-column c-mb-3">
            <span class="info-title">氏名</span>
            <p class="text-shadow mt-1 c-ff-h3">{{confirmDetailData.last_name}} {{confirmDetailData.first_name}}</p>
          </div>
          <div class="form-group d-flex flex-column c-mb-3">
            <span class="info-title">氏名（フリガナ）</span>
            <p class="text-shadow mt-1 c-ff-h3">{{confirmDetailData.last_name_furigana}} {{confirmDetailData.first_name_furigana}}</p>
          </div>
          <div class="form-group d-flex flex-column c-mb-3">
            <span class="info-title">ケーブルテレビ設置住所</span>
            <p class="text-shadow mt-2 c-ff-h3">〒{{confirmDetailData.establish_postal_code}}</p>
            <p class="text-shadow c-ff-h3">{{confirmDetailData.establishAddress}}</p>
          </div>
          <div class="form-group d-flex flex-column c-mb-3">
            <span class="info-title">ご契約者住所</span>
            <p class="text-shadow mt-2 c-ff-h3">〒{{confirmDetailData.contract_postal_code}}</p>
            <p class="text-shadow c-ff-h3">{{confirmDetailData.contractAddress}}</p>
          </div>
          <div class="form-group d-flex flex-column c-mb-3" *ngIf="confirmDetailData.birthday">
            <span class="info-title">生年月日</span>
            <p class="text-shadow mt-1 c-ff-h3">{{confirmDetailData.birthday}}</p>
          </div>
          <div class="form-group d-flex flex-column c-mb-3">
            <span class="info-title">性別</span>
            <p class="text-shadow mt-2 c-ff-h3">{{constGenderString[confirmDetailData.gender]}}</p>
          </div>
          <div class="form-group d-flex flex-column c-mb-3">
            <span class="info-title">電話番号</span>
            <p class="text-shadow mt-1 c-ff-h3">{{confirmDetailData.telephone_number}}</p>
          </div>
          <div class="form-group d-flex flex-column c-mb-3" *ngIf="confirmDetailData.mobile_telephone_number">
            <span class="info-title">携帯電話番号</span>
            <p class="text-shadow mt-2 c-ff-h3">{{confirmDetailData.mobile_telephone_number}}</p>
          </div>
          <div class="form-group d-flex flex-column c-mb-3">
            <span class="info-title">メールアドレス</span>
            <p class="text-shadow mt-1 c-ff-h3">{{confirmDetailData.email_address}}</p>
          </div>
          <div *ngIf="shouldHiddenPayment" class="form-group d-flex flex-column">
            <span class="info-title c-fw-6 mt-1">支払方法</span>
            <p *ngIf="paymentMethodOpt" class="text-shadow mt-1">{{paymentMethodOpt}}</p>
            <p *ngIf="cardNumber" class="text-shadow mt-1">カード番号：<span class="c-ff-h3">{{cardNumber}}</span></p>
            <div
              *ngIf="
                paymentMethodValue &&
                (selectedPaymentMethodExplanatory.explanatory !== '' || selectedPaymentMethodExplanatory.explanatory_url !== '')
              "
              class="payment-method-explanatory border-0 mt-0">
              <p class="explanatory-text m-0">{{ selectedPaymentMethodExplanatory.explanatory }}</p>
              <a
                class="explanatory-url"
                href="{{ selectedPaymentMethodExplanatory.explanatory_url }}"
                target="_blank"
              >
                {{ selectedPaymentMethodExplanatory.explanatory_url }}
              </a>
            </div>
          </div>
          <div class="form-group d-flex flex-column c-mb-3" *ngIf="remark_title !== '' && confirmDetailData.remarks !== ''">
            <p class="info-title c-fs-16">{{remark_title}}</p>
            <p class="text-shadow mt-1 c-ff-h3">{{confirmDetailData.remarks}}</p>
          </div>
        </div>
        <div class="footer row align-items-center">
          <div class="col-md-4 col-sm-12 d-flex mt-2">
            <button class="btn btn-white btn-cw-204 btn-ch-44" (click)="backPreviousStep();">
              <span class="btn-text c-ff-h3"><fa-icon [icon]="faChevronLeft" size="sm"></fa-icon> 入力情報を変更する</span>
            </button>
          </div>
          <div class="col-md-4 col-sm-12 justify-content-sm-center d-flex my-sm-4">
            <button class="btn btn-lime btn-cw-260 btn-ch-70" (click)="nextStep();">
              <span class="btn-text"><fa-icon [icon]="faChevronRight"></fa-icon> お申し込みの確定</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    </section>
</div>
