import { ApiResponse } from '@shared/common/types';
import { Address } from '@core/models/area.model';
import { HelperService } from '@core/services/helper.service';
import { CourseService } from '@core/services/home/course.service';
import { CompanyService } from '@core/services/home/company.service';
import { DataService } from '@core/services/data.service';
import { DialogServiceService } from '@core/services/dialog-service.service';
import { Component, OnInit } from '@angular/core';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import {
  PROGRESS_STEPS,
  HOUSE_TYPE,
  ROUTES_PATH,
  RESULT_CODES,
  DIALOG_MESSAGE_TYPE,
  DEFAULT_HEADER_CONTENT,
  BOOLEAN_MAPPING_VALUE,
  DEFAULT_HEADER_ELEMENT,
  PAYMENT_TYPES,
} from '@shared/common/constants';
import { Course, SearchCoursesParams } from '@core/models/course.model';
import { Company } from '@core/models/company.model';
import * as _ from 'lodash';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-course-choose',
  templateUrl: './course-choose.component.html',
  styleUrls: ['./course-choose.component.scss'],
})
export class CourseChooseComponent implements OnInit {
  faChevronLeft: any = faChevronLeft;
  faCheckCircle: any = faCheckCircle;
  faChevronRight: any = faChevronRight;
  faAngleRight: any = faAngleRight;
  selectedCourse: Course;
  noCourse: boolean = true;
  realNoCourse: boolean = true;
  displayCourse: boolean = false;
  noCourseError: boolean = false;
  listCourse: Array<Course> = [];
  listCourseSearch: Array<Course> = [];
  companyInfo: Company;
  public routes = ROUTES_PATH;
  isLoading: boolean = false;
  addressStored: any;
  campaignCodeInput: string = '';
  isDifferentCompany: boolean = false;
  blinkState: boolean = false;
  courseSelectedByCourseIndex: { courseCodeId: string; hasCheckedCourse: boolean }[] = [];

  constructor(
    private readonly dataService: DataService,
    private readonly helperService: HelperService,
    private readonly companyService: CompanyService,
    private readonly courseService: CourseService,
    private readonly dialogService: DialogServiceService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.isLoading = true;
    // Get selected address from previous page
    this.addressStored = this.dataService.getDataStored(PROGRESS_STEPS.StepOne.Index);
    // Check whether user selected address from area-search page
    if (!this.addressStored) {
      this.helperService.redirect(ROUTES_PATH.Index);
      return;
    }

    // Re-init when back from next page
    const dataParsed = this.dataService.getDataStored(PROGRESS_STEPS.StepTwo.Index);
    dataParsed && (await this.reInitDataStored(dataParsed));

    // Fetch courses list via API
    await this.getCourseByAddress();
    this.noCourse = this.listCourse.length === 0;
    this.displayCourse = !this.noCourse;

    if (this.companyInfo && this.addressStored.originalCompanyCode) {
      this.isDifferentCompany = this.companyInfo.code !== this.addressStored.originalCompanyCode;
    }

    this.setHeaderFooterContent();
    this.isLoading = false;
    this.handleEffectBtn();
  }

  onCheckBoxChange(e, courseIndex: number, optionIndex: number, courseCodeId: string): void {
    const courseByIndex = this.listCourse[courseIndex];
    const optionByIndex = courseByIndex.options.length && courseByIndex.options[optionIndex];
    this.listCourse[courseIndex].options[optionIndex].checked = optionByIndex && e.target.checked;

    const hasCheckedCourse = courseByIndex.options.some((option) => option.checked);
    const hasCourseIndex = this.courseSelectedByCourseIndex.findIndex((item) => item.courseCodeId === courseCodeId);

    if (hasCourseIndex !== -1) {
      this.courseSelectedByCourseIndex.splice(hasCourseIndex, 1, { courseCodeId, hasCheckedCourse });
    } else {
      this.courseSelectedByCourseIndex.push({ courseCodeId, hasCheckedCourse });
    }
  }

  /**
   * set header footer content via header_footer_inquiry_contents field of get company
   */
  private setHeaderFooterContent(): void {
    if (!this.companyInfo || !this.companyInfo.header_footer_inquiry_contents) {
      return;
    }

    let headerFooterContents: string = decodeURI(this.companyInfo.header_footer_inquiry_contents);
    // Convert text to html content
    if (!this.helperService.isHTML(headerFooterContents)) {
      headerFooterContents = DEFAULT_HEADER_ELEMENT.replace(
        DEFAULT_HEADER_CONTENT,
        this.textToHtmlElement(headerFooterContents),
      );
    }

    this.dataService.setHeaderFooterContent(headerFooterContents, headerFooterContents);
  }

  /**
   * Get course by address that choose from previous page
   */
  private async getCourseByAddress(): Promise<void> {
    try {
      const addressDetail = this.getAddressDetailByHouseType();
      const searchResult = await this.courseService
        .getCourseByAddress(
          this.addressStored.houseType,
          this.defineSearchCoursesParams(addressDetail, this.campaignCodeInput),
        )
        .toPromise();
      searchResult.result_code === RESULT_CODES.Success && (await this.handleGetCoursesResponse(searchResult));
    } catch (error: any) {
      this.dialogService.open({ type: DIALOG_MESSAGE_TYPE.Error, message: error.message });
    }
  }

  private async handleGetCoursesResponse(searchResult: ApiResponse): Promise<void> {
    this.realNoCourse = !!searchResult.contents.house.noCourse;

    if (this.listCourse.length !== 0) {
      this.listCourse = this.listCourse.filter((value) => !value?.campaign_code?.length);
    }

    this.companyInfo = await this.getCompanyByCode(searchResult.contents.house.company_code);
    const isCandyWPaymentErrorGetSettlementAgency =
      this.companyInfo.credit_code === PAYMENT_TYPES.CANDY_W && !this.companyInfo.settlementAgency;

    if (!this.companyInfo || !this.companyInfo.code || isCandyWPaymentErrorGetSettlementAgency) {
      this.helperService.redirect(ROUTES_PATH.Error);
      return;
    }

    this.listCourse = await this.getCoursesList(searchResult);

    const listCourses = this.listCourse;
    this.listCourse = await this.mapOption(listCourses);
    this.listCourse.length === 1 && (await this.clickSelectCourse(this.listCourse[0]));
    const inquiryContents: string = decodeURI(this.companyInfo.inquiry_contents || '');
    inquiryContents && (this.companyInfo.inquiry_contents = this.formatInquiryContents(inquiryContents));
  }

  private async mapOption(listCourses: Array<Course>): Promise<Array<Course>> {
    return listCourses.map((course) => {
      course.options.map((option) => {
        const deCodeContents = decodeURI(option.contents);
        option.contents = this.helperService.convertText2Html(deCodeContents);
        return option;
      });
      return course;
    });
  }
  private async getCoursesList(searchResult: ApiResponse): Promise<Array<Course>> {
    const existingCoursesList: Array<Course> = searchResult.contents?.house?.course || [];
    const originalCompanyCode: string = this.addressStored.originalCompanyCode;
    if (!originalCompanyCode || !this.addressStored.selectedAddress?.other_building_display_flag) {
      return existingCoursesList;
    }

    return this.companyInfo.application_continuation_flag ? existingCoursesList : [];
  }

  /**
   * Define params for api search courses
   * @param {} addressDetail Address detail
   * @returns {SearchCoursesParams}
   */
  private defineSearchCoursesParams(addressDetail, campaign_code = ''): SearchCoursesParams {
    const searchCoursesParams: SearchCoursesParams = {
      postal_code: addressDetail.postal_code,
      campaign_code: encodeURI(campaign_code),
      chome: addressDetail.chome || '',
      apartment_name: addressDetail.apartment_name,
      town_area: addressDetail.town_area,
      address: addressDetail.address,
    };

    if (this.addressStored.houseType === HOUSE_TYPE.Collective) {
      searchCoursesParams.other_building_flag = this.addressStored.selectedAddress?.other_building_display_flag
        ? BOOLEAN_MAPPING_VALUE.True
        : BOOLEAN_MAPPING_VALUE.False;
    }

    return searchCoursesParams;
  }

  /**
   * Show address that selected on previous page
   */
  public getAddress(): string {
    return this.addressStored.postalCode + this.helperService.addressDisplayFormat(this.getAddressDetailByHouseType());
  }

  private async removeChecked(): Promise<Array<Course>> {
    return this.listCourse.map((course) => {
      if (course.course_code_id !== this.selectedCourse.course_code_id) {
        course.options.map((option) => {
          option.checked = false;
          return option;
        });
      }
      return course;
    });
  }

  private removeBlinkEffect(courseId: string) {
    this.courseSelectedByCourseIndex = this.courseSelectedByCourseIndex.filter((item) => {
      return item.courseCodeId === courseId;
    });
  }

  /**
   * Handle search address response data
   * @param course - selected from a list
   */
  public async clickSelectCourse(course: Course): Promise<void> {
    this.selectedCourse = course;
    this.noCourse = false;
    this.handleEffectBtn();
    this.removeBlinkEffect(course.course_code_id);
    await this.removeChecked();
  }

  /**
   * Get company by code after get data from api get courses
   * @param {string} companyCode The company code
   */
  private getCompanyByCode(companyCode: string): Promise<Company> {
    return this.companyService
      .getCompanyInfo(companyCode)
      .toPromise()
      .then((result) => {
        if (result.result_code === RESULT_CODES.Success && result.contents.company) {
          return result.contents.company;
        }

        return null;
      })
      .catch(() => null);
  }

  /**
   * Click to next step
   */
  public nextStep(): void {
    this.selectedCourse = this.listCourse.find(
      (course) => course.course_code_id === this.selectedCourse.course_code_id,
    );
    this.selectedCourse.company_code = this.companyInfo.code;
    this.selectedCourse.payment_url = this.companyInfo.payment_url;
    this.dataService.setStepDataStored(PROGRESS_STEPS.StepThree.Index, {
      selectedCourse: this.selectedCourse,
      company: this.companyInfo,
    });
    this.helperService.redirectByOptions(ROUTES_PATH.HomeTermsOfService, { skipLocationChange: true });
  }

  /**
   * Click to go back to area-search page
   */
  public backPreviousStep(): void {
    this.dataService.setStepDataStored(PROGRESS_STEPS.StepOne.Index);
    this.helperService.redirect(ROUTES_PATH.Index);
  }

  /**
   * Handle re-init entered data before when click back page from courses list
   * @param {any} dataParsed Data stored form local storage
   */
  private async reInitDataStored(dataParsed): Promise<void> {
    this.selectedCourse = dataParsed.selectedCourse;
    this.campaignCodeInput = this.selectedCourse.campaign_code ?? '';
    await this.reInitOptionChecked();
  }

  private async reInitOptionChecked(): Promise<Array<Course>> {
    const selectedOptionIds = (this.selectedCourse.options || [])
      .filter((option) => option.checked)
      .map((option) => option.id);
    return this.listCourse.map((course) => {
      if (course.course_code_id === this.selectedCourse.course_code_id) {
        course.options.map((option) => {
          option.checked = selectedOptionIds.includes(option.id);
          return option;
        });
      }
      return course;
    });
  }
  /**
   * Get address detail
   * @returns {Address} Return the address
   */
  private getAddressDetailByHouseType(): Address {
    let addressDetail =
      this.addressStored.houseType === HOUSE_TYPE.Collective
        ? this.addressStored.selectedAddress || {}
        : this.addressStored.selectedGroupAddress.details[0];

    addressDetail = _.merge(addressDetail, {
      address: this.addressStored.inputBuildingAddress.address,
      address_number: this.addressStored.inputBuildingAddress.addressNumber,
    });
    return addressDetail;
  }

  /**
   * Format inquiry contents (if not HTML -> convert text to html)
   * @param {String} inquiryContents
   * @returns {String}
   */
  private formatInquiryContents(inquiryContents: string): string {
    if (this.helperService.isHTML(inquiryContents)) {
      return inquiryContents;
    }

    return this.helperService.convertText2Html(inquiryContents);
  }

  private textToHtmlElement(text: string = ''): string {
    const htmlStrContent: string = this.helperService.convertText2Html(text);

    return DEFAULT_HEADER_ELEMENT.replace(DEFAULT_HEADER_CONTENT, `<span class="ml-2">${htmlStrContent}</span>`);
  }

  public clearCampaignCodeInput(): void {
    this.campaignCodeInput = '';
  }

  public async onSearchCourse(): Promise<void> {
    this.isLoading = true;
    // Fetch courses list via API
    await this.getCourseByAddress();
    if (this.listCourse.length === 0) {
      this.noCourse = true;
      this.displayCourse = false;
    } else {
      this.displayCourse = true;
    }
    this.noCourseError = this.listCourse.length === 0;
    this.isLoading = false;
  }

  public onChangeCampaignCodeInput(): void {
    this.campaignCodeInput = this.campaignCodeInput.replace(/[^a-zA-Z0-9]+$/g, '');
  }

  private handleEffectBtn(): void {
    if (this.selectedCourse) {
      this.blinkState = true;
    }
  }

  handleEffectSelectCourse(courseCodeId: string): string {
    const foundItem = this.courseSelectedByCourseIndex.some(
      (item) => item.courseCodeId === courseCodeId && item.hasCheckedCourse,
    );
    return foundItem ? 'blink' : 'not-blink';
  }
}
