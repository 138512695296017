import { ROUTES_PATH } from '@shared/common/constants';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home.component';
import { CourseChooseComponent } from './course-choose/course-choose.component';
import { AppInfoConfirmComponent } from './app-info-confirm/app-info-confirm.component';
import { AppInfoSony3DComponent } from './app-info-sony-3d/app-info-sony-3d.component';
import { AppInfoInputComponent } from './app-info-input/app-info-input.component';
import { AreaSearchComponent } from './area-search/area-search.component';
import { TermsOfServiceComponent } from './terms-of-service/terms-of-service.component';
import { ReserveComponent } from './reserve/reserve.component';
import { DirectAccessGuard } from '@core/guards/direct-access.guard';
import { SettingConstructionComponent } from './setting-construction/setting-construction.component';

export const routes: Routes = [
  {
    path: ROUTES_PATH.Home,
    component: HomeComponent,
    children: [
      { path: ROUTES_PATH.Index, component: AreaSearchComponent },
      { path: ROUTES_PATH.CourseChoose, component: CourseChooseComponent, canActivate: [DirectAccessGuard] },
      { path: ROUTES_PATH.AppInfoConfirm, component: AppInfoConfirmComponent, canActivate: [DirectAccessGuard] },
      { path: ROUTES_PATH.AppInfoSony3D, component: AppInfoSony3DComponent, canActivate: [DirectAccessGuard] },
      { path: ROUTES_PATH.AppInfoInput, component: AppInfoInputComponent, canActivate: [DirectAccessGuard] },
      { path: ROUTES_PATH.HomeTermsOfService, component: TermsOfServiceComponent, canActivate: [DirectAccessGuard] },
      { path: ROUTES_PATH.Reserve, component: ReserveComponent, canActivate: [DirectAccessGuard] },
      {
        path: ROUTES_PATH.SettingConstruction,
        component: SettingConstructionComponent,
        canActivate: [DirectAccessGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomeRoutingModule {}
