import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import * as _ from 'lodash';
import { ApiResponse } from '@shared/common/types';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class RequestService {
  private readonly apiUrl: string;
  private requestBaseOptions = {
    headers: {},
    withCredentials: false,
  };

  constructor(private readonly http: HttpClient, private authService: AuthService) {
    this.apiUrl = environment.apiUrl;
  }

  private setSecureHeader(apiPath: string): void {
    if (apiPath.includes('/admin/')) {
      const idToken: string = this.authService.getAccessToken();
      idToken && (this.requestBaseOptions.headers['Authorization'] = idToken);
    }
  }

  private handleError(error: HttpErrorResponse) {
    // server-side error
    if (error instanceof HttpErrorResponse) {
      error.error.status = error.status;
      return throwError(error.error);
    }

    // client-side error
    return throwError(error);
  }

  /**
   * @param {Observable} response The response
   * @returns {Observable}
   */
  private processResponse(response: Observable<any>): Observable<ApiResponse> {
    return response.pipe(catchError(this.handleError));
  }

  /**
   * @param {String} route The route path
   * @param {Object} options The http options
   * @returns {Observable}
   */
  public get(route: string, options = {}): Observable<ApiResponse> {
    this.setSecureHeader(route);
    options = _.merge(options, this.requestBaseOptions);
    const response = this.http.get(this.apiUrl + route, options);
    return this.processResponse(response);
  }

  /**
   * @param {String} route The api route
   * @param {Object} body The request body
   * @returns {Observable}
   */
  public post(route: string, body: any = {}): Observable<ApiResponse> {
    this.setSecureHeader(route);
    const response = this.http.post(this.apiUrl + route, body, this.requestBaseOptions);
    return this.processResponse(response);
  }

  /**
   * @param {String} route The api route
   * @param {Object} body The request body
   * @param {Object} options The request options
   * @returns {Observable}
   */
  public put(route: string, body: any = {}, options = {}): Observable<ApiResponse> {
    this.setSecureHeader(route);
    options = _.merge(options, this.requestBaseOptions);
    const response = this.http.put(this.apiUrl + route, body, options);
    return this.processResponse(response);
  }

  /**
   * @param {String} route The api route
   * @param {Object} options The request options
   * @returns {Observable}
   */
  public delete(route: string, options: any = {}): Observable<ApiResponse> {
    this.setSecureHeader(route);
    options = _.merge(options, this.requestBaseOptions);
    const response = this.http.delete(this.apiUrl + route, options);
    return this.processResponse(response);
  }
}
