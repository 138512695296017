export class KanaMaker {
    pre: string;
    roma: any[];
    kana:any[];
    hira:any[]
    HIRA_MATCHER: RegExp;
    KANA_MATCHER: RegExp;
    constructor() {
      this.pre = "";
      this.kana = [];
      this.hira = [];
      this.roma = [];
      this.HIRA_MATCHER = /[ぁ-ん]/g;
      this.KANA_MATCHER = /[ァ-ン]/g;
    }
  
    eval(e:any):any {
      const resource = e.target;
      const text = resource.value;
      if (e.key === 'Backspace' || e.key === 'Delete') {
        if(!text){
          this.clear()
          return
        }
        this.kana.pop();
        this.hira.pop();
        this.roma.pop();
        this.pre = text;
        return;
      }
      // Check if the pressed key is an alphabet
      if (e.keyCode === 32) {
        if (text.substr(text.length - 1, 1).match(/ |　/g)) {
          this.space();
        }
        return false;
      } else if (!(65 <= e.keyCode && e.keyCode <= 90)) {
        this.pre = text;
        return false;
      }
  
      // Add alphabet character
      this.roma.push(String.fromCharCode(e.keyCode));
  
      // Check diff from pre
      const diff = this._makeDiff(this.pre, text);
      this.pre = text;
  
      // Change all hira to kana
      const kanas = [];
      const kanaText = diff.replace(this.HIRA_MATCHER, (c) => {
        const k = String.fromCharCode(c.charCodeAt(0) + 0x60);
        kanas.push(k);
        return k;
      });
  
      // Add kanas / hiras
      this.kana = this.kana.concat(kanas);
      for (const k of kanas) {
        const h = String.fromCharCode(k.charCodeAt(0) - 0x60);
        this.hira.push(h);
      }
    }
  
    space() {
      this.kana.push("　");
      this.hira.push("　");
      this.roma.push(" ");
    }
  
    clear() {
      this.kana = [];
      this.hira = [];
      this.roma = [];
    }
  
    _makeDiff(base, now) {
      let samePart = "";
      for (let i = 0; i < now.length; i++) {
        if (i < base.length && now.charAt(i) === base.charAt(i)) {
          samePart += now.charAt(i);
        }
      }
      return now.replace(samePart, "");
    }
  
    getPart(name, isLast) {
      if (isLast === undefined) {
        return name;
      } else {
        const splited = name.split(/ |　/g);
        if (splited.length > 1) {
          return isLast ? splited[0] : splited[splited.length - 1];
        } else {
          return isLast ? name : "";
        }
      }
    }
  
    Kana(isLast) {
      return this.getPart(this.kana.join(""), isLast);
    }
  
    Hira(isLast) {
      return this.getPart(this.hira.join(""), isLast);
    }
  
    Roma(isLast) {
      const names = this.roma.join("").split(" ");
      let result = "";
      for (let i = 0; i < names.length; i++) {
        result += (i > 0 ? " " : "") + names[i].substr(0, 1).toLowerCase();
        result += names[i].substr(1).toLowerCase();
      }
      return this.getPart(result, isLast);
    }
    evalChange(str:string){
      let arr_str=str.length?str.split(''):[]
    }
  }
  