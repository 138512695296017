import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DataService } from '@core/services/data.service';
import { HelperService } from '@core/services/helper.service';
import { DECRYPT_DATA_FAIL_COOKIE_NAME, MESSAGES, PROGRESS_STEPS, ROUTES_PATH } from '@shared/common/constants';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import * as cryptoJs from 'crypto-js';
import { SettlementAgency } from '@core/models/settlementAgency.model';
@Component({
  selector: 'app-candy-w-payment',
  templateUrl: './candy_w-payment.component.html',
})
export class CandyWPaymentComponent implements OnInit {
  @ViewChild('candyWCardForm') form: ElementRef<HTMLFormElement>;
  @ViewChild('trans_no') transNoEle: ElementRef<HTMLInputElement>;
  @ViewChild('trans_date') transDateEle: ElementRef<HTMLInputElement>;
  @ViewChild('term_id') termIdEle: ElementRef<HTMLInputElement>;
  @ViewChild('yds_id') ydsIdEle: ElementRef<HTMLInputElement>;
  @ViewChild('div_trade') divTradeEle: ElementRef<HTMLInputElement>;
  @ViewChild('amount') amountEle: ElementRef<HTMLInputElement>;
  @ViewChild('last_name') lastNameEle: ElementRef<HTMLInputElement>;
  @ViewChild('result_url') resultUrlEle: ElementRef<HTMLInputElement>;
  @ViewChild('biz_info') bizInfoEle: ElementRef<HTMLInputElement>;
  @ViewChild('hash') hashEle: ElementRef<HTMLInputElement>;

  trans_no = Math.floor(Date.now() / 10 ** 3)
    .toString()
    .padStart(10, '0');
  trans_date = moment().format('YYYYMMDD');

  yds_id: string;
  end_point: string;
  operate_id: string;
  term_id: string;
  hash: string;
  cardForm: FormGroup;

  @Input() payment_url: string;
  @Input() settlementAgency: string;
  @Input() company_code: string;
  @Input() member_id: string;

  constructor(private readonly dataService: DataService, private readonly helperService: HelperService) {}

  ngOnInit() {
    this.decryptData();
  }

  fillDataToForm() {
    this.transNoEle.nativeElement.value = this.trans_no;
    this.transDateEle.nativeElement.value = this.trans_date;
    this.termIdEle.nativeElement.value = this.term_id;
    this.ydsIdEle.nativeElement.value = this.yds_id;
    this.divTradeEle.nativeElement.value = '0';
    this.amountEle.nativeElement.value = '100000';
    this.resultUrlEle.nativeElement.value = window.location.origin;
    this.hashEle.nativeElement.value = this.hash;
    this.bizInfoEle.nativeElement.value = '100' + this.member_id;
  }

  decryptData() {
    try {
      const settlementAgencyOrigin = cryptoJs.AES.decrypt(this.settlementAgency, environment.cryptoPrivateKey);
      const settlementAgency = JSON.parse(settlementAgencyOrigin.toString(cryptoJs.enc.Latin1)) as SettlementAgency;
      const memberId = cryptoJs.AES.decrypt(this.member_id, environment.cryptoPrivateKey).toString(cryptoJs.enc.Latin1);

      if (!settlementAgency || !memberId) throw new Error();

      this.term_id = settlementAgency.merchant_id;
      this.yds_id = settlementAgency.merchant_password;
      this.end_point = settlementAgency.end_point;
      this.operate_id = settlementAgency.operate_id;
      this.member_id = memberId;
      this.generateCandyWHash();
    } catch (error) {
      this.handleDecryptDataFail();
    }
  }

  async sha256(str: string) {
    const encoder = new TextEncoder();
    const data = encoder.encode(str);
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
    return hashHex;
  }

  async generateCandyWHash() {
    const template = `${this.end_point}${this.term_id}${this.trans_no}${this.trans_date}${this.yds_id}${this.operate_id}`;
    const hexStr = await this.sha256(template);
    this.hash = hexStr;
  }

  public submitForm(data?: { last_name: string }) {
    this.lastNameEle.nativeElement.value = data.last_name;
    this.fillDataToForm();
    this.form.nativeElement.submit();
  }

  private handleDecryptDataFail() {
    document.cookie = `${DECRYPT_DATA_FAIL_COOKIE_NAME}=${MESSAGES.AppConfirm.InvalidMemberId}; Path=/;`;
    this.backToFirstStep();
  }

  private backToFirstStep(): void {
    this.dataService.setStepDataStored(PROGRESS_STEPS.StepOne.Index);
    this.helperService.redirect(ROUTES_PATH.Index);
  }
}
