<app-loading *ngIf="isLoading"></app-loading>
<div class="container-fluid shadow">
  <div class="card">
    <div class="card-header">
      会員規約
    </div>
    <div class="card-body section">
      <ul *ngIf="memberShipsData.length" class="c-fw-6 c-mt-4 c-mb-4 py-0 pl-0">
        <li *ngFor="let item of memberShipsData;">
            <span *ngIf="item.url" class="text-green"><a href="{{item.url}}" target="_blank">{{item.url_link_name}}</a></span>
            <span *ngIf="!item.url" class="text-green"><a href="javascript: void(0);" (click)="openModal(detail, 'memberShip', item.url_link_name)">{{item.url_link_name}}</a></span>
        </li>
      </ul>
      <div *ngIf="!memberShipsData.length" class="mt-5"></div>
    </div>
    <div class="card-header">
      コースご利用規約
    </div>
    <div class="card-body section">
      <ul *ngIf="policiesData.length" class="c-fw-6 c-mt-4 c-mb-4 py-0 pl-0">
        <li *ngFor="let item of policiesData; index as indexOfItem">
          <span *ngIf="item.url" class="text-green"><a href="{{item.url}}" target="_blank">{{item.url_link_name}}</a></span>
          <span *ngIf="!item.url" class="text-green"><a href="javascript: void(0);" (click)="openModal(detail, 'policies', item.url_link_name)">{{item.url_link_name}}</a></span>
        </li>
      </ul>
      <div *ngIf="!policiesData.length" class="mt-5"></div>
    </div>
    <div class="card-header">
      注意事項
    </div>
    <div class="card-body section pb-4">
      <h6 class="d-flex heading c-mt-5">
        申し込みにあたっての注意事項をご確認ください
      </h6>
      <ul *ngIf="notesData.length" class="c-fw-6 c-mt-2 c-mb-4 pl-0">
        <li *ngFor="let item of notesData; index as indexOfItem">
          <span *ngIf="item.url" class="text-green"><a href="{{item.url}}" target="_blank">{{item.url_link_name}}</a></span>
          <span *ngIf="!item.url" class="text-green"><a href="javascript: void(0);" (click)="openModal(detail, 'notes', item.url_link_name)">{{item.url_link_name}}</a></span>
        </li>
      </ul>
      <div *ngIf="!notesData.length"></div>
      <div class="alert alert-default c-mt-4">
        <div class="d-flex align-items-center justify-content-center">
          <div class="wrapper-checkbox mr-2 position-relative">
            <input type="checkbox" value="" id="checkTerms" name="check" [checked]="isChecked"  (change)="onCheckBoxChange($event)"/>
            <label for="checkTerms"></label>
          </div>
          <label for="checkTerms" class="c-fw-6 mt-2 c-fs-18">すべての規約と注意事項をご確認の上、同意する場合はチェックを入れてください</label>
        </div>
      </div>
      <div class="footer row c-mt-2 align-items-center">
        <div class="col-sm-4 d-flex">
          <button class="btn btn-white btn-cw-150 btn-ch-44" (click)="backPreviousStep();">
            <span class="btn-text"><fa-icon [icon]="faChevronLeft" size="sm"></fa-icon> <span class="c-ff-h3 c-fs-16">一つ前へ戻る</span></span>
          </button>
        </div>
        <div class="col-sm-4 justify-content-center d-flex my-sm-4">
          <button class="btn btn-lime btn-cw-260 btn-ch-70" (click)="nextStep();" [disabled]="isDisable">
            <span class="btn-text"><fa-icon [icon]="faChevronRight"></fa-icon> 次へ進む</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #detail>
  <div class="modal-header">
    <h2 class="modal-title font-weight-bold">{{dataModal?.url_link_name}}</h2>
  </div>
  <div class="modal-body overflow-scroll">
    <pre>{{dataModal?.contents}}</pre>
  </div>
  <div class="modal-footer"></div>
</ng-template>
