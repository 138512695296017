import { Pipe, PipeTransform } from '@angular/core';
import { Address } from '@core/models/area.model';
import { HelperService } from '@core/services/helper.service';

@Pipe({
  name: 'addressDetailFormatPipe',
})
export class AddressDetailFormatPipe implements PipeTransform {
  constructor(private readonly helperService: HelperService) {}
  transform(addressObj: Address, ...args: any): string {
    return this.helperService.addressDisplayFormat(addressObj);
  }
}
