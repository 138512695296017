import { config } from '../../../config/app.config';

export const ROUTES_PATH = {
  Admin: 'admin',
  Home: '',
  Index: '',
  AreaSearch: 'area-search',
  AppInfoConfirm: 'app-info-confirm',
  AppInfoSony3D: 'app-info-sony-3d',
  HomeTermsOfService: 'terms-of-service',
  SearchRegistration: 'registration',
  AppInfoInput: 'app-info-input',
  Construction: 'construction',
  ServiceMaster: 'service-master',
  MasterArea: 'master-area',
  NotFound: 'not-found',
  Forbidden: 'forbidden',
  Maintenance: 'maintenance',
  InvalidRouter: '**',
  RegistCompanyMaster: 'company-master',
  RegistMailTemplate: 'regist-mail-template',
  RegistContractMaster: 'contract-master',
  MailCompleteRegist: 'regist-mail-template/mail-complete-regist',
  MailTemplateSetting: 'regist-mail-template/mail-template-setting',
  ContractGroup: 'contract-master/contract-group',
  ContractTerms: 'contract-master/terms',
  Reserve: 'reserve',
  CourseChoose: 'course-choose',
  Login: 'admin/login',
  Error: 'error',
  ErrorAdmin: 'admin/error',
  ChangePassword: 'change-password',
  SettingConstruction: 'setting-construction',
  RegisterMemberId: 'register-member-id',
  Reserve_SMBC: 'reserve_smbc',
};

export const DEFAULT_LOCALE = 'ja';

export const ROUTER_PATH_MATCH = {
  Full: 'full',
  Prefix: 'prefix',
};

export const PAGES_TITLE = {
  InputCourseMaster: 'サービスコースマスタ登録',
  AreaMasterOfService: '提供エリアマスタ登録',
  ConstructionManagement: '工事枠管理録',
  SearchRegistration: '申込情報検索',
  RegisterCompanyMaster: '会社マスタ登録',
  ContractMaster: '約款マスタ登録',
  MailTemplateMaster: 'メールテンプレート設定',
  AdminDashboard: 'CATV管理',
  Home: '新規お申し込み',
  Login: 'CATV管理｜ログイン',
  ChangePassword: 'CATV管理｜パスワード変更',
  Maintenance: 'メンテナンス中',
  RegisterMemberIds: '会員ID登録',
};

export const LIMIT_YEARS = {
  LimitYears: 120,
  conditionYears: 18,
};

export const API_ROUTES = {
  Admin: {
    GetCompanyInfo: '/admin/company',
    Company: '/admin/company',
    Email: '/admin/mail',
    Terms: '/admin/terms',
    Application: '/admin/applications',
    GetCourses: '/admin/service/courses',
    ServiceOption: '/admin/service-options',
    ImportCourse: '/admin/service/courses/csv',
    GetMemberIds: '/admin/kaiins/idinfo',
    ImportMemberIds: '/admin/kaiins/idinfo/csv',
    Construction: '/admin/construction',
    GetDetachedHouses: '/admin/detached/houses/csv',
    GetCollectiveHouses: '/admin/collective/houses/csv',
    UploadDetachedHouses: '/admin/detached/houses/csv',
    UploadCollectiveHouses: '/admin/collective/houses/csv',
    GetDocumentManual: '/admin/document/manual',
    SendMailTemplate: '/admin/mail/templates/send',
    DeleteMemberId: '/admin/kaiins/idinfo',
  },
  Home: {
    GetDetachedHousesAreas: '/detached/houses/area',
    GetDetachedHousesCourses: '/detached/houses/courses',
    GetCollectiveHousesArea: '/collective/houses',
    GetCollectiveHousesCourses: '/collective/houses/courses',
    GetCompanyInfo: '/company',
    GetConstruction: '/construction',
    TermsOfService: '/terms',
    CheckApplication: '/applications/check',
    Application: '/applications',
    ApplicationCompletion: '/applications/completion',
    CheckCardToken: '/applications/confirmation',
    Complementary: '/complementary_function/town_area',
    GetSettlementAgencies: '/settlement/agencies',
    PaymentStatus: '/payment/status',
    ApplicationDelete: '/applications/delete',
  },
};

export const RESULT_CODES = {
  Success: 1,
  NoData: 2,
  Pending: 3,
};

export const GENDERS = ['未選択', '女性', '男性'];

export const HOUSE_TYPE = {
  Detached: 'detached',
  Collective: 'collective',
};
export const KIND_CONDITION = {
  kind_survey: 8,
  kind_notification: 1,
  survey_flg: 1,
};

export const HOUSING_CATEGORY = {
  Detached: 1,
  Collective: 2,
};

export const MAIL_KIND_STRING = {
  application_mail: 'application_mail',
  field_survey_application_mail: 'field_survey_application_mail',
  admin_notification_mail: 'admin_notification_mail',
  field_survey_admin_notification_mail: 'field_survey_admin_notification_mail',
  construction_accept_mail: 'construction_accept_mail',
  detached_houses_admin_mail: 'detached_houses_admin_mail',
  collective_houses_admin_mail: 'collective_houses_admin_mail',
  detached_houses_company_mail: 'detached_houses_company_mail',
  collective_houses_company_mail: 'collective_houses_company_mail',
};
export const MAIL_KIND_VALUE = {
  application_mail: 1,
  admin_notification_mail: 2,
  construction_accept_mail: 3,
  detached_houses_admin_mail: 4,
  collective_houses_admin_mail: 5,
  detached_houses_company_mail: 6,
  collective_houses_company_mail: 7,
  field_survey_application_mail: 8,
  field_survey_admin_notification_mail: 9,
};
export const TERMS_KIND = {
  MemberAgreement: 1,
  Policy: 2,
  Notes: 3,
  List: [1, 2, 3],
};

export const TERMS_TITLE = ['会員規約', '利用規約', '注意事項'];

export const DIALOG_MESSAGE_TYPE = {
  Success: 'success',
  Error: 'error',
  Warning: 'warning',
};

export const MESSAGES = {
  Base: {
    UpdateSuccess: '更新が完了しました。',
    UpdateFailed: '更新失敗。',
    UploadSuccess: 'メッセージ：アップロードに成功しました。',
    UploadFail: 'アップロードに失敗しました',
    DownloadFileFailed: 'ファイルのダウンロードに失敗しました。',
    GetFailed: '失敗',
    DownloadFileSuccess: 'ファイルを正常にダウンロード',
    AddSuccess: '登録が完了しました。',
    DeleteSuccess: '削除が完了しました。',
    CommonFailed: '処理中にエラーが発生しました。しばらく待ってから、再度お試し下さい。',
    OpenSendMailFailed: 'メールテンプレートが登録されません。管理者にお問い合わせください',
    NoData: '該当住所がありません。郵便番号を確認のうえ、再検索してください',
    CheckSuccess: '成功を確認する',
    CheckFailed: '指定されていません。',
    ConfirmSuccess: '成功を確認する',
    ConfirmFailed: '確認に失敗しました',
    LoginFailed: 'ユーザー名またはパスワードが正しくありません ',
    ConfirmCodeFailed: '認証コードが間違っています ',
    MissingDataExport: 'データなし',
    NoApplication: '申請情報がありません',
    ChangePasswordSuccess: 'パスワードを更新しました',
    EmailSentSuccessfully: 'メールを送信しました',
  },
  Area: {
    WarningInvalidAddress: '正しいアドレスを選択してください。',
  },
  CourseChoose: {
    notFoundMemberId: '会員IDが存在しません。ケーブルTV局までお問い合わせください。',
  },
  Card: {
    InvalidCardToken: '指定されたクレジットカードは利用できません',
  },
  AppInput: {
    InvalidBirthday: '18歳以上でないと申込できません',
  },
  Member: {
    DeleteMemberSuccess: '削除しました',
  },
  AppConfirm: {
    InvalidMemberId: '不正な会員番号です',
  },
};

export const UPDATE_MANAGEMENT = {
  id: 1,
  idEmpty: 0,
  checkArray: 1,
};
export const RESPONSE_TYPES = {
  Blob: 'blob',
  NotAuthorizedException: 'NotAuthorizedException',
};

export const CHOOSE_FILE_MESSAGE = 'ファイルを選択してください。';

export const DEFAULT_PREPARATION_DAY = 7;

export const DEFAULT_DATE_RANGE_AVAILABLE = 60;

export const CARD_TOKEN_FORMAT = '$1?????$2';

export const DEFAULT_BULK_UPDATE_VALUE = {
  all: 1,
  morning: 1,
  afternoon: 1,
  evening: 1,
};

export const KIND_PAGE_TITLE = {
  1: '申込完了メール　申込者向け',
  2: '申込完了メール　局管理者向け',
  3: '工事日希望メール　申込者向け',
  4: '工事日希望メール　戸建住宅　局管理者向け',
  5: '工事日希望メール　集合住宅　局管理者向け',
  6: '工事日希望メール　戸建住宅　業者向け',
  7: '工事日希望メール　集合住宅　業者向け',
  8: '現地調査用申込完了メール　申込者向け',
  9: '現地調査用申込完了メール　局管理者向け',
};

export const TIME_ZONE = {
  NotSpecified: {
    value: 0,
    text: '指定無し',
  },
  Morning: {
    value: 1,
    text: '午前',
  },
  Afternoon: {
    value: 2,
    text: '午後早め',
  },
  Evening: {
    value: 3,
    text: '午後遅め',
  },
};

export const CALENDAR = {
  MaxChoose: 3,
  MinMonth: 1,
  MaxMonth: 12,
  MinLength: 28,
  MaxLength: 42,
  WeekLength: 7,
};

export const TIMEZONE_OF_DAY = {
  Morning: 1,
  Afternoon: 2,
  Evening: 3,
};

export const STORAGE_KEYS = {
  DataStoredSteps: 'data_stored_step',
  InfoInputValues: 'input_value',
  IsConfirm: 'confirm',
  HeaderContent: 'header_content',
  FooterContent: 'footer_content',
  StoreId: 'store_id',
  orderID: 'order_id',
  requestID: 'request_id',
  applicationID: 'application_id',
  BodySonyAuth: 'body_sony_auth',
  KaiinId: 'kaiin_id',
  KaiinPass: 'kaiin_pass',
  MaxTimeApi: 'max_time_api',
};

export const MONTHS = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
export const OBJECT_GENDER = {
  0: '未選択',
  2: '男性',
  1: '女性',
};

export const PROGRESS_STEPS = {
  StepOne: {
    Index: 1,
    Name: 'areaSearch',
  },
  StepTwo: {
    Index: 2,
    Name: 'courses',
  },
  StepThree: {
    Index: 3,
    Name: 'terms',
  },
  StepFour: {
    Index: 4,
    Name: 'infoInput',
  },
  StepFive: {
    Index: 5,
    Name: 'reserve',
  },
};
export const CHOME_CHARACTER = '丁目';
export const ADDRESS_CHARACTER = '番地';
export const ADDRESS_NUMBER_CHARACTER = '号';

export const TYPE_TERMS = {
  MemberShip: 'memberShip',
  Policies: 'policies',
  Notes: 'notes',
};
export const MIN_HEIGHT = 530;
export const MIN_WIDTH = 500;
export const MIN_HEIGHT_CONTRACT_MODAL = 583;
export const MIN_WIDTH_CONTRACT_MODAL = 800;

export const QUERY_PARAMS_KEY = {
  OriginalCompanyCode: 'original_company_code',
};

export const VERSION_TEXT = '%VERSION%';
export const DEFAULT_HEADER_CONTENT = '<img class="guest__logo" src="assets/img/WM_logo.png" alt="WM LOGO">';
export const DEFAULT_HEADER_ELEMENT = `
  <div class="page-header__content align-items-center">
    ${DEFAULT_HEADER_CONTENT}
  </div>
`;

export const DEFAULT_FOOTER_CONTENT = `
  <nav class="d-flex flex-wrap flex-row justify-content-between nav-align-items footer-copyright">
    <span class="first-span">&nbsp;</span>
    <span class="text-white home-ff-14">WELCOME MEMBERS powered by &copy; ITOCHU Techno-Solutions Corporation</span>
    <div class="d-flex justify-content-end">
      <a class="text-white mx-4" href="${config.ACCESS_DATA_URL}" target="_blank">アクセスデータの利用について</a>
      <span class="text-white home-ff-14 mr-3">%VERSION%</span>
    </div>
  </nav>`;

export const DEFAULT_FOOTER_ELEMENT = `
  <div class="page-footer__content align-items-center justify-content-center">
    ${DEFAULT_FOOTER_CONTENT}
  </div>
`;

export const OTHER_BUILDING_NAME = 'その他建物';

export const BOOLEAN_MAPPING_VALUE = {
  True: 1,
  False: 0,
};

export const KAIINS = {
  status: {
    unused: 1,
    used: 2,
  },
  text: {
    1: '未使用',
    2: '使用済み',
  },
};

export const PAYMENT_TYPES = {
  SONY: 'Sony_Payment',
  SMBC: 'SMBC',
  CANDY_W: 'CANDY_W',
  SMBC_3D: 'SMBC_3D',
  SONY_3D: 'Sony_Payment_3D',
};
export const EXPIRED_TIME = {
  YEAR_RANGE_END: 2050,
  MONTH_RANGE_LENGTH: 12,
};

export const COURSE_CHOOSE = {
  1: 'クレジットカード',
  2: '',
  3: '',
  5: '',
};

export enum PaymentMethod {
  NOTHING = <any>'-1',
  EMPTY = <any>'',
  NONE = <any>'0',
  CREDIT_CARD = <any>'1',
  ACCOUNT_TRANSFER = <any>'2', // 口座振替
  CONVENIENCE_STORE = <any>'3', // コンビニ
  OTHERS = <any>'5', // その他支払方法
}

export const PAYMENT_METHOD_VALUE = {
  NOTHING: PaymentMethod.NOTHING,
  EMPTY: PaymentMethod.EMPTY,
  NONE: PaymentMethod.NONE,
  CREDIT_CARD: PaymentMethod.CREDIT_CARD,
};

export const MAIL_TEMPLATE_MODAL_STATIC_TAGS_HEIGHT = 320;

export const ExplanatoryKeyName = {
  1: {
    explanatory: 'credit_Explanatory',
    explanatory_url: 'credit_Explanatory_url',
  },
  2: {
    explanatory: 'account_Explanatory',
    explanatory_url: 'account_Explanatory_url',
  },
  3: {
    explanatory: 'convenience_Explanatory',
    explanatory_url: 'convenience_Explanatory_url',
  },
  5: {
    explanatory: 'other_Explanatory',
    explanatory_url: 'other_Explanatory_url',
  },
};

export const creditNamesBasedOnPaymentMethodValue = {
  [PaymentMethod.ACCOUNT_TRANSFER]: {
    fieldName: 'credit_name_1',
    value: '',
  },
  [PaymentMethod.CONVENIENCE_STORE]: {
    fieldName: 'credit_name_2',
    value: '',
  },
  [PaymentMethod.OTHERS]: {
    fieldName: 'credit_name_3',
    value: '',
  },
};

export const CANDY_W_SUCCESS_RESULT = '000';

export const CARD_INFO_COOKIE_NAME = 'card-info';
export const CHECK_CARD_INFO_COOKIE_EMPTY_REGEX = /card-info=$|card-info=;/;
export const GET_CARD_INFO_COOKIE_VALUE_REGEX = /(card-info=.*)|(card-info=.*;)/;

export const NOT_FOUND_MEMBER_ID_COOKIE_NAME = 'member-id-not-found';
export const NOT_FOUND_SETTLEMENT_AGENCY_COOKIE_NAME = 'settlement-agency-not-found';
export const DECRYPT_DATA_FAIL_COOKIE_NAME = 'fail-data-decrypt';
