import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { RequestService } from '../request.service';
import { ApiResponse } from '@shared/common/types';
import { API_ROUTES, HOUSE_TYPE, HOUSING_CATEGORY } from '@shared/common/constants';
import { ConstructionsBody } from '@core/models/reserve.modal';

@Injectable({
  providedIn: 'root',
})
export class ReserveService {
  constructor(private requestService: RequestService) {}

  /**
   * Get constructions
   * @param {string} companyCode The company code
   * @param {number} year The year
   * @param {number} month The month
   * @param {number} housingCategory The house category
   * @param {string} facilityFlg The facility flag
   * @returns
   */
  public getConstructions(
    companyCode: string,
    year: number,
    month: number,
    housingCategory: number,
    facilityFlg: string,
  ): Observable<ApiResponse> {
    const houseType = housingCategory === HOUSING_CATEGORY.Detached ? HOUSE_TYPE.Detached : HOUSE_TYPE.Collective;
    let params = `?company_code=${companyCode}&year=${year}&month=${month}`;
    housingCategory === HOUSING_CATEGORY.Collective && (params += `&facility_flg=${facilityFlg ? facilityFlg : ''}`);
    const url = `${API_ROUTES.Home.GetConstruction}/${houseType}/frames/${params}`;
    return this.requestService.get(url);
  }

  /**
   * Update constructions
   * @param {ConstructionsBody} data Request body data
   * @returns
   */
  public updateConstructions(data: ConstructionsBody): Observable<ApiResponse> {
    const url = `${API_ROUTES.Home.GetConstruction}/desire/days`;
    return this.requestService.put(url, data);
  }

  /**
   * Get desire days
   * @param {number} applicationId The application id
   * @param {string} companyCode The company code
   * @returns
   */
  public getDesireDays(applicationId: number, companyCode: string): Observable<ApiResponse> {
    const url = `${API_ROUTES.Home.GetConstruction}/desire/days/?application_id=${applicationId}&company_code=${companyCode}`;
    return this.requestService.get(url);
  }
}
