import { HomeRoutingModule } from './home-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DirectivesModule } from '../directives/directives.module';
import { HomeComponent } from './home.component';
import { AppInfoConfirmComponent } from './app-info-confirm/app-info-confirm.component';
import { AppInfoSony3DComponent } from './app-info-sony-3d/app-info-sony-3d.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppInfoInputComponent } from './app-info-input/app-info-input.component';
import { TermsOfServiceComponent } from './terms-of-service/terms-of-service.component';
import { ReserveComponent } from './reserve/reserve.component';
import { CourseChooseComponent } from './course-choose/course-choose.component';
import { AreaSearchComponent } from './area-search/area-search.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoadingModule } from '@shared/components/loading/loading.module';
import { AddressDetailFormatPipe } from '@core/pipes/address-detail-format.pipe';
import { SettingConstructionComponent } from './setting-construction/setting-construction.component';
import { SmbcPaymentComponent } from './smbc-payment/smbc-payment.component';
import { SharedNl2brModule } from '../shared-modules/shared-nl2br/shared-nl2br.module';
import { CandyWPaymentComponent } from './candy_w-payment/candy_w-payment.component';

@NgModule({
  declarations: [
    HomeComponent,
    AppInfoInputComponent,
    AppInfoConfirmComponent,
    AppInfoSony3DComponent,
    TermsOfServiceComponent,
    ReserveComponent,
    CourseChooseComponent,
    AreaSearchComponent,
    AddressDetailFormatPipe,
    SettingConstructionComponent,
    SmbcPaymentComponent,
    CandyWPaymentComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    HomeRoutingModule,
    FontAwesomeModule,
    BsDropdownModule,
    LoadingModule,
    DirectivesModule,
    SharedNl2brModule,
  ],
})
export class HomeModule {}
