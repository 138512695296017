import { Observable } from 'rxjs';
import { RequestService } from '@core/services/request.service';
import { API_ROUTES } from '@shared/common/constants';
import { Injectable } from '@angular/core';
import { ApiResponse } from '@shared/common/types';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(private readonly requestService: RequestService) {}

  /**
   * Get Company Info by id
   * @param {number} companyId the company id
   * @returns
   */
  public getCompanyInfo(companyCode: string): Observable<ApiResponse> {
    const url: string = API_ROUTES.Home.GetCompanyInfo + `?company_code=${companyCode}`;
    return this.requestService.get(url);
  }
}
