import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, EventEmitter, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { EXPIRED_TIME } from '@shared/common/constants';

@Component({
  selector: 'app-smbc-payment',
  templateUrl: './smbc-payment.component.html',
  styleUrls: ['./smbc-payment.component.scss'],
})
export class SmbcPaymentComponent implements OnInit {
  @Output() hideModal = new EventEmitter();
  @ViewChild('ccNumber') creditCardNumber: ElementRef;
  faTimes: any = faTimes;
  monthsRange: Array<number> = [];
  yearsRange: Array<number> = [];
  cardForm: FormGroup;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.cardForm = this.fb.group({
      cardNumber: ['', [Validators.required, Validators.maxLength(19), Validators.pattern('^[-0-9 ]*$')]],
      expireMonth: ['', [Validators.required, Validators.maxLength(2), Validators.pattern('^[-0-9]*$')]],
      expireYear: ['', [Validators.required, Validators.maxLength(4), Validators.pattern('^[-0-9]*$')]],
      securityCode: [
        '',
        [Validators.required, Validators.minLength(3), Validators.maxLength(4), Validators.pattern('^[-0-9]*$')],
      ],
    });

    const currentYear: number = new Date().getFullYear();
    this.yearsRange.length = EXPIRED_TIME.YEAR_RANGE_END - currentYear;
    for (let index = 0; index < EXPIRED_TIME.MONTH_RANGE_LENGTH; index++) {
      this.monthsRange[index] = index + 1;
    }
    for (let index = 0; index <= EXPIRED_TIME.YEAR_RANGE_END; index++) {
      this.yearsRange[index - currentYear] = index;
    }
  }

  public closeModal(): void {
    document.body.style.overflow = '';
    this.hideModal.emit(false);
  }
  public creditCardNumberSpacing() {
    const input = this.creditCardNumber.nativeElement;
    const { selectionStart } = input;
    const { cardNumber } = this.cardForm.controls;

    const trimmedCardNum = cardNumber.value.replace(/\s+/g, '');
    const partitions = [4, 4, 4, 4];
    const numbers = [];
    let position = 0;
    partitions.forEach((partition) => {
      const part = trimmedCardNum.substr(position, partition);
      if (part) numbers.push(part);
      position += partition;
    });
    cardNumber.setValue(numbers.join(' '));

    // / Handle caret position if user edits the number later /
    if (selectionStart < cardNumber.value.length - 1) {
      input.setSelectionRange(selectionStart, selectionStart, 'none');
    }
  }

  public get cardFormControls() {
    return this.cardForm.controls;
  }
}
