import { API_ROUTES, HOUSE_TYPE } from '@shared/common/constants';
import { ApiResponse } from '@shared/common/types';
import { Observable } from 'rxjs';
import { RequestService } from '../request.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AreaService {
  constructor(private readonly requestService: RequestService) {}

  /* add Observable<ApiResponse> if having postal code*/
  public searchAddressByPostalCode(type: string, postalCode: string = ''): Observable<ApiResponse> {
    const url: string =
      (type === HOUSE_TYPE.Detached
        ? API_ROUTES.Home.GetDetachedHousesAreas
        : API_ROUTES.Home.GetCollectiveHousesArea) + `?postal_code=${postalCode}`;
    return this.requestService.get(url);
  }
}
