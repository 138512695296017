import { ICognitoStorage } from 'amazon-cognito-identity-js';

export default class AuthStorage implements ICognitoStorage {
  constructor() {}
  setItem(key: string, value: string): void {
    sessionStorage.setItem(key, value);
  }

  getItem(key: string): string {
    return sessionStorage.getItem(key);
  }

  removeItem(key: string): void {
    sessionStorage.removeItem(key);
  }

  clear(): void {
    sessionStorage.clear();
  }
}
