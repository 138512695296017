<div class="payment__wrapper">
  <div id="paymentArea" class="">
    <form [formGroup]="cardForm" autocomplete="off" novalidate="">
      <ul id="headerArea">
        <li>
          <button (click)="closeModal()">
            <fa-icon [icon]="faTimes"></fa-icon>
            <!-- <img
            alt
            style="margin-left: -6px; margin-top: -1px;"
            width="25"
            src="/assets/img/btn_close.gif"
          > -->
          </button>
        </li>
      </ul>
      <div id="paymentBox">
        <div align="center">
          <img src="/assets/img/logo_smbc_gmo_payment.jpg" alt="SMBC GMO Payment">
        </div>
        <div align="center">
        </div>
        <p class="mt_20 blue">カード番号 Card Number</p>
        <div class="mt_05">
          <input type="text" id="cardno" name="cardno" formControlName="cardNumber" #ccNumber class="txtHankaku"
            [ngClass]="{'is-invalid': cardFormControls.cardNumber.value && cardFormControls.cardNumber.errors}"
            maxlength="19" value="" style="ime-mode: disabled;" (keyup)="creditCardNumberSpacing();"
            placeholder="1234 5678 9012 3456">
        </div>
        <p class="mt_20 blue">有効期限 Expiration</p>
        <ul class="listTbl w100p mt_05" id="expiration">
          <li style="width: 45%;">
            <select id="expire_month" name="expire_month" class="selectBox"
              [ngClass]="{'is-invalid': cardFormControls.expireMonth.value && cardFormControls.expireMonth.errors}"
              formControlName="expireMonth">
              <option value="">--</option>
              <option *ngFor="let month of monthsRange" value="{{('0' + month).slice(-2)}}">{{month}}</option>
            </select>
          </li>
          <li class="txtC" style="width: 10%;"> / </li>
          <li style="width: 45%;">
            <select id="expire_year" name="expire_year" class="selectBox"
              [ngClass]="{'is-invalid': cardFormControls.expireYear.value && cardFormControls.expireYear.errors}"
              formControlName="expireYear">
              <option value="">----</option>
              <option *ngFor="let year of yearsRange" value="{{year}}">{{year}}</option>
            </select>
          </li>
        </ul>
        <div style="position: relative;">
          <p class="mt_20 blue">
            セキュリティコード CVC/CVV
          </p>
          <div class="mt_05">
            <input type="password" name="securitycode" class="txtHankaku"
              [ngClass]="{'is-invalid': cardFormControls.securityCode.value && cardFormControls.securityCode.errors}"
              formControlName="securityCode" value="" id="securitycode" maxlength="4" placeholder="●●●">
          </div>
        </div>
        <div class="d-flex justify-content-center mt-3">
          <span id="wrongCardMsg" class="text-danger"></span>
        </div>
        <button type="button" class="btnGreen mt_10" name="next" id="next" [disabled]="cardForm.invalid"
          onclick="verifyCardNumber()">
          次へ　NEXT
        </button>
      </div>
    </form>
  </div>
</div>
