import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '@core/services/data.service';
import { HelperService } from '@core/services/helper.service';
import { ROUTES_PATH, STORAGE_KEYS, PAGES_TITLE } from '@shared/common/constants';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
  constructor(private helperService: HelperService, private router: Router, private dataService: DataService) {}

  ngOnInit(): void {
    this.helperService.setPageTitle(PAGES_TITLE.Home);
    this.clearData();
  }

  clearData(): void {
    this.dataService.clearData();
    this.helperService.removeSessionStorageValue(STORAGE_KEYS.DataStoredSteps);
  }

  back(): void {
    this.helperService.redirect(ROUTES_PATH.Index, this.router.url.includes(ROUTES_PATH.Admin));
  }
}
