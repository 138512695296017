import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { RequestService } from '../request.service';
import { ApiResponse } from '@shared/common/types';
import { API_ROUTES } from '@shared/common/constants';
import { ApplicationCompletionBody } from '@core/models/application.model';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ApplicationsService {
  constructor(private requestService: RequestService) {}
  /**
   * POST check value of app-info-input
   * @param {Object} objectData The object data of app-info-input
   * @returns
   */
  public checkDataOfAppInput(objectData: any): Observable<ApiResponse> {
    const url = `${API_ROUTES.Home.CheckApplication}`;
    return this.requestService.post(url, objectData);
  }
  /**
   * POST value of app-info-confirm
   * @param {Object} objectData The object data of app-info-confirm
   * @returns
   */
  public postDataOfAppConfirm(objectData: any): Observable<ApiResponse> {
    const url = `${API_ROUTES.Home.Application}`;
    return this.requestService.post(url, objectData);
  }

  /**
   * Update application completion
   * @param {ApplicationCompletionBody} data Request body data
   * @returns
   */
  public updateApplicationCompletion(data: ApplicationCompletionBody): Observable<ApiResponse> {
    const url = `${API_ROUTES.Home.ApplicationCompletion}`;
    return this.requestService.put(url, data);
  }

  /**
   * Check the card token is valid or not
   * @param {String} token The card token
   * @param {String} companyCode The company code
   * @returns {Observable}
   */
  public checkValidCardToken(token: string, companyCode: string): Observable<ApiResponse> {
    const url: string = `${API_ROUTES.Home.CheckCardToken}`;
    let params: HttpParams = new HttpParams();
    params = params.set('card_token_id', token);
    params = params.set('company_code', companyCode);

    return this.requestService.get(url, { params });
  }
}
