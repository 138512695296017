import { HelperService } from '@core/services/helper.service';
import { AuthService } from '@core/services/auth.service';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { ROUTES_PATH } from '@shared/common/constants';

@Injectable({
  providedIn: 'root',
})
export class MustBeGuestGuard implements CanActivate {
  constructor(private authService: AuthService, private helperService: HelperService) {}
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const isLoggedIn: boolean = await this.authService.isLoggedIn();
    if (environment.applyAuth && isLoggedIn) {
      this.helperService.adminRedirect(ROUTES_PATH.Index);
      return Promise.resolve(false);
    }

    return Promise.resolve(true);
  }
}
