import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MessageComponent } from '@shared/components/message/message.component';

@Injectable({
  providedIn: 'root',
})
export class DialogServiceService {
  private modalRef;
  constructor(private readonly toastrService: ToastrService, private readonly bsModalService: BsModalService) {}

  /**
   * Open dialog message
   * @param {} props
   * @returns
   */
  public openModal(props) {
    const modalOptions: ModalOptions = {
      initialState: {
        type: props.type,
        message: props.message,
      },
    };

    this.modalRef = this.bsModalService.show(MessageComponent, modalOptions);
    return this.modalRef;
  }

  /**
   * Open toastr
   * @param {} props
   * @returns
   */
  public open(props): void {
    this.toastrService[props.type](props.message, props.title);
  }

  /**
   * Clear all toastr
   */
  public clearToastr(): void {
    this.toastrService.clear();
  }
}
