import { Injectable } from '@angular/core';
import { API_ROUTES } from '@shared/common/constants';
import { ApiResponse } from '@shared/common/types';
import { Observable } from 'rxjs';
import { RequestService } from '../request.service';

@Injectable({
  providedIn: 'root',
})
export class ComplementaryService {
  constructor(private requestService: RequestService) {}
  /**
   * Get contract town area
   * @param {String} postalCode The postal code
   * @returns
   */

  public getContractInfoTownArea(postalCode: string): Observable<ApiResponse> {
    const url = `${API_ROUTES.Home.Complementary}?postal_code=${postalCode}`;
    return this.requestService.get(url);
  }
}
