import { Injectable } from '@angular/core';
import { ROUTES_PATH } from '@shared/common/constants';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { environment } from 'src/environments/environment';
import { HelperService } from './helper.service';
import AuthStorage from '@shared/utils/storage-util';
import { UserInfo } from '@shared/common/types';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private helperService: HelperService) {}

  /**
   * Check current user is admin
   * @returns {boolean}
   */
  public isAdmin(): boolean {
    return true;
  }

  /**
   * Is admin logged in
   * @returns {Boolean} Is user logged in or not
   */
  public async isLoggedIn(): Promise<boolean> {
    let isAuth: boolean = false;
    try {
      const cognitoUser = this.getCognitoUserPool().getCurrentUser();
      if (cognitoUser) {
        isAuth = await this.getUserSession(cognitoUser);
      }

      return isAuth;
    } catch {
      return false;
    }
  }

  private getUserSession(cognitoUser): Promise<boolean> {
    return new Promise((resolve, reject) => {
      cognitoUser.getSession((err: any, session: any) => {
        if (err) {
          return reject(err);
        }

        resolve(session.isValid());
      });
    });
  }

  /**
   * Get cognito user poll
   * @returns {CognitoUserPool}
   */
  public getCognitoUserPool(): CognitoUserPool {
    const poolData = {
      UserPoolId: environment.cognitoUserPoolId,
      ClientId: environment.cognitoAppClientId,
      Storage: new AuthStorage(),
    };

    return new CognitoUserPool(poolData);
  }

  /**
   * Get cognito user access token
   * @returns {string}
   */
  public getAccessToken(): string {
    let idToken: string = '';
    const cognitoUser = this.getCognitoUserPool().getCurrentUser();
    if (cognitoUser) {
      cognitoUser.getSession((err: any, session: any) => session.isValid() && (idToken = session?.idToken?.jwtToken));
    }

    return idToken;
  }

  /**
   * Get the username of user logged in
   * @returns {String}
   */
  public getUserEmail(): Promise<string> {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return new Promise((resolve, reject) => {
      const cognitoUser = this.getCognitoUserPool().getCurrentUser();
      if (cognitoUser) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        cognitoUser.getSession((error: Error, session: string) => {
          if (error) {
            return resolve('');
          }

          cognitoUser.getUserAttributes((error: Error, results: any) => {
            if (error) {
              return resolve('');
            }

            const email: string = this.convertUserAttributesToObject(results).email;
            return resolve(email);
          });
        });
      } else {
        return resolve('');
      }
    });
  }

  /**
   * User logout -> Destroy the user session
   */
  public logout(): void {
    const cognitoUser = this.getCognitoUserPool().getCurrentUser();
    cognitoUser?.signOut();
    this.helperService.redirect(ROUTES_PATH.Login);
  }

  public convertUserAttributesToObject(userAttributes: any): UserInfo {
    const userInfo: any = {};
    userAttributes.forEach((attributePe) => {
      userInfo[attributePe.getName()] = attributePe.getValue();
    });

    return userInfo;
  }
}
