import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { RequestService } from '../request.service';
import { ApiResponse } from '@shared/common/types';
import { API_ROUTES } from '@shared/common/constants';

@Injectable({
  providedIn: 'root',
})
export class TermsOfServiceService {
  constructor(private requestService: RequestService) {}
  /**
   * Get terms of service managements
   * @param {Number} termManagementId The term management id
   * @param {String} companyCode The term company id
   * @returns
   */
  public getTermsOfServiceManagements(
    termManagementId: number,
    companyCode: string,
    isIncludeMemberId: boolean,
  ): Observable<ApiResponse> {
    const url = `${API_ROUTES.Home.TermsOfService}/managements/${termManagementId}?company_code=${companyCode}&included_member_id=${isIncludeMemberId}`;
    return this.requestService.get(url);
  }
}
