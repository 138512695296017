import { HelperService } from '@core/services/helper.service';
import { DataService } from '@core/services/data.service';
import { Component, OnInit } from '@angular/core';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import {
  PROGRESS_STEPS,
  ROUTES_PATH,
  OBJECT_GENDER,
  RESULT_CODES,
  DIALOG_MESSAGE_TYPE,
  MESSAGES,
  STORAGE_KEYS,
  ADDRESS_NUMBER_CHARACTER,
  ADDRESS_CHARACTER,
  CHOME_CHARACTER,
  HOUSE_TYPE,
  BOOLEAN_MAPPING_VALUE,
  PaymentMethod,
  COURSE_CHOOSE,
  creditNamesBasedOnPaymentMethodValue,
  PAYMENT_TYPES,
  DECRYPT_DATA_FAIL_COOKIE_NAME,
  PAYMENT_METHOD_VALUE,
} from '@shared/common/constants';
import { ApplicationsService } from '@core/services/home/applications.service';
import { DialogServiceService } from '@core/services/dialog-service.service';
import { HttpStatusCode } from '@angular/common/http';
import * as cryptoJs from 'crypto-js';
import { Company } from '@core/models/company.model';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-app-info-confirm',
  templateUrl: './app-info-confirm.component.html',
  styleUrls: ['./app-info-confirm.component.scss'],
})
export class AppInfoConfirmComponent implements OnInit {
  faChevronLeft: any = faChevronLeft;
  faChevronRight: any = faChevronRight;
  dataOfAppInput: any = {};
  confirmDetailData: any = {};
  constGenderString: any = OBJECT_GENDER;
  isLoading: boolean = false;
  courseInfo: any = {};
  cardNumber: string = '';
  paymentMethodValue: PaymentMethod = PaymentMethod.EMPTY;
  paymentMethodOpt = '';
  listOptionChoice: any = [];
  public routes = ROUTES_PATH;
  selectedPaymentMethodExplanatory = {
    explanatory: '',
    explanatory_url: '',
  };
  remark_title: string;
  company: Company;
  constructor(
    private readonly dataService: DataService,
    private readonly helperService: HelperService,
    private applicationsService: ApplicationsService,
    private dialogService: DialogServiceService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.getDataFromSessionStorage();
    if (window.location.search) window.history.pushState({}, document.title, window.location.pathname);
  }

  private getDataFromSessionStorage() {
    const infoInputStored: string = this.helperService.getSessionStorageValue(STORAGE_KEYS.InfoInputValues);
    const dataParsed = this.dataService.getDataStored(PROGRESS_STEPS.StepTwo.Index);

    const { selectedCourse, company } = dataParsed;
    this.company = company;
    this.remark_title = company?.remark_title || '';
    this.courseInfo = selectedCourse;
    const { options } = this.courseInfo;
    options.map((option) => {
      option.checked === true && this.listOptionChoice.push(option);
      return option;
    });

    this.dataOfAppInput = this.helperService.parseJson(infoInputStored);
    if (!(this.dataOfAppInput && this.dataOfAppInput.application)) {
      this.helperService.redirect(ROUTES_PATH.Error);
      return;
    }
    this.dataOfAppInput.application.detail?.remarks?.replace(/\n/g, '<br/>');

    this.selectedPaymentMethodExplanatory = this.dataOfAppInput.application.explanatory;
    this.cardNumber = this.dataOfAppInput.application.cardNumber || '';
    this.paymentMethodValue = this.dataOfAppInput.application.payment.method_value || '';
    this.confirmDetailData = this.dataOfAppInput.application.detail;
    const establishChome =
      this.confirmDetailData.establish_chome && this.confirmDetailData.establish_chome.concat(CHOME_CHARACTER);
    const establishApartmentName = this.confirmDetailData.establish_apartment_name;

    const contractChome =
      this.confirmDetailData.contract_chome && this.confirmDetailData.contract_chome.concat(CHOME_CHARACTER);
    const contractApartmentName = this.confirmDetailData.contract_apartment_name;
    const contractAddressNumber =
      this.confirmDetailData.contract_address_number &&
      this.confirmDetailData.contract_address_number.concat(ADDRESS_NUMBER_CHARACTER);
    const establishAddressNumber =
      this.confirmDetailData.establish_address_number &&
      this.confirmDetailData.establish_address_number.concat(ADDRESS_NUMBER_CHARACTER);

    const establishAddress =
      this.confirmDetailData.establish_address && this.confirmDetailData.establish_address.concat(ADDRESS_CHARACTER);
    const contractAddress =
      this.confirmDetailData.contract_address && this.confirmDetailData.contract_address.concat(ADDRESS_CHARACTER);

    this.confirmDetailData.establishAddress = `${this.confirmDetailData.establish_prefectures} ${this.confirmDetailData.establish_municipalities} ${this.confirmDetailData.establish_town_area} ${establishChome} ${establishAddress} ${establishAddressNumber} ${establishApartmentName} ${this.confirmDetailData.establish_room_number}`;
    this.confirmDetailData.contractAddress = `${this.confirmDetailData.contract_prefectures} ${this.confirmDetailData.contract_municipalities} ${this.confirmDetailData.contract_town_area} ${contractChome} ${contractAddress} ${contractAddressNumber} ${contractApartmentName} ${this.confirmDetailData.contract_room_number}`;

    // this.confirmDetailData.spsvBrowserJavaEnabled = navigator.javaEnabled();
    // this.confirmDetailData.spsvBrowserLanguage = navigator.language;
    // this.confirmDetailData.spsvBrowserColorDepth = screen.colorDepth;
    // this.confirmDetailData.spsvBrowserScreenHeight = screen.height;
    // this.confirmDetailData.spsvBrowserScreenWidth = screen.width;
    // this.confirmDetailData.spsvBrowserTimezone = new Date().getTimezoneOffset();
    // this.confirmDetailData.spsvUserAgent = navigator.userAgent;

    const creditNameValue = company[creditNamesBasedOnPaymentMethodValue[this.paymentMethodValue]?.fieldName];
    this.paymentMethodOpt = creditNameValue || COURSE_CHOOSE[this.paymentMethodValue];
  }

  backPreviousStep(): void {
    this.helperService.removeSessionStorageValue(STORAGE_KEYS.IsConfirm);
    this.helperService.redirectByOptions(ROUTES_PATH.AppInfoInput, { skipLocationChange: true });
  }

  nextSony3D(): void {
    this.helperService.redirectByOptions(ROUTES_PATH.AppInfoSony3D, { skipLocationChange: true });
  }

  convertHalfWidthToFullWidth(roomNumber): string {
    return roomNumber.replace(/[A-Za-z0-9]/g, (s) => {
      return String.fromCharCode(s.charCodeAt(0) + 0xfee0);
    });
  }

  get shouldHiddenPayment() {
    const stepDataStored = this.dataService.getDataStored(PROGRESS_STEPS.StepTwo.Index);
    const paymentMethod: PaymentMethod[] = stepDataStored?.selectedCourse?.payment_Method || [];

    return !paymentMethod.includes(PaymentMethod.NONE);
  }

  async nextStep() {
    if (
      (this.company.credit_code === PAYMENT_TYPES.SMBC_3D || this.company.credit_code === PAYMENT_TYPES.SONY_3D) &&
      this.paymentMethodValue == 1
    ) {
      var new_tab = window.open('about:blank', 'new_tab');
      setTimeout(function () {
        new_tab.document.title = 'Processing...';
      }, 100);
      new_tab.document.write(`<head></head><body>
        <div class="processing-text">Processing...</div> 
      </body>`);
    }
    this.isLoading = true;
    const { options } = this.courseInfo;
    const listOptionsChoose = [];
    options.forEach((element) => {
      element.checked === true && listOptionsChoose.push(element.id);
    });
    this.dataOfAppInput.application.select_serviceOption_ids = listOptionsChoose;
    const dataParsedStepOne = this.dataService.getDataStored(PROGRESS_STEPS.StepOne.Index);
    const { houseType, selectedAddress } = dataParsedStepOne;
    if (houseType === HOUSE_TYPE.Collective) {
      this.dataOfAppInput.application.detail.facility_flg = selectedAddress.facility_flg || '';
    }
    this.dataOfAppInput.application.detail.field_survey_flg =
      this.courseInfo.field_survey_flg || BOOLEAN_MAPPING_VALUE.False;
    delete this.dataOfAppInput.application.detail.email_address_confirmation;
    delete this.dataOfAppInput.application.detail.contractAddress;
    delete this.dataOfAppInput.application.detail.establishAddress;
    delete this.dataOfAppInput.application.detail.checkedContract;
    delete this.dataOfAppInput.application.cardNumber;

    const establishRoomNumber = this.dataOfAppInput.application.detail.establish_room_number;
    const contractRoomNumber = this.dataOfAppInput.application.detail.contract_room_number;

    this.dataOfAppInput.application.detail.establish_room_number =
      establishRoomNumber && this.convertHalfWidthToFullWidth(establishRoomNumber);
    this.dataOfAppInput.application.detail.contract_room_number =
      contractRoomNumber && this.convertHalfWidthToFullWidth(contractRoomNumber);

    this.dataOfAppInput.application.payment.card_token = this.helperService.decodeBase64EncodeString(
      this.dataOfAppInput.application.payment.card_token,
    );

    /*
     * Only handle decrypt and add member_id to application form if company credit_code is CANDY_W
     */
    if (this.company.credit_code === PAYMENT_TYPES.CANDY_W) {
      const dataStepThreeParsed = this.dataService.getDataStored(PROGRESS_STEPS.StepThree.Index);

      try {
        const member_id = cryptoJs.AES.decrypt(dataStepThreeParsed.member_id, environment.cryptoPrivateKey).toString(
          cryptoJs.enc.Latin1,
        );
        if (!member_id) throw new Error();

        this.dataOfAppInput.application.payment = {
          ...this.dataOfAppInput.application.payment,
          credit_code: PAYMENT_TYPES.CANDY_W,
          member_id,
        };
      } catch (error) {
        document.cookie = `${DECRYPT_DATA_FAIL_COOKIE_NAME}=${MESSAGES.AppConfirm.InvalidMemberId}; Path=/;`;
        return this.backToFirstStep();
      }
    }

    this.applicationsService
      .postDataOfAppConfirm(this.dataOfAppInput)
      .toPromise()
      .then((data: any): Promise<any> => {
        if (data.result_code === RESULT_CODES.Success) {
          if (data.body?.result === RESULT_CODES.NoData) {
            this.dialogService.open({
              type: DIALOG_MESSAGE_TYPE.Warning,
              message: MESSAGES.Card.InvalidCardToken,
            });

            this.backPreviousStep();
            return Promise.resolve();
          } else if (data && data.body && data.body.responseSony_3d) {
            const form = document.createElement('form');
            form.action = data.body.responseSony_3d['base_url'];
            form.method = 'POST';
            let dataKey = data.body.responseSony_3d['key'];
            form.target = 'new_tab';
            for (const key in dataKey) {
              if (dataKey.hasOwnProperty(key)) {
                const input = document.createElement('input');
                input.type = 'hidden';
                input.name = key;
                input.value = dataKey[key];
                form.appendChild(input);
              }
            }
            document.body.appendChild(form);
            form.submit();
            this.helperService.removeSessionStorageValue(STORAGE_KEYS.IsConfirm);
            this.dataService.setStepDataStored(PROGRESS_STEPS.StepFive.Index, data.body);
            this.helperService.setSessionStorage(STORAGE_KEYS.requestID, data.body?.application?.request_id || '');
            this.helperService.setSessionStorage(STORAGE_KEYS.orderID, data.body.orderID);
            this.helperService.redirectByOptions(ROUTES_PATH.AppInfoSony3D, { skipLocationChange: true });
            return Promise.resolve();
          }
          if (data && data.body && data.body.responseSMBC_3d) {
            // this.navigateToServerUrl(data.body.responseSMBC_3d.redirectUrl);
            new_tab.location.href = data.body.responseSMBC_3d.redirectUrl;
            this.helperService.removeSessionStorageValue(STORAGE_KEYS.IsConfirm);
            this.dataService.setStepDataStored(PROGRESS_STEPS.StepFive.Index, data.body);
            this.helperService.redirectByOptions(ROUTES_PATH.Reserve, { skipLocationChange: true });
            this.helperService.setSessionStorage(STORAGE_KEYS.orderID, data.body.orderID);
            this.helperService.setSessionStorage(STORAGE_KEYS.requestID, data.body?.application?.request_id || '');
            this.helperService.setSessionStorage(STORAGE_KEYS.applicationID, data.body?.application_id || '');
            return Promise.resolve();
          }
          this.helperService.removeSessionStorageValue(STORAGE_KEYS.InfoInputValues);
          this.helperService.removeSessionStorageValue(STORAGE_KEYS.IsConfirm);
          this.dataService.setStepDataStored(PROGRESS_STEPS.StepFive.Index, data.body);
          this.helperService.redirectByOptions(ROUTES_PATH.Reserve, { skipLocationChange: true });
          return Promise.resolve();
        }

        return Promise.reject(new Error(MESSAGES.Base.CommonFailed));
      })
      .catch((error: any = {}) => {
        this.dialogService.open({
          type: DIALOG_MESSAGE_TYPE.Error,
          message: error.message || MESSAGES.Base.CommonFailed,
        });
        if (error?.status === HttpStatusCode.Conflict) {
          const inputStoredData =
            this.helperService.parseJson(this.helperService.getSessionStorageValue(STORAGE_KEYS.InfoInputValues)) || {};
          inputStoredData.isCheckCardError = true;
          this.helperService.setSessionStorage(STORAGE_KEYS.InfoInputValues, JSON.stringify(inputStoredData));
        }

        this.backPreviousStep();
      })
      .then(() => (this.isLoading = false));
  }

  private backToFirstStep() {
    this.dataService.setStepDataStored(PROGRESS_STEPS.StepOne.Index);
    this.helperService.redirect(ROUTES_PATH.Index);
  }
  navigateToServerUrl(url: string) {
    window.open(url, '_blank');
  }
  openWindow() {
    var myWindow = window.open('', 'myWindow');
    return true;
  }
}
