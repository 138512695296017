<div class="dialog-message py-0 my-0">
  <div class="modal-body {{type}}" style="text-align: center">
    <span>
      <fa-icon *ngIf="type === dialogType.Success" [icon]="faCheckCircle" size="3x"></fa-icon>
      <fa-icon *ngIf="type === dialogType.Error" [icon]="faExclamationCircle" size="3x"></fa-icon>
      <fa-icon *ngIf="type === dialogType.Warning" [icon]="faTimes" size="3x"></fa-icon>
      <p class="pb-0 mb-0 mt-3">{{ message }}</p>
    </span>
  </div>
  <div class="modal-footer {{type}}">
    <button type="button" class="btn btn-secondary" (click)="close()">再送信</button>
  </div>
</div>