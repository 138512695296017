import AuthStorage from '@shared/utils/storage-util';
import { DialogServiceService } from '@core/services/dialog-service.service';
import { AuthService } from '@core/services/auth.service';
import { HelperService } from '@core/services/helper.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import { DIALOG_MESSAGE_TYPE, MESSAGES, PAGES_TITLE, RESPONSE_TYPES, ROUTES_PATH } from '@shared/common/constants';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  isLoading: boolean = false;
  isConfirmCode = false;
  cognitoUserInfo: CognitoUser;

  constructor(
    private readonly fb: FormBuilder,
    private readonly helperService: HelperService,
    private readonly authService: AuthService,
    private dialogService: DialogServiceService,
  ) {
    this.helperService.setPageTitle(PAGES_TITLE.Login);
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  ngOnInit(): void {}

  public getLoginFormControls(input): any {
    if (this.loginForm.controls[input].value.length > 0 || this.loginForm.controls[input].touched) {
      return this.loginForm.controls[input].valid ? 'is-valid' : 'is-invalid';
    }
  }

  public onSubmitLogin(): void {
    const { username, password } = this.loginForm.value;
    if (!username || !password) {
      return;
    }

    this.isLoading = true;
    const authenticateDetail: AuthenticationDetails = new AuthenticationDetails({
      Username: username,
      Password: password,
    });

    this.cognitoUserInfo = new CognitoUser({
      Username: username,
      Pool: this.authService.getCognitoUserPool(),
      Storage: new AuthStorage(),
    });

    this.cognitoUserInfo.setAuthenticationFlowType('CUSTOM_AUTH');

    this.cognitoUserInfo.authenticateUser(authenticateDetail, this.callBackCognito(this.cognitoUserInfo, password));
  }

  public callBackCognito(cognitoUserInfo: CognitoUser, password: string) {
    return {
      onSuccess: () => {
        this.isConfirmCode = false;
        this.helperService.adminRedirect(ROUTES_PATH.Index);
        this.isLoading = false;
      },
      onFailure: () => {
        this.dialogService.open({
          type: DIALOG_MESSAGE_TYPE.Error,
          message: RESPONSE_TYPES.NotAuthorizedException ? MESSAGES.Base.LoginFailed : MESSAGES.Base.CommonFailed,
        });
        this.isLoading = false;
      },
      customChallenge: (challengeParameters) => {
        this.isLoading = false;
        this.isConfirmCode = true;
        if (challengeParameters.isConfirmCode === 'false') {
          this.dialogService.open({
            type: DIALOG_MESSAGE_TYPE.Error,
            message: RESPONSE_TYPES.NotAuthorizedException
              ? MESSAGES.Base.ConfirmCodeFailed
              : MESSAGES.Base.CommonFailed,
          });
        }
        if (challengeParameters.isSendMailSuccess === 'false') {
          this.isConfirmCode = false;
          this.dialogService.open({
            type: DIALOG_MESSAGE_TYPE.Error,
            message: RESPONSE_TYPES.NotAuthorizedException ? MESSAGES.Base.LoginFailed : MESSAGES.Base.CommonFailed,
          });
        }
      },
      newPasswordRequired: function () {
        return cognitoUserInfo.completeNewPasswordChallenge(password, [], this);
      },
    };
  }

  public onCodeCompleted(code: string) {
    this.isLoading = true;

    this.cognitoUserInfo.sendCustomChallengeAnswer(
      code,
      this.callBackCognito(this.cognitoUserInfo, this.loginForm.value.password),
    );
  }
}
