import { API_ROUTES, HOUSE_TYPE } from '@shared/common/constants';
import { ApiResponse } from '@shared/common/types';
import { Observable } from 'rxjs';
import { RequestService } from '../request.service';
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class CourseService {
  constructor(private readonly requestService: RequestService) {}

  /**
   * get course by address selected
   * @param {searchParams} include town_area, postal_code, chome, apartment_name, address
   * @param {houseType} to select type of url
   * */
  public getCourseByAddress(houseType: string, searchParams: any): Observable<ApiResponse> {
    const url: string =
      houseType === HOUSE_TYPE.Detached
        ? API_ROUTES.Home.GetDetachedHousesCourses
        : API_ROUTES.Home.GetCollectiveHousesCourses;

    let params = new HttpParams();
    //remove empty properties
    Object.keys(searchParams).forEach((key) => {
      const paramValue: string = searchParams[key];
      this.isValidParamValue(paramValue) && (params = params.set(key, paramValue));
    });

    return this.requestService.get(url, { params });
  }

  private isValidParamValue(value: any): boolean {
    return value || value === '' || value === 0;
  }
}
