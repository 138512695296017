import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { AsYouType, parsePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js';
@Directive({
  selector: '[appPhoneMask]',
})
export class PhoneMaskDirective {
  @Input() countryCode: string;
  constructor(public ngControl: NgControl) {}

  @HostListener('ngModelChange', ['$event'])
  public onModelChange(event) {
    this.onInputChange(event, false);
  }

  @HostListener('keydown.backspace', ['$event'])
  public keydownBackspace(event) {
    this.onInputChange(event.target.value, true);
  }

  private onInputChange(event, backspace) {
    const NUMBER_MAX_LENGTH = 11;
    const asYouType: AsYouType = this.getFormatter();
    if (!asYouType) {
      return;
    }

    let newVal = event.replace(/\D/g, '');
    newVal.length > NUMBER_MAX_LENGTH && (newVal = newVal.slice(0, NUMBER_MAX_LENGTH));
    if (backspace && newVal.length <= 6) {
      newVal = newVal.substring(0, newVal.length - 1);
    }
    newVal.length >= 10 && (newVal = parsePhoneNumber(newVal, asYouType.country).formatNational());
    if (!isValidPhoneNumber(newVal, asYouType.country)) {
      this.ngControl.control.setErrors({ wrongFormat: true });
    }

    newVal != this.ngControl.control.value && this.ngControl.control.patchValue(newVal);
  }

  private getFormatter(): AsYouType {
    switch (this.countryCode) {
      case 'us':
        return new AsYouType('US');
      case 'jp':
        return new AsYouType('JP');
    }

    return null;
  }
}
