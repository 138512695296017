import { config } from 'src/config/app.config';
import { HeaderFooterContents } from '@shared/common/types';
import { PAGES_TITLE, ROUTES_PATH, PROGRESS_STEPS, STORAGE_KEYS, VERSION_TEXT } from '@shared/common/constants';
import { HelperService } from '@core/services/helper.service';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DataService } from '@core/services/data.service';
import { ActivatedRoute } from '@angular/router';
declare let $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  stepNumber: number;
  requestId: string;
  headerContent: string;
  footerContent: string;
  webappVersion: string = config.APP_VERSION;

  constructor(
    private dataService: DataService,
    private readonly helperService: HelperService,
    private route: ActivatedRoute,
    private ref: ChangeDetectorRef,
  ) {
    if (!this.dataService.isValidProgressDataStored()) {
      this.dataService.removeProgressData();
      window.location.reload();
      return;
    }
    // Set page title
    this.helperService.setPageTitle(PAGES_TITLE.Home);

    this.requestId = this.route.snapshot.queryParamMap.get('request_id');
    this.requestId ? this.goSettingConstruction() : this.goCurrentSessionPage();

    this.getHeaderFooterContent();
  }

  ngOnInit(): void {
    this.ref.detectChanges();
    HomeComponent.setContentPadding();
  }

  goCurrentSessionPage(): void {
    const progressDataStoredStr: string = this.helperService.getSessionStorageValue(STORAGE_KEYS.DataStoredSteps);
    this.stepNumber =
      (this.helperService.parseJson(progressDataStoredStr) || {}).activeStepNumber || PROGRESS_STEPS.StepOne.Index;
    this.dataService.getStepNumber().subscribe((step: number = this.stepNumber) => (this.stepNumber = step));
  }

  goSettingConstruction(): void {
    this.stepNumber = 5;
    this.helperService.redirectByOptions(ROUTES_PATH.SettingConstruction, {
      skipLocationChange: true,
      queryParams: { request_id: this.requestId },
    });
  }

  private getHeaderFooterContent(): void {
    this.headerContent = this.helperService.getSessionStorageValue(STORAGE_KEYS.HeaderContent);
    this.footerContent = this.helperService.getSessionStorageValue(STORAGE_KEYS.FooterContent);
    this.footerContent = this.footerContent.replace(VERSION_TEXT, this.webappVersion);
    this.dataService.getHeaderFooterContent().subscribe((data: HeaderFooterContents): void => {
      this.headerContent = data?.headerContent;
      this.footerContent = data?.footerContent;
      this.footerContent = this.footerContent.replace(VERSION_TEXT, this.webappVersion);
      this.ref.detectChanges();
      HomeComponent.setContentPadding();
    });
  }

  /**
   * Set content padding bottom base on height of footer
   */
  private static setContentPadding(): any {
    const footerElement: any = $('.page__footer.footer');
    if (footerElement) {
      const height = footerElement.height();
      $('.content.body__content').css('padding-bottom', `${height}px`);
    }
  }
}
