import { ROUTES_PATH, ROUTER_PATH_MATCH } from './shared/common/constants';
import { ForbiddenComponent } from './shared/pages/forbidden/forbidden.component';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './shared/pages/not-found/not-found.component';
import { ErrorComponent } from '@shared/pages/error/error.component';
import { LoginComponent } from '@shared/pages/login/login.component';
import { MustBeGuestGuard } from './core/guards/must-be-guest.guard';

export const routes: Routes = [
  {
    path: ROUTES_PATH.Admin,
    loadChildren: () => import('./admin/admin.module').then((module) => module.AdminModule),
  },
  { path: ROUTES_PATH.Error, component: ErrorComponent },
  { path: ROUTES_PATH.ErrorAdmin, component: ErrorComponent },
  { path: ROUTES_PATH.Forbidden, component: ForbiddenComponent },
  { path: ROUTES_PATH.NotFound, component: NotFoundComponent },
  { path: ROUTES_PATH.Login, component: LoginComponent, canActivate: [MustBeGuestGuard] },
  { path: ROUTES_PATH.InvalidRouter, pathMatch: ROUTER_PATH_MATCH.Full, redirectTo: ROUTES_PATH.NotFound },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false, preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
