<app-loading *ngIf="isLoading"></app-loading>
<app-smbc-payment id="paymentSection" *ngIf="company?.credit_code === PAYMENT_TYPES.SMBC||company?.credit_code === PAYMENT_TYPES.SMBC_3D" [hidden]="!isOpenSmbcPayment" (hideModal)="setSMBCPaymentButton()"></app-smbc-payment>
<app-candy-w-payment
  *ngIf="company?.credit_code === PAYMENT_TYPES.CANDY_W && isCourseSupportCreditCard && paymentMethodValue === paymentMethodConst.CREDIT_CARD"
  [settlementAgency]="company?.settlementAgency"
  [member_id]="member_id"
  [payment_url]="company?.payment_url"
  [company_code]="company?.code"
></app-candy-w-payment>
<div class="container-fluid shadow">
  <div class="card">
    <div class="card-header">
      お申し込み情報の入力
    </div>
    <div class="card-body section py-4 mt-1">
      <h6 class="heading">お申し込み者情報を入力してください</h6>
      <form [formGroup]="appInfoInputForm" class="mt-4">
        <label class="col pl-0 c-fw-6 label-font-size-22 text-cloud label-surname-margin-top"for="surname">氏名 <span  class="badge-custom c-ff-h3  badge-danger">必須</span></label>
        <div class="form-row">
          <div class="form-group d-flex flex-column col-md-6 col-lg-4">
            <input
              [ngClass]="{'is-invalid': appInfoFormControls.last_name.value && appInfoFormControls.last_name.errors}"
              type="text"
              class="form-control custom-input c-ff-h3"
              autocomplete="new-last_name"
              (change)="enableCandyWPaymentCardButton($event.target)"
              (blur)="onInputBlur()"
              (focus)="onFocus($event)"
              (input)="isFullWidth('last_name',$event);"
              id="surname" maxlength="10" placeholder="姓（全角10文字以内）" formControlName="last_name" required>
          </div>
          <div class="form-group d-flex flex-column col-md-6 col-lg-4">
            <input
              [ngClass]="{'is-invalid': appInfoFormControls.first_name.value && appInfoFormControls.first_name.errors}"
              type="text"
              autocomplete="new-first_name"
              class="form-control custom-input c-ff-h3"
              (blur)="onInputBlur()"
              (input)="isFullWidth('first_name',$event);"
              (focus)="onFocus($event)"
              id="name" maxlength="10" placeholder="名（全角10文字以内）" formControlName="first_name" required>
          </div>
        </div>
        <span id="emailHelp1" class="form-text c-ff-h3  mt-n1">姓名それぞれ<span class="text-danger">全角10文字以内</span>で入力してください。</span>
        <span class="form-text mb-4  c-ff-h3 ">例：山田　太郎</span>
        <label class="col pl-0 font-weight-bold label-font-size-22 text-cloud mt-1">氏名（フリガナ）<span class="badge-custom c-ff-h3  badge-danger">必須</span></label>
        <div class="form-row">
          <div class="form-group d-flex flex-column col-md-6 col-lg-4">
            <input
              [ngClass]="{'is-invalid': appInfoFormControls.last_name_furigana.value && appInfoFormControls.last_name_furigana.errors}"
              type="text"
              autocomplete="new-last_name_furigana"
              class="form-control custom-input c-ff-h3 "
              (input)="isKatakana('last_name_furigana',$event)"
              id="surname-katakana" placeholder="カタカナ姓" maxlength="10" formControlName="last_name_furigana" required>
          </div>
          <div class="form-group d-flex flex-column col-md-6 col-lg-4">
            <input
              [ngClass]="{'is-invalid': appInfoFormControls.first_name_furigana.value && appInfoFormControls.first_name_furigana.errors}"
              type="text"
              autocomplete="new-first_name_furigana"
              class="form-control custom-input c-ff-h3 "
              (input)="isKatakana('first_name_furigana',$event)"
              id="name-katakana" placeholder="カタカナ名" maxlength="10" formControlName="first_name_furigana" required>
          </div>
        </div>
        <span id="emailHelp2" class="form-text c-ff-h3 ">姓名それぞれ<span class="text-danger">全角10文字以内</span>のカタカナで入力してください。</span>
        <span id="emailHelp2" class="form-text  mb-4 c-ff-h3 ">例：ヤマダ　タロウ</span>

        <!-- add logic -->
        <label class="col pl-0 font-weight-bold label-font-size-22 text-cloud mt-1">氏名（ローマ字） <span class="badge-custom c-ff-h3  badge-danger">必須</span></label>
        <div class="form-row">
          <div class="form-group d-flex flex-column col-md-6 col-lg-4">
            <input
              [ngClass]="{'is-invalid': appInfoFormControls.last_name_romaji?.value && appInfoFormControls.last_name_romaji?.errors}"
              type="text"
              autocomplete="new-last_name_romaji"
              class="form-control custom-input c-ff-h3 "
              (input)="isRomaji('last_name_romaji')"
              id="last-romaji" placeholder="ローマ字名" maxlength="30" formControlName="last_name_romaji" required>
          </div>
          <div class="form-group d-flex flex-column col-md-6 col-lg-4">
            <input
              [ngClass]="{'is-invalid': appInfoFormControls.first_name_romaji?.value && appInfoFormControls.first_name_romaji?.errors}"
              type="text"
              autocomplete="new-first_name_romaji"
              class="form-control custom-input c-ff-h3 "
              (input)="isRomaji('first_name_romaji')"
              id="fist-romaji" placeholder="ローマ字姓" maxlength="30" formControlName="first_name_romaji" required>
          </div>
        </div>
        <span id="" class="form-text c-ff-h3 ">姓名それぞれ<span class="text-danger">半角ローマ字</span>で入力してください。(クレジットカード申込の方は、カード名義のローマ字を入力してください)</span>
        <span id="" class="form-text c-ff-h3 ">例：TARO　YAMADA</span>
        <!-- End logic -->
        <div class="form-row">
          <div class="form-group d-flex flex-column col-md-6">
            <div class="d-flex flex-column mt-4 mb-2">
              <span class="c-fw-6 label-font-size-22 text-cloud">設置住所</span>
              <span class="c-fs-18 c-fw-6 text-cloud">郵便番号</span>
              <span class="c-fs-16  c-ff-h3 ">〒{{establishPostalCode}}</span>
              <span class="c-fs-18 c-fw-6 text-cloud mt-2">都道府県市区町村</span>
              <span class="c-fs-16  c-ff-h3 mb-n2">{{stateOrCity}}</span>
            </div>
          </div>
        </div>
        <label class="mt-2 c-ff-h3 c-fw-6 c-fs-18 text-cloud">番地 号<span class="badge-custom badge-secondary c-ff-h3 span-font-size-15 ml-1">任意</span></label>
        <div class="form-row enterAddress">
          <div class="form-group select-time-group d-flex col-12 col-md-6 col-lg-5">
            <div>
              <input
              type="text"
              autocomplete="new-establish_address"
              placeholder="番地（半角数字）"
              [ngClass]="{'is-invalid': appInfoFormControls.establish_address.errors}"
              class="form-control custom-input c-ff-h3"
              id="establish_address" formControlName="establish_address" maxlength="5" value="">
            </div>
            <span class="mt-2 c-ff-h3">番地</span>
          </div>
          <div class="form-group select-time-group d-flex col-12 col-md-6 col-lg-5">
            <div>
              <input
              type="text"
              autocomplete="new-establish_address_number"
              placeholder="号（半角数字）"
              [ngClass]="{'is-invalid': appInfoFormControls.establish_address_number.value && appInfoFormControls.establish_address_number.errors}"
              class="form-control custom-input c-ff-h3"
              id="establish_address_number" formControlName="establish_address_number" value="">
            </div>
            <span class="mt-2 c-ff-h3">号</span>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group d-flex flex-column col-md-12">
            <label class="col pl-0 c-fw-6 c-fs-18 text-cloud mt-n1" for="establish_apartment_name" >建物名<span *ngIf="isReadonlyAndRequired"  class="badge-custom c-ff-h3  badge-danger span-font-size-15 ml-1">必須</span><span *ngIf="!isReadonlyAndRequired" class="badge-custom badge-secondary c-ff-h3 span-font-size-15 ml-1">任意</span></label>
            <input
              [ngClass]="{'is-invalid': appInfoFormControls.establish_apartment_name.errors}"
              type="text"
              class="form-control custom-input c-ff-h3"
              id="establish_apartment_name" formControlName="establish_apartment_name" value="" [required]="isReadonlyAndRequired" [readonly]="isReadonlyAndRequired && !isSelectedOtherBuilding">
          </div>
        </div>

        <div class="form-row mt-n1">
          <div class="form-group d-flex flex-column col-md-3">
            <label class="col pl-0 c-fw-6 c-fs-18 text-cloud " for="room-number1" >部屋番号<span *ngIf="isReadonlyAndRequired"  class="badge-custom c-ff-h3  badge-danger span-font-size-15 ml-1">必須</span><span *ngIf="!isReadonlyAndRequired" class="badge-custom badge-secondary c-ff-h3 span-font-size-15 ml-1">任意</span></label>
            <input
              type="text"
              placeholder="全半角10桁以内"
              autocomplete="off"
              [ngClass]="{'is-invalid': appInfoFormControls.establish_room_number.value && appInfoFormControls.establish_room_number.errors}"
              class="form-control custom-input c-ff-h3 mt-n1 min-width-room-number"
              id="establish_room_number" formControlName="establish_room_number" maxlength="10" [required]="isReadonlyAndRequired">
            <span class="form-text  c-fs-16 c-ff-h3 ">例：101号室</span>
          </div>
        </div>
        <div class="form-row mt-n1 mb-1">
          <div class="form-group d-flex ml-1">
            <div class="wrapper-checkbox mr-2 position-relative">
              <input type="checkbox" [checked]="isCheckedContract" id="checkTerms" name="check" (change)="onCheckBoxChange($event)"/>
              <label for="checkTerms"></label>
            </div>
            <label for="checkTerms" class="c-fw-6 mt-n1 c-fs-16">設置住所とご契約者住所が異なる場合はチェックを入れてください</label>
          </div>
        </div>
        <div class="form-row">
          <div class="d-flex flex-column">
            <label *ngIf="isDisplay" class="c-fw-6 mt-1 label-font-size-22 text-cloud ml-1">ご契約者住所</label>
            <label  *ngIf="isDisplay" class="col pl-0 c-fw-6 label-font-size-18 text-cloud ml-1" for="postal-code" >郵便番号<span class="badge-custom badge-danger c-ff-h3 span-font-size-15 ml-1">必須</span></label>
          </div>
        </div>
        <div class="form-row" *ngIf="isDisplay">
          <div class="form-group d-flex flex-column col-sm-6 col-md-3 mb-0 mr-2">
            <input
              [ngClass]="{'is-invalid': appInfoFormControls.contract_postal_code.value && appInfoFormControls.contract_postal_code.errors}"
              type="text"
              autocomplete="new-contract_postal_code"
              class="form-control custom-input c-ff-h3"
              maxlength="7"
              placeholder="1234567"
              (keypress)="validateEnterNumberValue($event)"
              id="postal-code" placeholder="〒" formControlName="contract_postal_code" required>
            <span class="form-text mt-2 c-ff-h3">例：1230001</span>
          </div>
          <button type="button"
            class="btn btn-shadow btn-black btn-cw-170 btn-ch-45"
            [disabled]="appInfoFormControls.contract_postal_code.invalid" (click)="getContractDataByPostalCode(appInfoFormControls.contract_postal_code.value)">
            <span class="c-ff-h3 ">
              <fa-icon [icon]="faChevronRight"></fa-icon> 住所を検索する
            </span>
          </button>
        </div>
        <div class="form-row mt-2"  *ngIf="isDisplay">
          <div class="form-group d-flex flex-column col-md-12">
            <label class="col pl-0 c-fw-6 c-fs-18 text-cloud mt-n1" for="contract_prefectures" >都道府県<span  class="badge-custom badge-danger c-ff-h3 span-font-size-15 ml-1">必須</span></label>
            <input
              type="text"
              autocomplete="new-contract_prefectures"
              placeholder="都道府県"
              class="form-control custom-input c-ff-h3"
              id="contract_prefectures" formControlName="contract_prefectures" value="" required>
          </div>
        </div>
        <div class="form-row mt-2"  *ngIf="isDisplay">
          <div class="form-group d-flex flex-column col-md-12">
            <label class="col pl-0 c-fw-6 c-fs-18 text-cloud mt-n1" for="contract_municipalities" >市区部<span  class="badge-custom badge-danger c-ff-h3 span-font-size-15 ml-1">必須</span></label>
            <input
              type="text"
              autocomplete="new-contract_municipalities"
              placeholder="市区部"
              class="form-control custom-input c-ff-h3"
              id="contract_municipalities" formControlName="contract_municipalities" value="" required>
          </div>
        </div>
        <div class="form-row mt-2"  *ngIf="isDisplay">
          <div class="form-group d-flex flex-column col-md-12">
            <label class="col pl-0 c-fw-6 c-fs-18 text-cloud mt-n1" for="contract_town_area" >町名<span  class="badge-custom badge-secondary c-ff-h3 span-font-size-15 ml-1">任意</span></label>
            <input
              type="text"
              autocomplete="new-contract_town_area"
              placeholder="町名"
              class="form-control custom-input c-ff-h3"
              id="contract_town_area" formControlName="contract_town_area" value="">
          </div>
        </div>
        <div class="form-row mt-2" *ngIf="isDisplay">
          <div class="form-group d-flex flex-column col-md-12">
            <label class="col pl-0 c-fw-6 c-fs-18 text-cloud mt-n1" for="contract_chome" >丁目<span  class="badge-custom badge-secondary c-ff-h3 span-font-size-15 ml-1">任意</span></label>
            <input
              type="text"
              maxlength="64"
              autocomplete="new-contract_chome"
              placeholder="丁目"
              class="form-control custom-input c-ff-h3"
              id="contract_apartment_name" formControlName="contract_chome" value="">
          </div>
        </div>
        <label *ngIf="isDisplay" class="mt-2 c-ff-h3 c-fw-6 c-fs-18 text-cloud">番地 号<span class="badge-custom badge-secondary c-ff-h3 span-font-size-15 ml-1">任意</span></label>
        <div class="form-row enterAddress" *ngIf="isDisplay">
          <div class="form-group select-time-group d-flex col-12 col-md-6 col-lg-5">
            <div>
              <input
              type="text"
              placeholder="番地（半角数字）"
              autocomplete="new-contract_address"
              [ngClass]="{'is-invalid': appInfoFormControls.contract_address.errors}"
              class="form-control custom-input c-ff-h3"
              id="contract_address" formControlName="contract_address" maxlength="5" value="">
            </div>
            <span class="mt-2 c-ff-h3">番地</span>
          </div>
          <div class="form-group select-time-group d-flex col-12 col-md-6 col-lg-5">
            <div>
              <input
              type="text"
              autocomplete="new-contract_address_number"
              placeholder="号（半角数字）"
              [ngClass]="{'is-invalid': appInfoFormControls.contract_address_number.value && appInfoFormControls.contract_address_number.errors}"
              class="form-control custom-input c-ff-h3"
              id="contract_address_number" formControlName="contract_address_number" value="">
            </div>
            <span class="mt-2 c-ff-h3">号</span>
          </div>
        </div>
        <div class="form-row" *ngIf="isDisplay">
          <div class="form-group d-flex flex-column col-md-12">
            <label class="col pl-0 c-fw-6 c-fs-18 text-cloud mt-n1" for="contract_apartment_name" >建物名<span  class="badge-custom badge-secondary c-ff-h3 span-font-size-15 ml-1">任意</span></label>
            <input
              type="text"
              maxlength="100"
              autocomplete="new-contract_apartment_name"
              class="form-control custom-input c-ff-h3"
              id="contract_apartment_name" formControlName="contract_apartment_name" value="">
          </div>
        </div>
        <div class="form-row" *ngIf="isDisplay">
          <div class="form-group d-flex flex-column col-md-3 col-lg-3 mt-n2">
            <label class="col pl-0 c-fw-6 label-font-size-18 text-cloud" for="contract_room_number" >部屋番号<span  class="badge-custom badge-secondary c-ff-h3 span-font-size-15 ml-1">任意</span></label>
            <input
              type="text"
              placeholder="全半角10桁以内"
              autocomplete="off"
              [ngClass]="{'is-invalid': appInfoFormControls.contract_room_number.value && appInfoFormControls.contract_room_number.errors}"
              class="form-control custom-input c-ff-h3 min-width-room-number"
              id="contract_room_number" maxlength="10" formControlName="contract_room_number">
            <span class="form-text c-ff-h3 ">例：101号室</span>
          </div>
        </div>
        <label class="col pl-0 c-fw-6 label-font-size-22 text-cloud mt-3">生年月日<span  class="badge-custom badge-danger c-ff-h3 span-font-size-15 ml-1">必須</span></label>
        <div class="form-row">
          <div class="form-group select-time-group d-flex col-12 col-sm-6 col-md-4 col-lg-3">
            <div dropdown [autoClose]="true" #dropdownYear="bs-dropdown">
              <button id="dropdownYear" dropdownToggle type="button" class="btn btn-block dropdown-toggle d-flex text-selected c-ff-h3 c-fs-16 my-0 cw-btn-dropdown-190"
                [ngClass]="selectedYear ? 'text-shadow': 'text-gray'"
                aria-controls="dropdown-autoclose1" (click)="onShown($event, dropdownYear);">
                <span class="d-flex text-selected justify-content-between w-100 ">
                  <span class="d-inline-block text-shadow" >
                    {{ selectedYear ? selectedYear : '選択してください' }}
                  </span>
                  <img class="dropdown-arrow" src="{{'../../../assets/img/icon-pulldown.png'}}">
                </span>
              </button>
              <ul id="dropdownYear" *dropdownMenu class="dropdown-menu py-0"
                  role="menu" aria-labelledby="button-autoclose1">
                <li *ngFor ="let item of yearsList" role="menuitem">
                  <div class="dropdown-item  py-2" (click)="getSelectedYear(item)">
                    <div class="c-ff-h3 c-fs-16 item">{{ item }}</div>
                  </div>
                </li>
              </ul>
            </div>
            <span class="mt-2 c-ff-h3">年</span>
          </div>
          <div class="form-group select-time-group d-flex col-12 col-sm-6 col-md-4 col-lg-3">
            <div dropdown [isDisabled]="isDisabledMonth" [autoClose]="true" #dropdownMonth="bs-dropdown">
            <button id="dropdownMonth" dropdownToggle type="button" class="btn btn-block dropdown-toggle d-flex  text-selected c-ff-h3 c-fs-16 my-0 cw-btn-dropdown-190"
                [ngClass]="selectedMonth ? 'text-shadow': 'text-gray'"
                [ngClass]="selectedYear && !selectedMonth ? 'empty-value-warn' : ''"
                aria-controls="dropdown-autoclose1" (click)="onShown($event, dropdownMonth);">
                <span class="d-flex text-selected justify-content-between w-100 ">
                  <span class="d-inline-block text-shadow" >
                    {{ selectedMonth ? selectedMonth : '選択してください' }}
                  </span>
                  <img class="dropdown-arrow" src="{{isDisabledMonth === true ?'../../../assets/img/icon-pulldown-inactive.png' : '../../../assets/img/icon-pulldown.png'}}">
                </span>
              </button>
              <ul id="dropdownMonth" *dropdownMenu class="dropdown-menu py-0"
                  role="menu" aria-labelledby="button-autoclose1">
                <li *ngFor ="let item of monthsList" role="menuitem">
                  <div class="dropdown-item  py-2" (click)="getDaysOfMonth(selectedYear, item)">
                    <div class="c-ff-h3 c-fs-16 item">{{ item }}</div>
                  </div>
                </li>
              </ul>
            </div>
            <span class="mt-2 c-ff-h3">月</span>
          </div>
          <div class="form-group select-time-group d-flex col-12 col-sm-6 col-md-4 col-lg-3">
            <div dropdown  [isDisabled]="isDisabledDay" [autoClose]="true" #dropdownDay="bs-dropdown">
              <button id="dropdownDay" dropdownToggle type="button" class="btn btn-block dropdown-toggle d-flex text-selected c-ff-h3 c-fs-16 my-0 cw-btn-dropdown-190"
                  [ngClass]="selectedDay ? 'text-shadow': 'text-gray'"
                  [ngClass]="selectedMonth && !selectedDay ? 'empty-value-warn' : ''"

                  aria-controls="dropdown-autoclose1" (click)="onShown($event, dropdownDay);">
                  <span class="d-flex text-selected justify-content-between w-100 ">
                    <span class="d-inline-block text-shadow" >
                      {{ selectedDay ? selectedDay : '選択してください' }}
                    </span>
                    <img class="dropdown-arrow" src="{{isDisabledDay === true ?'../../../assets/img/icon-pulldown-inactive.png' : '../../../assets/img/icon-pulldown.png'}}">
                  </span>
                </button>
                <ul id="dropdownDay" *dropdownMenu class="dropdown-menu py-0"
                    role="menu" aria-labelledby="button-autoclose1">
                  <li *ngFor ="let item of daysList" role="menuitem">
                    <div class="dropdown-item  py-2" (click)="getSelectedDay(item)">
                      <div class="c-ff-h3 c-fs-16 item">{{ item }}</div>
                    </div>
                  </li>
                </ul>
              </div>
              <span class="mt-2 c-ff-h3">日</span>
          </div>
          <span *ngIf="birthdayErrorMsg" class="c-ff-h3 text-danger ml-1 birthdayInvalid">{{ birthdayErrorMsg }}</span>
        </div>
        <label class="col pl-0 c-fw-6 label-font-size-22 text-cloud mt-3">性別<span  class="badge-custom badge-secondary c-ff-h3  span-font-size-15 ml-1">任意</span></label>
        <div class="form-row mb-2">
          <div class="form-group col-md-12">
            <input id="radio1" type="radio" value="2" name="gender" formControlName="gender" checked>
            <label class="c-ff-h3 " for="radio1">男性</label>
            <input id="radio2" class="ml-4" type="radio" value="1" name="gender" formControlName="gender">
            <label class="c-ff-h3 " for="radio2">女性</label>
            <input id="radio3" class="ml-4" type="radio" value="0" name="gender" formControlName="gender">
            <label class="c-ff-h3 " for="radio3">未選択</label>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group d-flex flex-column col-md-6 col-sm-12 mt-n1">
            <label class="col pl-0 c-fw-6 text-cloud label-font-size-22" for="landline">電話番号<span  class="badge-custom badge-danger c-ff-h3  span-font-size-15 ml-1">必須</span></label>
            <input
              [ngClass]="{'is-invalid': appInfoFormControls.telephone_number.value && appInfoFormControls.telephone_number.errors}"
              type="text"
              autocomplete="new-telephone_number"
              maxlength="13"
              appPhoneMask
              [countryCode]="countryCode"
              class="form-control custom-input mt-1 c-ff-h3"
              id="landline" formControlName="telephone_number" required>
            <span class="form-text  mt-2 c-ff-h3 ">ハイフンは自動入力されます。</span>
            <span class="form-text  mt-2 c-ff-h3 ">必要時にご連絡いたします。</span>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group d-flex flex-column col-md-6 col-sm-12">
            <label class="col pl-0 c-fw-6 text-cloud label-font-size-22 mt-3" for="phone-number">携帯電話番号<span  class="badge-custom badge-secondary c-ff-h3  span-font-size-15 ml-1">任意</span></label>
            <input
              [ngClass]="{'is-invalid': appInfoFormControls.mobile_telephone_number.value && appInfoFormControls.mobile_telephone_number.errors}"
              type="text"
              autocomplete="new-mobile_telephone_number"
              class="form-control custom-input c-ff-h3 mt-1"
              appPhoneMask
              [countryCode]="countryCode"
              maxlength="13"
              id="phone-number" formControlName="mobile_telephone_number">
            <span class="form-text  mt-2 c-ff-h3 ">ハイフンは自動入力されます。</span>
            <span class="form-text mt-2 c-ff-h3">必要時にご連絡いたします。</span>
          </div>
        </div>

        <div class="form-row mt-4">
          <div class="form-group d-flex flex-column col-md-6 col-sm-12">
            <label class="col pl-0 c-fw-6 text-cloud label-font-size-22 mb-1 mt-n1">メールアドレス<span  class="badge-custom badge-danger c-ff-h3  span-font-size-15 ml-1">必須</span></label>
            <input
              [ngClass]="{'is-invalid': appInfoFormControls.email_address.value && appInfoFormControls.email_address.errors}"
              type="email"
              autocomplete="new-email_address"
              class="form-control custom-input c-ff-h3"
              (change)="mustMatch()"
              id="email-address" placeholder="メールアドレスの入力" formControlName="email_address" required>
            <span class="form-text c-ff-h3">半角英数字、記号でご入力ください。</span>
            <span class="form-text c-ff-h3"> 例：yamada@example.com</span>
            <input
              [ngClass]="{'is-invalid': appInfoFormControls.email_address_confirmation.value && appInfoFormControls.email_address_confirmation.errors && appInfoFormControls.email_address_confirmation.errors.mustMatch}"
              type="email"
              autocomplete="new-email_address_confirmation"
              class="form-control mt-2 custom-input c-ff-h3"
              (change)="mustMatch()"
              id="email-address-again" placeholder="メールアドレスの再入力" formControlName="email_address_confirmation" required>
              <span class="form-text c-ff-h3">確認の為、再入力してください。</span>
          </div>
        </div>

        <div *ngIf="shouldPaymentShow" class="form-row">
          <div class="form-group col-md-12 pb-0 mb-0 mt-1">
            <label class="col pl-0 c-fw-6 text-cloud label-font-size-22 mt-2" for="apartment-wholesalers">支払方法<span  class="badge-custom badge-danger c-ff-h3 span-font-size-15 ml-1">必須</span></label>
            <div class="card-info six columns" [ngClass]="{'active': (paymentMethodValue === paymentMethodConst.CREDIT_CARD || paymentMethodValue === paymentMethodConst.NOTHING) && hiddenCardNumber && cardToken}">
              <label id="card_number_label">カード番号</label><br>
              <span id="card_number_confirm">{{hiddenCardNumberProp}}</span>
              <input type="hidden" id="card_number" #hiddenCardNumber/>
              <input type="hidden" id="token" #hiddenToken/>
            </div>

            <div *ngIf="paymentMethodValue !== paymentMethodConst.NONE && paymentMethodValue !== paymentMethodConst.NOTHING" #select2Method class="pl-0 form-group col-md-12">
              <div class="form-group-radio">
                <div *ngFor="let item of paymentMethod;" (click)="selectPaymentMethod(item, courseChoose[item])" style="display: flex; min-width: 120px;">
                  <input id="paymentMethod{{item}}" type="radio" value="{{item}}" name="paymentMethod" [checked]="paymentMethodValue === item" style="margin-right: 0.25rem;">
                  <label class="c-ff-h3" for="paymentMethod{{item}}">{{ creditNames[item]?.value || courseChoose[item] }}</label>
                </div>
              </div>
            </div>
            <div *ngIf="paymentMethodValue !== paymentMethodConst.EMPTY">
              <div
                *ngIf="paymentMethodValue === paymentMethodConst.NONE || paymentMethodValue === paymentMethodConst.CREDIT_CARD || paymentMethodValue === paymentMethodConst.NOTHING">
                <button class="btn btn-black btn-cw-170 btn-ch-50"
                  [ngClass]="{'card-added': (paymentMethodValue === paymentMethodConst.CREDIT_CARD || paymentMethodValue === paymentMethodConst.NOTHING) && hiddenCardNumberProp !== '' }"
                  id="cardButton"
                  [attr.data-payment-url]="paymentUrl"
                  onclick="recheckSonyScript(this);"
                  *ngIf="company?.credit_code === PAYMENT_TYPES.SONY || company?.credit_code === PAYMENT_TYPES.SONY_3D">
                  <span class="c-ff-h3">カード情報の登録</span>
                </button>
                <button class="btn btn-black btn-cw-170 btn-ch-50"
                  [ngClass]="{'card-added': (paymentMethodValue === paymentMethodConst.CREDIT_CARD || paymentMethodValue === paymentMethodConst.NOTHING) && hiddenCardNumberProp !== '' }"
                  id="cardButton"
                  (click)="setSMBCPaymentButton(true)"
                  *ngIf="company?.credit_code === PAYMENT_TYPES.SMBC||company?.credit_code === PAYMENT_TYPES.SMBC_3D">
                  <span class="c-ff-h3">カード情報の登録</span>
                </button>
                <button class="btn btn-black btn-cw-170 btn-ch-50"
                  [disabled]="!isEnableCandyWCardButton"
                  [ngClass]="{'card-added': (paymentMethodValue === paymentMethodConst.CREDIT_CARD || paymentMethodValue === paymentMethodConst.NOTHING) && candyWCardAdded }"
                  id="cardButton"
                  (click)="this.saveInputValuesToSessionStorage(); this.candyWPaymentForm.submitForm({last_name: appInfoFormControls.last_name.value});"
                  *ngIf="company?.credit_code === PAYMENT_TYPES.CANDY_W">
                  <span class="c-ff-h3">{{candyWCardAdded ? "カード情報入力済" : "カード情報の登録" }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="paymentMethodValue !== paymentMethodConst.EMPTY && paymentMethodValue !== paymentMethodConst.NOTHING" class="form-row box-payment-infomations">
          <div
          class="payment-method-explanatory"
          *ngIf="selectedPaymentMethodExplanatory.explanatory !== '' || selectedPaymentMethodExplanatory.explanatory_url !== ''"
          >
            <p
              class="explanatory-text mb-0 pre-white-space"
              *ngIf="selectedPaymentMethodExplanatory.explanatory !== ''"
            >{{ selectedPaymentMethodExplanatory.explanatory }}
            </p>
            <a
              class="explanatory-url"
              href="{{ selectedPaymentMethodExplanatory.explanatory_url }}"
              target="_blank"
              *ngIf="selectedPaymentMethodExplanatory.explanatory_url !== ''"
            >
              {{ selectedPaymentMethodExplanatory.explanatory_url }}
            </a>
          </div>
        </div>
        <div class="form-row" *ngIf="remark_title !== ''">
          <div class="form-group d-flex flex-column col-md-12 col-sm-12">
            <label  class="col pl-0 c-fw-6 text-cloud mt-6" for="remarks">{{remark_title}}</label>
            <textarea
              formControlName="remarks"
              placeholder="{{remark_annotation}}"
              id="remarks"
              class="form-control custom-textarea c-ff-h3 remove-placeholder-on-focus"
            ></textarea>
          </div>
        </div>
        <div class="footer row align-items-center mt-4">
          <div class="col-sm-4 d-flex">
            <button class="btn btn-white btn-cw-150 btn-ch-44" (click)="backPreviousStep();">
              <span class="btn-text c-ff-h3 "><fa-icon [icon]="faChevronLeft" size="sm"></fa-icon> 一つ前へ戻る</span>
            </button>
          </div>
          <div class="col-sm-6 col-md-4 justify-content-center d-flex my-sm-4">
            <button class="btn btn-lime btn-cw-260 btn-ch-70" [disabled]="!allowNextStep || isCheckCardError" (click)="clickToConfirmPage();">
              <span class="btn-text"><fa-icon [icon]="faChevronRight"></fa-icon> 入力情報の確認</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
