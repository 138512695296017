import { HelperService } from '@core/services/helper.service';
import { ROUTES_PATH, STORAGE_KEYS } from '@shared/common/constants';
import { DataService } from '@core/services/data.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reserve',
  templateUrl: './reserve.component.html',
  styleUrls: ['./reserve.component.scss'],
})
export class ReserveComponent implements OnInit {
  requestId: string = null;
  buttonName: string = '';
  homeUrl: string = '';
  constructor(private readonly dataService: DataService, private readonly helperService: HelperService) {}

  async ngOnInit(): Promise<void> {
    this.getDataStored();
    this.clearData();
  }

  getDataStored(): void {
    const {
      data: {
        infoInput: {
          value: {
            application: { request_id },
            company: { button_name, home_url },
          },
        },
      },
    } = this.dataService.getDataStored();
    !request_id && this.helperService.redirect(ROUTES_PATH.Error);
    this.requestId = request_id;
    if (button_name && home_url) {
      this.buttonName = button_name;
      this.homeUrl = home_url;
    }
  }

  clearData(): void {
    this.dataService.clearData();
    this.helperService.removeSessionStorageValue(STORAGE_KEYS.DataStoredSteps);
  }

  goToHomeUrl(): void {
    window.location.href = this.homeUrl;
  }
}
