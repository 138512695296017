import { HelperService } from '@core/services/helper.service';
import { ROUTES_PATH } from '@shared/common/constants';
import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DirectAccessGuard implements CanActivate {
  constructor(private readonly helperService: HelperService) {}

  canActivate(): Observable<boolean> {
    const activeRoute: string = location.href.split('/').slice(-1).pop();
    const disableRoutes = [
      ROUTES_PATH.CourseChoose,
      ROUTES_PATH.HomeTermsOfService,
      ROUTES_PATH.AppInfoInput,
      ROUTES_PATH.AppInfoConfirm,
      ROUTES_PATH.Reserve,
      ROUTES_PATH.SettingConstruction,
    ];
    if (disableRoutes.includes(activeRoute)) {
      this.helperService.redirect(ROUTES_PATH.Home);
      return of(false);
    }
    return of(true);
  }
}
