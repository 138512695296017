import { HttpClientModule } from '@angular/common/http';
import { HomeModule } from './home/home.module';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ModalModule, BsModalService } from 'ngx-bootstrap/modal';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { APP_BASE_HREF } from '@angular/common';
import { NotFoundComponent } from '@shared/pages/not-found/not-found.component';
import { ForbiddenComponent } from '@shared/pages/forbidden/forbidden.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ToastrModule } from 'ngx-toastr';
import { LoadingModule } from '@shared/components/loading/loading.module';
import { ErrorComponent } from '@shared/pages/error/error.component';
import { LoginComponent } from '@shared/pages/login/login.component';
import { CodeInputModule } from 'angular-code-input';

@NgModule({
  declarations: [AppComponent, NotFoundComponent, ForbiddenComponent, ErrorComponent, LoginComponent],
  imports: [
    BrowserModule,
    HomeModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ModalModule,
    FontAwesomeModule,
    AppRoutingModule,
    PaginationModule.forRoot(),
    BsDropdownModule.forRoot(),
    HttpClientModule,
    ToastrModule.forRoot(),
    LoadingModule,
    CodeInputModule,
  ],
  providers: [{ provide: APP_BASE_HREF, useValue: '/' }, BsModalService],
  bootstrap: [AppComponent],
})
export class AppModule {}
