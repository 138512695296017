import { ApiResponse, TermsType } from '@shared/common/types';
import { TermsOfServiceService } from '@core/services/home/terms-of-serivce.service';
import { HelperService } from '@core/services/helper.service';
import {
  DIALOG_MESSAGE_TYPE,
  MESSAGES,
  NOT_FOUND_MEMBER_ID_COOKIE_NAME,
  PAYMENT_METHOD_VALUE,
  PAYMENT_TYPES,
  PROGRESS_STEPS,
  RESULT_CODES,
  ROUTES_PATH,
  TYPE_TERMS,
} from '@shared/common/constants';
import { DataService } from '@core/services/data.service';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { finalize } from 'rxjs/operators';
import { DialogServiceService } from '@core/services/dialog-service.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-terms-of-service',
  templateUrl: './terms-of-service.component.html',
  styleUrls: ['./terms-of-service.component.scss'],
})
export class TermsOfServiceComponent implements OnInit {
  modalRef?: BsModalRef;
  dataModal: any = {};
  faChevronLeft: any = faChevronLeft;
  faChevronRight: any = faChevronRight;
  isDisable: boolean = true;
  isChecked: boolean = false;
  isLoading: boolean = true;
  memberShipsData: TermsType[] = [];
  policiesData: TermsType[] = [];
  notesData: TermsType[] = [];
  member_id: string;
  isCandyWCredit = false;

  constructor(
    private readonly dataService: DataService,
    private readonly helperService: HelperService,
    private termsOfServiceService: TermsOfServiceService,
    private dialogService: DialogServiceService,
    private modalService: BsModalService,
  ) {}

  ngOnInit(): void {
    this.fetchDataFromSessionOfCourseChoose();
  }

  openModal(detail: TemplateRef<any>, typeTerm: string, urlName: string): void {
    switch (typeTerm) {
      case TYPE_TERMS.MemberShip: {
        this.dataModal = this.findDataByUrlName(this.memberShipsData, urlName);
        break;
      }
      case TYPE_TERMS.Policies: {
        this.dataModal = this.findDataByUrlName(this.policiesData, urlName);
        break;
      }
      case TYPE_TERMS.Notes: {
        this.dataModal = this.findDataByUrlName(this.notesData, urlName);
        break;
      }
    }
    this.modalRef = this.modalService.show(detail, { class: 'modal-lg modal-dialog-centered terms-custom-modal' });
  }

  closeModal(): void {
    this.modalService.hide();
  }

  onCheckBoxChange(e): void {
    this.isDisable = !e.target.checked;
  }

  fetchDataFromSessionOfCourseChoose(): void {
    const dataParsed = this.dataService.getDataStored(PROGRESS_STEPS.StepTwo.Index);
    const { selectedCourse, company } = dataParsed;
    const { terms_management_id, company_code } = selectedCourse;
    this.isCandyWCredit = company.credit_code === PAYMENT_TYPES.CANDY_W;
    this.getTermsOfService(terms_management_id, company_code);
  }

  /**
   * Back previous page
   */
  public backPreviousStep(): void {
    this.dataService.setStepDataStored(PROGRESS_STEPS.StepTwo.Index);
    this.helperService.redirectByOptions(ROUTES_PATH.CourseChoose, { skipLocationChange: true });
  }

  private backToFirstStep() {
    this.dataService.setStepDataStored(PROGRESS_STEPS.StepOne.Index);
    this.helperService.redirect(ROUTES_PATH.Index);
  }

  /**
   * Click to next step
   */
  public nextStep(): void {
    this.dataService.setStepDataStored(PROGRESS_STEPS.StepFour.Index, {
      ...(this.isCandyWCredit && { member_id: this.member_id }),
    });
    this.helperService.redirectByOptions(ROUTES_PATH.AppInfoInput, { skipLocationChange: true });
  }

  getTermsOfService(termManagementId: number, companyCode: string): void {
    this.termsOfServiceService
      .getTermsOfServiceManagements(termManagementId, companyCode, this.isCandyWCredit)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        async (res: ApiResponse) => {
          if (!res?.contents?.terms_management || res.result_code !== RESULT_CODES.Success) return;

          const {
            terms_management: { memberships_agreements = [], policies = [], notes = [] },
            member_id,
          } = res.contents;

          this.memberShipsData = memberships_agreements;
          this.policiesData = policies;
          this.notesData = notes;

          if (this.isCandyWCredit) {
            if (!member_id) {
              document.cookie = `${NOT_FOUND_MEMBER_ID_COOKIE_NAME}=${MESSAGES.CourseChoose.notFoundMemberId}; Path=/;`;
              return this.backToFirstStep();
            }

            this.member_id = member_id;
          }
        },
        (error: Error): any => {
          this.dialogService.open({
            type: DIALOG_MESSAGE_TYPE.Error,
            message: error.message || MESSAGES.Base.GetFailed,
          });
        },
      );
  }

  private findDataByUrlName(data: TermsType[], urlName: string): any {
    return data.find((element) => element.url_link_name === urlName);
  }
}
