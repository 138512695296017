import { Buffer } from 'buffer';
import Encoding from 'encoding-japanese';
import * as _ from 'lodash';

let cryptoPrivateKey;
const iv = new Uint8Array([188, 185, 57, 146, 246, 194, 114, 34, 12, 80, 198, 39]);

async function importCryptoKey() {
  return window.crypto.subtle.importKey(
    'jwk',
    {
      kty: 'oct',
      k: 'Y0zt37HgOx-BY7SQjYVmrqhPkO44Ii2Jcb9yydUDPfE',
      alg: 'A256GCM',
      ext: true,
    },
    {
      name: 'AES-GCM',
    },
    false,
    ['encrypt', 'decrypt'],
  );
}

export async function cryptoEncode(sourceData: any) {
  cryptoPrivateKey = await importCryptoKey();
  const enc = new TextEncoder();

  try {
    const encryptedData = await window.crypto.subtle.encrypt(
      {
        name: 'AES-GCM',
        iv,
      },
      cryptoPrivateKey,
      enc.encode(sourceData),
    );
    const base64Str = btoa(new Uint8Array(encryptedData).reduce((data, byte) => data + String.fromCharCode(byte), ''));
    return base64Str;
  } catch (error) {
    return false;
  }
}

export async function cryptoDecode(encryptedData: any) {
  cryptoPrivateKey = await importCryptoKey();
  const arrayBuffer = Buffer.from(encryptedData, 'base64');
  const enc = new TextDecoder('utf-8');

  try {
    const decryptedData = await window.crypto.subtle.decrypt(
      {
        name: 'AES-GCM',
        iv: iv,
        tagLength: 128,
      },
      cryptoPrivateKey,
      arrayBuffer,
    );
    return enc.decode(decryptedData);
  } catch (error) {
    return false;
  }
}

export function decodeBase64(str: string) {
  const bufferArr = Encoding.base64Decode(str);
  const result = Encoding.codeToString(bufferArr);
  return result;
}

export function decodePercentEncodedString(str: string) {
  const decodedStr = Encoding.urlDecode(str);
  const unicodeStr = Encoding.convert(decodedStr, { to: 'UNICODE' });
  const result = Encoding.codeToString(unicodeStr);
  return result;
}

export function convertCookieStringToArray(cookie: string) {
  return cookie
    .split(';')
    .filter((item) => !_.isEmpty(item))
    .map((item) => {
      const keyAndValueArr = item.split('=');
      return { key: keyAndValueArr[0].replace(/\s/g, ''), value: keyAndValueArr[1] };
    });
}
