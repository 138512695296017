<div class="container-fluid shadow course-choose">
  <div class="card">
    <div>
      <div class="card-header">
        提供状況の確認
      </div>
      <div class="card-body">
        <div class="c-mt-5" *ngIf="!isLoading">
          <div *ngIf="!realNoCourse">
            <div class="row justify-content-center align-items-center text-lime c-fs-18 c-fw-6" >
              <fa-icon class="mr-3" [icon]="faCheckCircle" size="5x"></fa-icon>
              <span class="mt-2 mt-sm-0 c-ff-h6 c-fs-18">提供可能エリアです<br>いますぐお申し込みいただけます。</span>
            </div>
            <div class="mb-0 mt-3">
              <p class="col-12 text-center alert-description c-ff-h3 c-fs-16">※物件の状況によっては導入できない場合もあります</p>
            </div>
          </div>
          <div *ngIf="realNoCourse">
            <div class="row justify-content-center align-items-center text-red c-fs-18 c-fw-6">
              <img src="assets/img/no-course-icon.jpeg" alt="" class="mr-3" width="70" height="70">
              <span  class="mt-2 mt-sm-0 c-ff-h6 c-fs-25">ご確認ください</span>
            </div>
            <div class="mb-0 mt-3 no-course-description">
              <p class="col-12 alert-text c-ff-h3 c-fs-16">※郵便番号が正しくないか、提供エリア外の郵便番号を入力されました。</p>
              <p class="col-12 alert-text c-ff-h3 c-fs-16">※お住まいの地域でサービスの提供をご希望される方は、サービス事業会社へお問い合わせ下さい。</p>
            </div>
          </div>
        </div>
        <div class="alert text-center address-bg c-mt-4 c-mb-5 p-4">
          <p class="main c-fs-19 c-ff-h6">住所</p>
          <p class="mb-4 c-ff-h6">{{this.getAddress()}}</p>
          <p class="mb-2">
            <a (click)="backPreviousStep()" class="text-info c-ff-h6 c-fs-16"><fa-icon [icon]="faChevronLeft" size="sm"></fa-icon> 住所を変更する</a>
          </p>
        </div>
      </div>
    </div>

    <div *ngIf="realNoCourse">
      <ng-container *ngIf="!isLoading">
        <div class="no-course alert text-center address-bg c-mt-4 c-mb-5 p-4" [innerHTML]="companyInfo?.inquiry_contents">
        </div>
      </ng-container>
    </div>

    <div *ngIf="!realNoCourse" class="w-100">
      <div class="card-header">
        サービスコース選択
      </div>
      <div class="card-body py-4 section">
        <div class="text-center page-title c-fw-6 mb-5">
          <p>提供可能ケーブルTV会社：{{ companyInfo?.name ? companyInfo?.name:"○○○○テレビ"  }}</p>
          <p class="text-danger" *ngIf="isDifferentCompany">申込サイトと提供可能なケーブルTV会社は異なります</p>
        </div>

        <!-- Element search campaign code -->
        <div class="box-search-campaign-code">
          <label>キャンペーンコード</label>
          <div class="campaign-search">
            <img *ngIf="campaignCodeInput.length" src="assets/img/btn-zipcode-delete@2x.png" class="position-absolute btn-remove-campaign-code" (click)="clearCampaignCodeInput()"/>
            <input type="text" #campaignCode
              class="form-control c-fs-30 text-shadow"
              [(ngModel)] = "campaignCodeInput"
              (keyup)="onChangeCampaignCodeInput()"
              maxlength="20">
          </div>
          <button type="button" [disabled]="!campaignCodeInput.length" class="btn btn-cw-110 btn-ch-44 btn-shadow btn-black btn-search-campaign-code" (click)="onSearchCourse()">
            <span>適用する</span>
          </button>
        </div>

        <h6 *ngIf="displayCourse" class="heading c-ff-h6 c-fs-19">お申し込みプランを選択してください</h6>
        <table *ngFor="let course of listCourse; let courseIndex = index;" class="table table-bordered c-mt-3" border="0" cellpadding="0" cellspacing="0">
          <thead>
          <tr class="table-header">
            <th colspan="3" class="p-1 text-left col-12" [ngClass]="{ 'course-choose-name': course.campaign_code && course.campaign_code.length !== 0}">
              <div [innerHTML]="course.name | nl2br"></div>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <th class="table-active col-md-1" scope="row">コース内容</th>
            <td class="c-ff-h3 col-md-9">
              <div [innerHTML]="course.contents | nl2br"></div>
            </td>
            <td rowspan="6" class="col-md-2 td-button">
              <button
                (click)="clickSelectCourse(course)"
                type="button"
                class="btn-select-course btn btn-cw-110 btn-ch-50 btn-sticky"
                [ngClass]="[selectedCourse?.course_code_id === course.course_code_id ? 'btn-choose-black': selectedCourse?.course_code_id ? 'btn-unselected' : 'btn-choose-white-custom',
                handleEffectSelectCourse(course.course_code_id)
              ]"
              >
                <span class="c-ff-h3" *ngIf="selectedCourse?.course_code_id === course.course_code_id else notSelect">選択中</span>
                <ng-template #notSelect>
                  <span class="c-ff-h3">選択する</span>
                </ng-template>
              </button>
            </td>
          </tr>
          <tr *ngIf="course.section_1">
            <th scope="row" class="table-active col-md-1" [innerHTML]="course.section_1_title | nl2br"></th>
            <td class="c-ff-h3 col-md-9" [innerHTML]="course.section_1 | nl2br"></td>
          </tr>
          <tr *ngIf="course.section_2">
            <th scope="row" class="table-active col-md-1" [innerHTML]="course.section_2_title | nl2br"></th>
            <td class="c-ff-h3 col-md-9" [innerHTML]="course.section_2 | nl2br"></td>
          </tr>
          <tr *ngIf="course.section_3">
            <th scope="row" class="table-active col-md-1" [innerHTML]="course.section_3_title | nl2br"></th>
            <td class="c-ff-h3 col-md-9" [innerHTML]="course.section_3 | nl2br"></td>
          </tr>
          <tr *ngIf="course.options.length">
            <td colspan="2" class="c-ff-h3 col-md-9 pb-0">
                <div *ngFor="let option of course.options; let optionIndex = index;" class="d-flex align-items-center justify-content-center">
                  <div class="wrapper-checkbox mr-2 mt-4 position-relative">
                    <input type="checkbox" value="" id="course-{{course.course_code_id}}-{{option.id}}" class="checkTerms" name="check" [checked]="option.checked"  (change)="onCheckBoxChange($event, courseIndex, optionIndex, course.course_code_id)"/>
                    <label for=course-{{course.course_code_id}}-{{option.id}}></label>
                  </div>
                  <table class="table table-bordered mb-2" cellpadding="0" cellspacing="0">
                    <thead>
                    <tr class="table-header-option">
                      <th colspan="3" class="p-1 text-left col-12 table-background">
                        {{option.title}}
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngIf="option.contents">
                      <td class="c-ff-h3 col-md-9" [innerHtml]="option.contents"></td>
                    </tr>
                    </tbody>
                  </table>
                </div>
            </td>
          </tr>
          </tbody>
        </table>
        <div *ngIf="noCourseError" class="text-danger">
          入力されたキャンペーンコードのサービスはありません。
        </div>
      </div>
    </div>

    <div class="card-body pb-4 btn-area">
      <div class="footer row align-items-center c-mt-3">
        <div class="col-sm-4 d-flex">
          <button class="btn btn-white btn-cw-150 btn-ch-44" (click)="backPreviousStep();">
            <span class="btn-text"><fa-icon [icon]="faChevronLeft"></fa-icon> <span class="c-ff-h3 c-fs-16">一つ前へ戻る</span></span>
          </button>
        </div>
        <div class="col-sm-4 justify-content-center d-flex my-sm-4">
          <button class="btn btn-lime btn-cw-260 btn-ch-70 btn-next" (click)="nextStep();" [disabled]="noCourse || realNoCourse" [class.btn-blink]="blinkState">
            <span class="btn-text"><fa-icon [icon]="faChevronRight"></fa-icon> 次へ進む</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<app-loading *ngIf="isLoading"></app-loading>
